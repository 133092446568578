import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../components/styles.css";
import Logo from "../assets/logo.png";
import defaultProfilePic from "../assets/profile_default.jpg"; 
import { ThemeContext } from "../components/theme.js";
import {
  StyledButton,
  StyledNavLink,
  StyledDiv,
} from "../components/styledComponents.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserPen,
  faRightFromBracket,
  faHome,
  faCog,
  faChartBar,
  faUsers,
  faHelicopter,
} from "@fortawesome/free-solid-svg-icons"; // Add more icons as needed
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { useNow } from "@mui/x-date-pickers/internals";
import { DataGrid } from "@mui/x-data-grid";
import dayjs from "dayjs";

const apiUrl = process.env.REACT_APP_API_URL;

//sample data for useNow
const columns = [
  { field: "status", headerName: "Status", flex: 1 },
  { field: "auditor_name", headerName: "Pilot", flex: 1 },
  { field: "date_scheduled", headerName: "Scheduled Date", flex: 1 },
  { field: "location_name", headerName: "Location", flex: 1 },
  { field: "audit_name", headerName: "Reference", flex: 1 },
  { field: "exemptions", headerName: "Exemptions", flex: 1 },
  { field: "flight_time", headerName: "Flight Time", flex: 1 },
  { field: "flight_distance", headerName: "Distance (m)", flex: 1 },
  { field: "drone_name", headerName: "Drone", flex: 1 },
  
  //{ field: "controller_name", headerName: "Controller", flex: 1 },
  //{ field: "software_name", headerName: "Software", flex: 1 },
  //{ field: "misc_name", headerName: "Other Equipment", flex: 1 },
];

function Home() {
  const [userID, setUserID] = useState("");
  const [profile, setProfile] = useState(null);
  const [memberships, setMemberships] = useState([]);
  const [selectedMembership, setSelectedMembership] = useState(null);
  const [logoUrl, setLogoUrl] = useState(Logo);
  const [userPicUrl, setUserPicUrl] = useState(null);
  const navigate = useNavigate();
  const { theme, setTheme } = useContext(ThemeContext);
  const mToken = localStorage.getItem("MToken");
  const [selectedFile, setSelectedFile] = useState(null);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [membershipIsExpanded, setMembershipIsExpanded] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [audits, setAudits] = useState([]);
  const [rows, setRows] = useState([]);
  const [totalFlights, setTotalFlights] = useState(0);
  const [totalDistance, setTotalDistance] = useState(0);
  const [totalFlightTime, setTotalFlightTime] = useState("0h 0m");
  const [totalTests, setTotalTests] = useState(0);
  const [isFilteredByAuditor, setIsFilteredByAuditor] = useState(false);
  const [filteredRows, setFilteredRows] = useState(rows); // To hold filtered rows

  const toggleMemberships = () => {
    setMembershipIsExpanded(!membershipIsExpanded);
  };

  const toggleFilter = () => {
    setIsFilteredByAuditor(!isFilteredByAuditor);
  };

  const handleUploadProfilePicture = async () => {
    if (selectedFile) {
      // Create a FormData object to send the selected file
      const formData = new FormData();
      formData.append("profile_pic", selectedFile);

      try {
        // Call the API endpoint to upload the profile picture
        await axios.put(
          apiUrl + `/profiles/${userID}/profile_picture`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        // Handle success, e.g., show a success message
        console.log("Profile picture uploaded successfully!");
        setUserPicUrl(URL.createObjectURL(selectedFile));
      } catch (error) {
        // Handle error, e.g., show an error message
        console.error("Error uploading profile picture:", error);
      }
    } else {
      // Handle the case where no file is selected
      console.error("No file selected for upload.");
    }
  };

  const handleFileInputChange = (e) => {
    // Handle the file input change event and set the selected file
    const file = e.target.files[0];
    setSelectedFile(file);

    // Automatically trigger the profile picture upload when a file is selected
    handleUploadProfilePicture();
  };

  const handleRowClick = (params) => {
    console.log("Row clicked:", params.row); // Logs the full row data
    const rowId = params.row.id; // Access row ID from params.row
    navigate(`/flight/create_test/${rowId}`); // Navigate with the row ID
  };

  // Fetch user ID once
  useEffect(() => {
    const fetchUserID = async () => {
      const token = localStorage.getItem("access_token");
      try {
        const userRes = await axios.get(apiUrl + "/get_user_id_from_token", {
          headers: { Authorization: `Bearer ${token}` },
        });
        const userID = userRes.data.user_id;
        setUserID(userID);
      } catch (error) {
        console.error("There was an error fetching user ID!", error.response);
        if (error.response && error.response.status === 401) {
          navigate("/login");
        }
      }
    };

    fetchUserID();
  }, [navigate]); // Only run this on initial render

  // Function to fetch audits by organization ID
  const fetchAuditsByOrg = async (orgId) => {
    try {
      console.log("Fetching audits for orgId:", orgId); // Add this log to ensure this function is called
      const auditsResponse = await axios.get(
        `${apiUrl}/audit/all-audits-extended/${orgId}`
      );
      const auditsData = auditsResponse.data;
      setAudits(auditsData); // Set the audits data (to keep track of all fetched audits)
      console.log("Fetched audits data:", auditsData); // Log the fetched data

      // Format the data for DataGrid
      const formattedRows = auditsData.map((audit) => {
        let flightTimeFormatted = "";
        if (audit.TO_date && audit.LD_date) {
          const takeoff = dayjs(audit.TO_date);
          const landing = dayjs(audit.LD_date);
          const flightTimeInMinutes = landing.diff(takeoff, "minute");
          const hours = Math.floor(flightTimeInMinutes / 60);
          const minutes = flightTimeInMinutes % 60;
          flightTimeFormatted = `${hours}h ${minutes}m`;
        }

        return {
          id: audit.id,
          status: audit.status,
          auditor: audit.auditor,
          auditor_name: audit.auditor_name,
          date_scheduled: new Date(audit.date_scheduled).toLocaleDateString(),
          audit_name: audit.audit_name,
          exemptions: audit.exemptions,
          flight_time: flightTimeFormatted,
          flight_distance: audit.flight_distance,
          drone_name: audit.drone_name,
          controller_name: audit.controller_name,
          software_name: audit.software_name,
          misc_name: audit.misc_name,
          location_name: audit.location_name,
        };
      });

      console.log("Setting formatted rows:", formattedRows); // Ensure rows are formatted
      setRows(formattedRows); // Set the rows for DataGrid
    } catch (error) {
      console.error("Error fetching audits:", error);
    }
  };

  // Handle membership click (manual selection by the user)
  const handleMembershipClick = async (membership) => {
    setSelectedMembership(membership);

    try {
      const token = localStorage.getItem("access_token");
      const orgId = membership.org_id;

      const membershipTokenRes = await axios.post(
        `${apiUrl}/membership-token`,
        {
          membership_data: membership,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      localStorage.setItem("MToken", membershipTokenRes.data.membership_token);

      const organisationRes = await axios.get(
        `${apiUrl}/organisations/${orgId}`
      );
      const {
        primary_colour,
        accent_colour,
        primary_text_and_icon_colour,
        secondary_text_and_icon_colour,
      } = organisationRes.data;

      // Retrieve the logo URL
      try {
        const logoRes = await axios.get(
          `${apiUrl}/organisations/retrieve_file/${orgId}/logo.png`
        );
        const newLogoUrl = logoRes.data.url;

        // Set the theme with the new logo URL
        setTheme({
          primary_colour,
          accent_colour,
          primary_text_and_icon_colour,
          secondary_text_and_icon_colour,
          logoUrl: newLogoUrl,
        });
      } catch (err) {
        console.error("There was an error retrieving the logo:", err);
      }

      // Fetch audits for the newly selected membership
      fetchAuditsByOrg(orgId);
    } catch (error) {
      console.error(
        "There was an error creating the membership token!",
        error.response
      );
    }
  };

  useEffect(() => {
    if (isFilteredByAuditor && userID) {
      // Filter by the logged-in user's ID (userID)
      const filtered = rows.filter((row) => row.auditor === userID);
      setFilteredRows(filtered);
    } else {
      // Show all rows when not filtering
      setFilteredRows(rows);
    }
  }, [isFilteredByAuditor, userID, rows]); // Re-run when toggle, userID, or rows change

  //update home page tiles
  useEffect(() => {
    // Calculate the number of flights
    const flightCount = rows.length;

    // Calculate the total distance
    const totalDistanceFlown = rows.reduce(
      (sum, row) => sum + (row.flight_distance || 0),
      0
    );

    // Calculate total flight time in minutes
    let totalFlightTimeInMinutes = 0;
    rows.forEach((row) => {
      if (row.flight_time) {
        const [hours, minutes] = row.flight_time
          .split("h")
          .map((str) => parseInt(str, 10) || 0);
        totalFlightTimeInMinutes += hours * 60 + minutes;
      }
    });

    // Convert total flight time to hours and minutes
    const hours = Math.floor(totalFlightTimeInMinutes / 60);
    const minutes = totalFlightTimeInMinutes % 60;
    const formattedFlightTime = `${hours}h ${minutes}m`;

    // Set the state with the calculated values for flights, distance, and time
    setTotalFlights(flightCount);
    setTotalDistance(totalDistanceFlown);
    setTotalFlightTime(formattedFlightTime);

    // Fetch checklists for each audit and count the total number of tests
    const fetchChecklists = async () => {
      try {
        const checklistPromises = rows.map((row) =>
          axios.get(`${apiUrl}/audit/get_audit_checklists/${row.id}`)
        );

        const checklistResponses = await Promise.all(checklistPromises);

        const totalTestCount = checklistResponses.reduce((total, response) => {
          return total + (response.data.checklist_names.length || 0); // Count the number of checklists for each audit
        }, 0);

        setTotalTests(totalTestCount);
      } catch (error) {
        console.error("Error fetching checklists:", error);
      }
    };

    // Only call fetchChecklists if there are rows to process
    if (rows.length > 0) {
      fetchChecklists();
    }
  }, [rows]); // Recalculate whenever `rows` changes

  // Fetch user profile and memberships when the component loads
  useEffect(() => {
    console.log("Fetching user profile and memberships for user...", userID); // Add this log to ensure this function is called
    if (userID) {
      const fetchUserProfile = async () => {
        try {
          const userProfileRes = await axios.get(
            apiUrl + `/profiles/${userID}`
          );
          setProfile(userProfileRes.data);
          if (userProfileRes.data.profile) {
            try {
              const res = await axios.get(
                apiUrl +
                  `/profiles/retrieve_file/${userID}/${userProfileRes.data.profile}`
              );
              setUserPicUrl(res.data.url);
            } catch (err) {
              console.error("Error fetching profile picture:", err);
            }
          }
        } catch (error) {
          console.error("Error fetching profile:", error.response);
        }
      };

      const fetchMemberships = async () => {
        try {
          const membershipsRes = await axios.get(
            apiUrl + `/user-memberships/${userID}`
          );
          const membershipsData = membershipsRes.data;
          setMemberships(membershipsData);

          const mToken = localStorage.getItem("MToken");

          if (membershipsData.length > 0) {
            if (mToken) {
              // Decode the mToken to get the organization ID
              const orgIdFromToken = getOrgIdFromToken(mToken);
              console.log("Org ID from mToken:", orgIdFromToken);

              // Find the membership that matches the org ID from the token
              const selectedMembership = membershipsData.find(
                (membership) => membership.org_id === orgIdFromToken
              );

              // If a matching membership is found, set it as the selected membership
              if (selectedMembership) {
                setSelectedMembership(selectedMembership);
                console.log(
                  "Selected Membership from MToken:",
                  selectedMembership
                );
                fetchAuditsByOrg(selectedMembership.org_id);
              } else {
                // If no matching membership is found, fall back to the first membership
                const fallbackMembership = membershipsData[0];
                handleMembershipClick(fallbackMembership);
                setSelectedMembership(fallbackMembership);
                console.log(
                  "Fallback to first membership:",
                  fallbackMembership
                );
                fetchAuditsByOrg(fallbackMembership.org_id);
              }
            } else {
              // No mToken, fall back to the first available membership
              const selectedMembership = membershipsData[0];
              handleMembershipClick(selectedMembership); // Automatically sets MToken
              setSelectedMembership(selectedMembership);
              console.log("Selected Membership:", selectedMembership);
              fetchAuditsByOrg(selectedMembership.org_id);
            }
          }
        } catch (error) {
          console.error("Error fetching memberships:", error);
        }
      };

      // Helper function to decode the mToken and extract the organization ID
      const getOrgIdFromToken = (token) => {
        try {
          const decodedToken = JSON.parse(atob(token.split(".")[1])); // Assuming it's a JWT token
          return decodedToken.org_id; // Adjust based on the actual structure of your token
        } catch (error) {
          console.error("Error decoding token:", error);
          return null;
        }
      };

      // Fetch data
      fetchUserProfile();
      fetchMemberships();
    }
  }, [userID, navigate]);

  const handleRegisterTestClick = () => {
    navigate("/flight/new_flight");
  };

  const handleTestScheduleClick = () => {
    // Navigate to the new schedule page and pass state to indicate it was initiated from the home page
    navigate("/flight/new_schedule", { state: { fromHome: true } });
  };
  

  if (!profile) return "Loading.."; // Add some loading state handling

  return (
    <div className="relative flex bg-white min-h-screen">
      <div
        className={`flex flex-col w-full transition-all duration-300 ${
          isSidebarOpen ? "ml-64" : "ml-2"
        }`}
      >
        <div className="flex justify-between items-center p-4 bg-white">
          <img
            src={theme.logoUrl}
            alt="Membership Logo"
            className="w-48 h-auto"
          />
          <h2 className="font-bold text-2xl text-primary">
            Welcome to DFR {profile.first_name}!
          </h2>
          <div className="relative">
            <img
              src={userPicUrl}
              alt="Profile"
              className="rounded-full w-20 h-20 object-cover cursor-pointer"
              onMouseEnter={() => setShowProfileMenu(true)}
              onMouseLeave={() => setShowProfileMenu(false)}
            />
            {showProfileMenu && (
              <div
                className="absolute right-2 mt-2 py-2 w-48 bg-gray-100 rounded-md shadow-xl z-10"
                onMouseEnter={() => setShowProfileMenu(true)}
                onMouseLeave={() => setShowProfileMenu(false)}
                style={{ marginTop: "-1rem" }} // Adjust this value as needed
              >
                <div
                  className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                  onClick={() => {
                    localStorage.removeItem("access_token");
                    navigate("/login");
                  }}
                >
                  <FontAwesomeIcon
                    icon={faRightFromBracket}
                    size="sm"
                    className="mr-2"
                  />
                  <span>Logout</span>
                </div>
                {profile.is_superAdmin && (
                  <div
                    className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                    onClick={() => navigate("/admin")}
                  >
                    <FontAwesomeIcon icon={faCog} size="sm" className="mr-2" />
                    <span>Admin</span>
                  </div>
                )}
                <div
                  className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                  onClick={() => {
                    const fileInput = document.createElement("input");
                    fileInput.type = "file";
                    fileInput.accept = "image/jpeg, image/png";
                    fileInput.onchange = handleFileInputChange;
                    fileInput.click();
                  }}
                >
                  <FontAwesomeIcon
                    icon={faUserPen}
                    size="sm"
                    className="mr-1"
                  />
                  <span>Update Profile</span>
                </div>
                <div
                  className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                  onClick={toggleMemberships}
                >
                  <FontAwesomeIcon icon={faUsers} size="sm" className="mr-2" />
                  <span>Memberships</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-col p-5">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-4 mb-4">
            <div className="bg-gray-100 p-6 rounded-lg shadow-lg flex flex-col justify-center items-center">
              <h3 className="font-bold text-lg text-gray-700">Flights</h3>
              <p className="text-2xl">{totalFlights}</p>{" "}
              {/* Display total flights */}
            </div>
            <div className="bg-gray-100 p-6 rounded-lg shadow-lg flex flex-col justify-center items-center">
              <h3 className="font-bold text-lg text-gray-700">Flight Time</h3>
              <p className="text-2xl">{totalFlightTime}</p>{" "}
              {/* Display total flight time */}
            </div>
            <div className="bg-gray-100 p-6 rounded-lg shadow-lg flex flex-col justify-center items-center">
              <h3 className="font-bold text-lg text-gray-700">Distance</h3>
              <p className="text-2xl">{(totalDistance / 1000).toFixed(2)} km</p>
              {/* Display total distance */}
            </div>
            <div className="bg-gray-100 p-6 rounded-lg shadow-lg flex flex-col justify-center items-center">
              <h3 className="font-bold text-lg text-gray-700">Tests</h3>
              <p className="text-2xl">{totalTests}</p>{" "}
              {/* Display total tests */}
            </div>

            <div
              className="bg-primary p-6 rounded-lg shadow-lg flex items-center justify-center"
              onClick={handleTestScheduleClick}
              style={{
                backgroundColor: theme.primary_colour,
              }}
            >
              <h3 className="font-bold text-lg text-white">
                Create New Test Schedule
              </h3>
            </div>
            <div
              className="bg-primary p-6 rounded-lg shadow-lg flex items-center justify-center"
              onClick={handleRegisterTestClick}
              style={{
                backgroundColor: theme.primary_colour,
              }}
            >
              <h3 className="font-bold text-lg text-white">
                Add New Test Flight
              </h3>
            </div>
          </div>

          {membershipIsExpanded && (
            <div>
              <h3 className="font-bold text-xl mt-4 mb-2">Memberships</h3>
              <div className="grid grid-cols-3 gap-4 pr-4">
                {memberships.map((organization, index) => (
                  <StyledDiv
                    key={index}
                    className={`p-6 rounded text-left ${
                      selectedMembership === organization ? "selected" : ""
                    }`}
                    onClick={() => handleMembershipClick(organization)}
                    style={{
                      backgroundColor:
                        selectedMembership === organization
                          ? theme.primary_colour
                          : "transparent",
                      color:
                        selectedMembership === organization
                          ? theme.primary_text_and_icon_colour
                          : "inherit",
                    }}
                  >
                    <h4 className="font-bold">
                      {organization.organisation_name}
                    </h4>
                    <p>{organization.role_name}</p>
                  </StyledDiv>
                ))}
              </div>
            </div>
          )}

          <div className="bg-white p-6 rounded-lg shadow-md mt-4">
            <h3 className="font-bold text-xl text-gray-700 mb-4">Flight Log</h3>

            {/* Toggle Button */}
            <button
              onClick={() => setIsFilteredByAuditor(!isFilteredByAuditor)}
              className="bg-gray-400 text-white px-4 py-2 rounded-md mb-4"
            >
              {isFilteredByAuditor ? "Show All Flights" : "Show My Flights"}
            </button>

            <DataGrid
              columns={columns}
              rows={filteredRows} // Use the filtered rows based on the toggle
              autoHeight
              autoWidth
              disableSelectionOnClick
              onRowClick={handleRowClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default Home;
