// React and React-related imports

import React, { useState, useEffect, useContext } from "react";
import Modal from "react-modal";

// Third-party libraries
import axios from "axios";
import Swal from "sweetalert2";
import { debounce } from "lodash";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { LineChart } from "@mui/x-charts/LineChart";
import { ButtonGroup, Button, Box } from "@mui/material";
import WeatherLineChart from "../components/WeatherLineChart"; // Assuming you have the chart component

// Router imports
import { useNavigate, useParams } from "react-router-dom";

// Context imports
import { ThemeContext } from "../components/theme.js";
// Icon imports
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faPlus,
  faHomeLgAlt,
  faLock,
} from "@fortawesome/free-solid-svg-icons";

// CSS and Style imports

import "../components/styles.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

// Styled-components and Related Imports
import { ThemeProvider } from "styled-components";
import {
  GlobalStyle,
  StyledCarousel,
  BottomContainer,
  AddButton,
  NoChecklistsMessage,
  ChecklistsGrid,
  AuditHeader,
  AuditDetails,
  StyledNavLink,
  Mask,
  Section,
} from "../components/styledComponents";

// Component imports
import auditProtection from "../hoc/auditProtection";
import CircularProgress from "../components/CircularProgress";
import CircularScore from "../components/CircularScore";
import { ta } from "date-fns/locale";

const apiUrl = process.env.REACT_APP_API_URL;

const CustomDemoItem = ({ label, children }) => (
  <div className="custom-demo-item">
    <label className="mt-1 block text-m font-medium text-gray-700">
      {label}
    </label>
    {children}
  </div>
);

function formatDate(dateString) {
  const options = { year: "numeric", month: "long", day: "numeric" };
  const formattedDate = new Date(dateString).toLocaleDateString(
    undefined,
    options
  );
  return formattedDate;
}

Modal.setAppElement("#root");

function CreateAuditPage(props) {
  const { theme, setTheme } = useContext(ThemeContext);
  const [userID, setUserID] = useState("");
  const [checklists, setChecklists] = useState([]);
  const navigate = useNavigate();
  const { auditId } = useParams();
  const [isAdding, setIsAdding] = useState(false);
  const [addedChecklists, setAddedChecklists] = useState([]);
  const [auditDetails, setAuditDetails] = useState(null);
  const [progress, setProgress] = useState({ total: 0, answered: 0 });
  const [showBottomContainer, setShowBottomContainer] = useState(false);
  const [availableBlueprints, setAvailableBlueprints] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const [isExpanded, setIsExpanded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const [takeoffTime, setTakeoffTime] = useState(null);
  const [landingTime, setLandingTime] = useState(null);
  const [distanceFlown, setDistanceFlown] = useState("");
  const [selectedExemption, setSelectedExemption] = useState("none");
  const [totalFlightTime, setTotalFlightTime] = useState("");
  const [weatherModalOpen, setWeatherModalOpen] = useState(false);
  const [weatherData, setWeatherData] = useState([]);
  const [takeoffBatteryCharge, setTakeoffBatteryCharge] = useState("");
  const [landingBatteryCharge, setLandingBatteryCharge] = useState("");

  const openModal = () => {
    console.log("Opening modal");
    setWeatherModalOpen(true);
  };
  const closeModal = () => setWeatherModalOpen(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
    handleToggleBottomContainer();
  };

  const fetchUserID = async () => {
    const token = localStorage.getItem("access_token");
    try {
      const userRes = await axios.get(apiUrl + "/get_user_id_from_token", {
        headers: { Authorization: `Bearer ${token}` },
      });
      const userID = userRes.data.user_id;
      setUserID(userID);
    } catch (error) {
      console.error("There was an error!", error.response);
      if (error.response && error.response.status === 401) {
        navigate("/login");
      }
    }
  };

  const fetchAuditDetails = async (auditId) => {
    try {
      console.log("Fetching audit details for audit ID:", auditId);
      // Call the function to update the status on the server
      if (auditId) {
        await updateAuditStatusOnServer(auditId);
      }

      const response = await axios.get(
        `${apiUrl}/audit/get_audit_header/${auditId}`
      );
      const auditDetailsData = response.data;
      console.log("Audit details:", auditDetailsData);
      // Set the audit details in the state
      setAuditDetails(auditDetailsData);
    } catch (error) {
      console.error("Error fetching audit details:", error);
      // Handle error appropriately
    }
  };

  const fetchChecklists = async (auditId) => {
    try {
      // Fetch checklists already in the audit
      const responseAuditChecklists = await axios.get(
        `${apiUrl}/audit/get_audit_checklists/${auditId}`
      );

      const auditChecklists = responseAuditChecklists.data.checklist_names; // Updated property name
      console.log(
        "Audit checklists:",
        responseAuditChecklists.data.checklist_names
      );

      // Process the audit checklists
      const formattedChecklists = auditChecklists.map((checklist) => ({
        id: checklist.id, // Add the id property
        checklist_name: checklist.checklist_name,
        created_at: checklist.created_at,
        updated_at:
          checklist.updated_at !== undefined ? checklist.updated_at : null,
        // Add other checklist details as needed
      }));

      setAddedChecklists(formattedChecklists);

      // Fetch organization ID from decoded membership token
      const mToken = localStorage.getItem("MToken");
      if (!mToken) {
        navigate("/flight/home"); // Redirect to home if there is no MToken
        return;
      }

      const response = await axios.post(
        `${apiUrl}/decode-membership-token/${mToken}`
      );
      const membershipData = response.data;

      if (
        membershipData &&
        membershipData.data &&
        membershipData.data.membership_data
      ) {
        const orgId = membershipData.data.membership_data.org_id;
        console.log("Organization ID:", orgId);

        // Fetch available blueprints for the organization
        const responseAvailableBlueprints = await axios.get(
          `${apiUrl}/audit/get_available_blueprints/${orgId}`
        );

        const availableBlueprints = responseAvailableBlueprints.data;
        console.log("Available blueprints:", availableBlueprints);
        setAvailableBlueprints(availableBlueprints);
      }
    } catch (error) {
      console.error("Error fetching audit checklists:", error);
    }
  };

  const fetchProgress = async () => {
    try {
      const response = await axios.get(`${apiUrl}/audit/progress/${auditId}`);
      setProgress(response.data);
    } catch (error) {
      console.error("Error fetching progress:", error);
      // Handle error appropriately
    }
  };

  const AuditScores = ({ auditId }) => {
    const [score, setScore] = useState(null);

    useEffect(() => {
      const fetchScore = async () => {
        try {
          const response = await axios.get(
            `${apiUrl}/audit/scores/audit/${auditId}`
          );
          // Assuming the response is an array and you need the first item's average_score
          const fetchedScore = response.data[0]?.average_score;
          if (fetchedScore !== undefined) {
            setScore(fetchedScore);
          } else {
            // Handle the case where average_score is not in the response
            console.error("Average score not found in the response");
            setScore(0);
          }
        } catch (error) {
          console.error("Error fetching score", error);
          setScore(0); // Or handle differently
        }
      };

      if (auditId) {
        fetchScore();
      }
    }, [auditId, apiUrl]);

    if (score === null || score === 0) {
      return null; // Don't render if score is zero or not fetched
    }

    return (
      <div className="circular-score-container">
        <CircularScore averageScore={score} />{" "}
        {/* Make sure prop names match */}
      </div>
    );
  };

  const ChecklistScores = ({ checklistId, auditId }) => {
    const [score, setScore] = useState(null);

    useEffect(() => {
      const fetchScore = async () => {
        try {
          const response = await axios.get(
            `${apiUrl}/audit/scores/checklist/${checklistId}/audit/${auditId}`
          );
          // Assuming the response is an array and you need the first item's average_score
          const fetchedScore = response.data[0]?.average_score;
          if (fetchedScore !== undefined) {
            setScore(fetchedScore);
          } else {
            // Handle the case where average_score is not in the response
            console.error("Average score not found in the response");
            setScore(0);
          }
        } catch (error) {
          console.error("Error fetching score", error);
          setScore(0); // Or handle differently
        }
      };

      if (checklistId && auditId) {
        fetchScore();
      }
    }, [checklistId, auditId, apiUrl]);

    if (score === null || score === 0) {
      return null; // Don't render if score is zero or not fetched
    }

    return (
      <div className="circular-score-container">
        <CircularScore averageScore={score} />{" "}
        {/* Make sure prop names match */}
      </div>
    );
  };

  const updateAuditStatusOnServer = async (auditId) => {
    try {
      await axios.post(`${apiUrl}/audit/update_audit_status/${auditId}`);
    } catch (error) {
      console.error("Error updating audit status:", error);
      // Handle error appropriately
    }
  };

  const handleDownloadReport = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/audit/generate_audit_report/${auditId}`,
        {
          responseType: "blob", // Important: handle the response as a Blob
        }
      );

      // Create a URL for the blob
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement("a");

      fileLink.href = fileURL;
      fileLink.setAttribute("download", `audit_report_${auditId}.pdf`);
      document.body.appendChild(fileLink);

      // Programmatically click the link to trigger the download
      fileLink.click();

      // Clean up: remove the link
      fileLink.parentNode.removeChild(fileLink);
    } catch (error) {
      console.error("Error generating audit report: ", error);
      Swal.fire({
        title: "Error!",
        text: "Failed to generate audit report.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const handleDeleteChecklist = async (checklistId, auditId) => {
    try {
      const response = await axios.get(
        `${apiUrl}/audit/checklist_answers_exist/${checklistId}/${auditId}`
      );
      const hasAnswers = response.data.hasAnswers;

      if (hasAnswers) {
        Swal.fire({
          title: `This checklist cannot be deleted because it has questions with answers.`,
          icon: "warning",
          showCancelButton: false,
          cancelButtonColor: "#3085d6",
          cancelButtonText: "OK",
        });
      } else {
        Swal.fire({
          title: "Hold on a sec!",
          text: "Are you sure you want to delete this checklist?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          confirmButtonText: "DELETE CHECKLIST",
          input: "text",
          inputAttributes: {
            autocapitalize: "off",
          },
          inputPlaceholder: "Type 'DELETE' to confirm",
          showLoaderOnConfirm: true,
          preConfirm: async (input) => {
            if (input !== "DELETE") {
              Swal.showValidationMessage(
                "You need to type 'DELETE' to confirm"
              );
            } else {
              try {
                await deleteChecklist(checklistId, auditId);
                fetchChecklists(auditId);
              } catch (error) {
                console.error("Error deleting checklist:", error);
                // Handle error appropriately
              }
            }
          },
          allowOutsideClick: () => !Swal.isLoading(),
        });
      }
    } catch (error) {
      console.error("Error handling audit deletion:", error);
      // Handle error appropriately
    }
  };

  const deleteChecklist = async (checklistId) => {
    try {
      await axios.delete(
        `${apiUrl}/audit/delete_audit_checklist/${checklistId}/${auditId}`
      );
      fetchChecklists(auditId);
    } catch (error) {
      console.error("Error deleting checklist:", error);
      // Handle error appropriately
    }
  };

  const handleAddToAudit = async (checklistId) => {
    if (isAdding) return;

    setIsAdding(true);
    console.log("Adding checklist to audit:", checklistId, auditId);
    try {
      const response = await axios.post(`${apiUrl}/audit/add_audit_checklist`, {
        auditHeaderId: auditId,
        checklist_header_id: checklistId,
      });

      // Assuming fetchChecklists updates the state after fetching
      await fetchChecklists(auditId);
    } catch (error) {
      console.error("Error adding audit detail:", error);
    } finally {
      setIsAdding(false);
    }
  };

  const renderAddedChecklists = () => {
    console.log("Added checklists:", addedChecklists);
  
    return addedChecklists.map((checklist) => (
      <div
        key={checklist.id}
        className="border p-4 rounded-lg shadow-md relative flex flex-col justify-between" 
      >
        {/* Title and Trash Icon */}
        <div className="flex justify-between items-center">
          <h3 className="text-xl font-semibold truncate">
            {checklist.checklist_name}
          </h3>
          <button
            className="text-red-400"
            onClick={() => handleDeleteChecklist(checklist.id, auditId)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
  
        {/* Date Added and Updated On */}
        <div className="mb-2 mt-2">
          <p>Date Added: {formatDate(checklist.created_at)}</p>
          {checklist.updated_at && (
            <p>Updated On: {formatDate(checklist.updated_at)}</p>
          )}
        </div>
  
        {/* Scores */}
        <div className="flex justify-end mt-auto">
          {/* Align the scores to the bottom-right */}
          <ChecklistScores checklistId={checklist.id} auditId={auditId} />
        </div>
      </div>
    ));
  };
  
  

  const renderWeatherModal = () => {
    return (
      <Modal
        isOpen={weatherModalOpen} // Correct prop for opening the modal
        onRequestClose={closeModal} // Ensure it can be closed via other means (like pressing escape)
        contentLabel="Weather Data"
      >
        <div
          style={{
            padding: "20px",
            backgroundColor: "#fff",
            margin: "auto",
            width: "80%",
          }}
        >
          {/* Title indicating the time zone */}
          <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
            Weather Data (in UTC)
          </h2>

          {/* Show loading if weatherData is empty */}
          {weatherData.length === 0 ? (
            <div>Weather data not available :(</div>
          ) : (
            <WeatherLineChart data={weatherData} onClose={closeModal} />
          )}
        </div>
      </Modal>
    );
  };

  const closeAudit = async () => {
    try {
      await axios.post(`${apiUrl}/audit/close_audit/${auditId}`);
      fetchAuditDetails(auditId);
    } catch (error) {
      console.error("Error closing audit:", error);
      // Handle error appropriately
    }
  };

  const handleToggleBottomContainer = () => {
    setShowBottomContainer(!showBottomContainer);
  };

  const handleAddSummary = () => {
    Swal.fire({
      title: "Flight Summary",
      html: `
        <textarea id="swal-input" class="swal2-textarea" placeholder="Type your summary here..." style="width: 60vh; height: 30vh;">${
          auditDetails && auditDetails.summary !== null
            ? auditDetails.summary
            : ""
        }</textarea>
      `,
      focusConfirm: false,
      width: "75vh",
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonColor: theme.primary_colour,
      cancelButtonText: "Close",
      didOpen: () => {
        const textarea = document.getElementById("swal-input");
        textarea.addEventListener(
          "input",
          debounce((event) => {
            handleCommentChange(auditDetails.id, event.target.value);
          }, 500)
        );
        textarea.focus();
        const textLength = textarea.value.length;
        textarea.setSelectionRange(textLength, textLength);
      },
      willClose: () => {
        fetchAuditDetails(auditId);
      },
    });
  };

  const handleCommentChange = debounce(async (auditContentId, summary) => {
    try {
      const response = await axios.post(`${apiUrl}/audit/update_summary`, {
        id: auditContentId,
        summary,
      });

      // Check if response data is null and handle accordingly
      if (response.data && response.data.message) {
        console.log(response.data.message);
      } else {
        console.log("Summary updated successfully.");
      }
    } catch (error) {
      console.error("Error updating comment", error);
    }
  }, 500);

  const updateFlightDetailsOnServer = async (
    auditId,
    takeoffTime,
    landingTime,
    distanceFlown,
    exemptions,
    takeoffBatteryCharge,
    landingBatteryCharge,
    batteryId // New parameter to pass the battery ID
  ) => {
    console.log(
      "Updating flight details",
      auditId,
      takeoffTime,
      landingTime,
      distanceFlown,
      exemptions,
      takeoffBatteryCharge,
      landingBatteryCharge,
      batteryId
    );

    try {
      // Build the payload only with the provided values
      const payload = {};

      if (takeoffTime) {
        payload.takeoff_time = takeoffTime.toISOString();
      }

      if (landingTime) {
        payload.landing_time = landingTime.toISOString();
      }

      if (distanceFlown !== undefined && distanceFlown !== null) {
        payload.distance_flown = distanceFlown;
      }

      if (exemptions !== undefined && exemptions !== null) {
        payload.exemptions = exemptions;
      }

      if (takeoffBatteryCharge !== undefined && takeoffBatteryCharge !== null) {
        payload.takeoff_battery_charge = takeoffBatteryCharge;
      }

      if (landingBatteryCharge !== undefined && landingBatteryCharge !== null) {
        payload.landing_battery_charge = landingBatteryCharge;
      }

      if (batteryId) {
        payload.battery_id = batteryId;
      }

      // Check if the payload is empty
      if (Object.keys(payload).length === 0) {
        console.log("No fields to update");
        return;
      }

      await axios.put(
        `${apiUrl}/flight/update_flight_details/${auditId}`,
        payload
      );
      console.log("Flight details updated successfully");
    } catch (error) {
      console.error("Error updating flight details:", error);
      // Handle error appropriately
    }
    //refresh auditDetails
    //fetchAuditDetails(auditId);
  };

  const handleTakeoffChange = (newValue) => {
    setTakeoffTime(newValue);

    // Ensure that landing time is cleared if takeoff time is after landing time
    if (
      landingTime &&
      newValue &&
      dayjs(newValue).isAfter(dayjs(landingTime))
    ) {
      setLandingTime(null);
      setTotalFlightTime("");
    }

    // Update flight details with the new takeoff time
    updateFlightDetailsOnServer(auditId, newValue, landingTime, distanceFlown);

    //update weather data
    if (
      takeoffTime.isValid() &&
      landingTime.isValid() &&
      auditDetails.location_id
    ) {
      fetchWeatherData(
        auditId,
        takeoffTime,
        landingTime,
        auditDetails.location_id
      );
    }
  };

  const handleLandingChange = (newValue) => {
    if (
      takeoffTime &&
      newValue &&
      dayjs(newValue).isBefore(dayjs(takeoffTime))
    ) {
      Swal.fire({
        icon: "error",
        title: "Invalid Landing Time",
        text: "Landing time cannot be earlier than takeoff time.",
      });
      return;
    }

    setLandingTime(newValue);

    // Calculate the total flight time if both takeoff and landing times are set
    if (takeoffTime && newValue) {
      const duration = dayjs(newValue).diff(dayjs(takeoffTime), "minute");
      setTotalFlightTime(`${Math.floor(duration / 60)}h ${duration % 60}m`);
    }

    // Update flight details with the new landing time
    updateFlightDetailsOnServer(auditId, takeoffTime, newValue, distanceFlown);

    //update weather data
    if (
      takeoffTime.isValid() &&
      landingTime.isValid() &&
      auditDetails.location_id
    ) {
      fetchWeatherData(
        auditId,
        takeoffTime,
        landingTime,
        auditDetails.location_id
      );
    }
  };

  const handleDistanceChange = (event) => {
    const newValue = event.target.value;
    setDistanceFlown(newValue);

    // Update flight details with the new distance flown
    updateFlightDetailsOnServer(auditId, takeoffTime, landingTime, newValue);
  };

  const handleExemptionChange = (event) => {
    console.log("Selected exemption:", event.target.value);
    setSelectedExemption(event.target.value);
    updateFlightDetailsOnServer(
      auditId,
      takeoffTime,
      landingTime,
      distanceFlown,
      event.target.value
    );
  };

  const handleDistanceBlur = () => {
    // Update flight details with the new distance flown only when the input loses focus
    updateFlightDetailsOnServer(
      auditId,
      takeoffTime,
      landingTime,
      distanceFlown // Use the current value from state
    );
  };

  const handleTakeoffBatteryChargeChange = (event) => {
    const value = Math.max(0, Math.min(100, event.target.value)); // Ensure the value is between 0 and 100
    setTakeoffBatteryCharge(value);
  };

  const handleTakeoffBatteryChargeBlur = async () => {
    // Update flight details with the new takeoff battery charge only when the input loses focus
    await updateFlightDetailsOnServer(
      auditId,
      takeoffTime,
      landingTime,
      null, // No change to distanceFlown
      null, // No change to exemptions
      takeoffBatteryCharge, // Use the current takeoff battery charge from state
      landingBatteryCharge, // Include the current landing battery charge
      auditDetails.battery_id
    );
  };

  const handleLandingBatteryChargeChange = (event) => {
    const value = Math.max(0, Math.min(100, event.target.value)); // Ensure the value is between 0 and 100
    setLandingBatteryCharge(value);
  };

  const handleLandingBatteryChargeBlur = async () => {
    // Update flight details with the new landing battery charge only when the input loses focus
    await updateFlightDetailsOnServer(
      auditId,
      takeoffTime,
      landingTime,
      null, // No change to distanceFlown
      null, // No change to exemptions
      takeoffBatteryCharge, // Include the current takeoff battery charge
      landingBatteryCharge, // Use the current landing battery charge from state
      auditDetails.battery_id
    );
  };

  useEffect(() => {
    if (auditId) {
      console.log("Audit ID:", auditId);
      // Fetch audit details and progress
      fetchAuditDetails(auditId);
      fetchProgress();

      // Fetch checklists and render added checklists
      fetchChecklists(auditId).then(() => {
        renderAddedChecklists();
      });
    }
  }, [auditId]);

  const fetchWeatherData = async (
    auditId,
    takeoffTime,
    landingTime,
    locationId
  ) => {
    console.log(
      "Fetching weather data for audit ID:",
      auditId,
      takeoffTime,
      landingTime,
      locationId
    );
    try {
      const response = await axios.post(`${apiUrl}/weather/fetch`, {
        audit_id: auditId,
        takeoff_time: takeoffTime.toISOString(), // Convert to ISO string
        landing_time: landingTime.toISOString(), // Convert to ISO string
        location_id: locationId,
      });
      console.log("Weather data:", auditId, takeoffTime.toISOString(), landingTime.toISOString(), locationId);
      setWeatherData(response.data.weather_data);
      console.log("Weather data:", response.data.weather_data);
    } catch (error) {
      console.error("Error fetching weather data", error);
    }
  };

  useEffect(() => {
    if (auditDetails) {
      const takeoffTime = dayjs(auditDetails.TO_date);
      const landingTime = dayjs(auditDetails.LD_date);
      const locationId = auditDetails.location_id;

      setTakeoffTime(takeoffTime);
      setLandingTime(landingTime);
      setDistanceFlown(auditDetails.flight_distance);
      setSelectedExemption(auditDetails.exemptions);

      // Set initial values based on auditDetails
      if (auditDetails.battery_takeoff_charge !== undefined) {
        setTakeoffBatteryCharge(auditDetails.battery_takeoff_charge);
      }
      if (auditDetails.battery_landing_charge !== undefined) {
        setLandingBatteryCharge(auditDetails.battery_landing_charge);
      }

      // Calculate total flight time if needed
      const flightTime = landingTime.diff(takeoffTime, "minute"); // in minutes
      setTotalFlightTime(`${flightTime} minutes`);

      // Fetch weather data only if both takeoff and landing times are provided
      if (takeoffTime.isValid() && landingTime.isValid() && locationId) {
        fetchWeatherData(auditId, takeoffTime, landingTime, locationId);
      }
    }
  }, [auditDetails]);

  useEffect(() => {
    // Render added checklists whenever addedChecklists state changes
    renderAddedChecklists();
  }, [addedChecklists]);

  //if (isLoading) {
  //  return <div>Loading...</div>; // Show loading message while fetching data
  //}

  if (!auditDetails) {
    return <div>Loading flight log</div>; // Handle case where auditDetails is null
  }

  //Main Render
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />

      <div className="container mx-auto px-6">
        <div className="flex items-center justify-between mt-5 mb-10">
          <div
            onClick={() => navigate("/flight/home")}
            className="text-primary cursor-pointer flex-shrink-0"
          >
            {theme.logoUrl ? (
              <img
                src={theme.logoUrl}
                alt="Membership Logo"
                className="h-16 w-auto"
              />
            ) : (
              <FontAwesomeIcon icon={faHomeLgAlt} size="2x" color="grey" />
            )}
          </div>
          {auditDetails && (
            <h1 className="text-4xl text-primary flex-grow text-center">
              {auditDetails.audit_name}
            </h1>
          )}

          <div className="flex-shrink-0 flex items-center justify-end">
            <StyledNavLink
              to={`/flight/edit_flight/${auditId}`}
              primary
              theme={theme}
              className="w-40 py-2 px-4 rounded-lg text-center"
            >
              Edit Flight
            </StyledNavLink>
          </div>
        </div>

        {/* Top Section */}
        <div className="border rounded-lg p-4 mb-4">
          <div className="flex flex-wrap justify-between items-stretch space-x-2">
            {/* Pilot Details */}
            <AuditDetails className="flex-1 min-h-full p-2">
              <h2 className="text-lg font-bold mb-2">Flight Details</h2>
              <p>
                <strong>PIC:</strong> {auditDetails.pilot_name}
              </p>
              <p>
                <strong>RPO:</strong> {auditDetails.observer}{" "}
                {auditDetails.rpo_location
                  ? `(${auditDetails.rpo_location})`
                  : ""}
              </p>
              <p>
                <strong>Scheduled:</strong>{" "}
                {auditDetails ? formatDate(auditDetails.date_scheduled) : "N/A"}
              </p>
              <p>
                <strong>Location:</strong>{" "}
                {auditDetails ? auditDetails.location : "N/A"}
              </p>
              <p>
                <strong>Address:</strong>{" "}
                {auditDetails ? auditDetails.address : "N/A"}
              </p>
              <p>
                <strong>What3Words:</strong>{" "}
                {auditDetails ? auditDetails.what3words : "N/A"}
              </p>
            </AuditDetails>

            {/* Drone Details */}
            <AuditDetails className="flex-1 min-h-full p-2">
              <h2 className="text-lg font-bold mb-2">Drone Details</h2>
              <p>
                <strong>Drone Model:</strong> {auditDetails.drone}
              </p>
              <p>
                <strong>Controller:</strong> {auditDetails.controller}
              </p>
              <p>
                <strong>Software:</strong> {auditDetails.software}
              </p>
              <p>
                <strong>Battery:</strong> {auditDetails.battery}
              </p>
              <p>
                <strong>Box:</strong> {auditDetails.box}
              </p>
              <p>
                <strong>Other Equipment:</strong> {auditDetails.misc.join(", ")}
              </p>
            </AuditDetails>
          </div>
        </div>

      {/* Middle Section */}
<div
  className={`border rounded-lg p-4 mb-4 transition-all duration-300 ${
    isExpanded ? "h-auto" : "h-50"
  }`}
>
  <div className="flex flex-row space-x-4">
    {/* Checklists Section */}
    <div className="flex-1">
      {showBottomContainer && (
        <BottomContainer>
          {availableBlueprints && availableBlueprints.length > 0 ? (
            <StyledCarousel
              showThumbs={false}
              emulateTouch={true}
              theme={theme}
              centerMode={true}
              centerSlidePercentage={100 / 5}
            >
              {availableBlueprints.map((blueprint) => (
                <div key={blueprint[2]}>
                  <div style={{ textAlign: "center" }}>
                    <h3>{blueprint[3]}</h3>
                  </div>
                  <AddButton
                    onClick={() => handleAddToAudit(blueprint[2])}
                    disabled={isAdding}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </AddButton>
                </div>
              ))}
            </StyledCarousel>
          ) : (
            <NoChecklistsMessage>
              No test cards available to add.
            </NoChecklistsMessage>
          )}
        </BottomContainer>
      )}

      {/* Render added checklists */}
      <ChecklistsGrid>{renderAddedChecklists()}</ChecklistsGrid>
    </div>

    {/* Buttons Section */}
    <div className="flex-1 flex flex-col items-end mb-4 space-y-2">
      <StyledNavLink
        primary
        theme={theme}
        disabled={auditDetails ? auditDetails.status === "Closed" : true}
        onClick={handleAddSummary}
        className="w-40 my-1 py-2 px-4 rounded-lg text-center"
      >
        Add Summary
        {auditDetails && auditDetails.status === "Closed" && (
          <FontAwesomeIcon icon={faLock} className="ml-2" />
        )}
      </StyledNavLink>

      <StyledNavLink
        primary
        theme={theme}
        disabled={auditDetails ? auditDetails.status === "Closed" : true}
        onClick={handleToggle}
        className="w-40 mt-1 py-2 px-4 rounded-lg text-center"
      >
        {showBottomContainer ? "Hide Test Card Menu" : "Add Test Card"}
        {auditDetails && auditDetails.status === "Closed" && (
          <FontAwesomeIcon icon={faLock} className="ml-2" />
        )}
      </StyledNavLink>
    </div>
  </div>
</div>


        {/* Bottom Section */}
        <div className="border rounded-lg p-4 relative w-auto mx-auto">
          {showBottomContainer && <Mask />}
          <div className="flex flex-wrap justify-between items-stretch">
            {/* Left Section: Takeoff, Landing, Total Flight Time, Distance, Exemptions */}
            <div className="w-full md:w-2/3 p-2 flex flex-row items-center space-x-4">
              <div className="flex flex-col">
                <h2 className="text-lg font-bold mb-2">Flight Log</h2>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <div className="flex flex-row space-x-4">
                    <CustomDemoItem label="Takeoff">
                      <MobileDateTimePicker
                        value={takeoffTime}
                        onChange={handleTakeoffChange}
                        sx={{ width: "180px" }}
                      />
                    </CustomDemoItem>
                    <CustomDemoItem label="Landing">
                      <MobileDateTimePicker
                        value={landingTime}
                        onChange={handleLandingChange}
                        sx={{ width: "180px" }}
                      />
                    </CustomDemoItem>
                    <div className="mt-2 flex flex-col">
                      <label className="block text-m font-medium text-gray-700">
                        Total Flight Time
                      </label>
                      <input
                        type="text"
                        value={totalFlightTime}
                        readOnly
                        className="block w-40 h-14 rounded-md border border-gray-300 shadow-m text-center font-semibold focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                    {/* Distance Flown Field */}
                    <div className="mt-2 flex flex-col">
                      <label className="block text-m font-medium text-gray-700">
                        Distance Flown (m)
                      </label>
                      <input
                        type="number"
                        value={distanceFlown}
                        onChange={handleDistanceChange} // Update state on change
                        onBlur={handleDistanceBlur} // Update server on blur
                        className="block w-40 h-14 rounded-md border border-gray-300 shadow-m text-center font-semibold focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                    <div className="mt-2 flex flex-col">
                      <label className="block text-m font-medium text-gray-700">
                        Exemptions
                      </label>
                      <select
                        value={selectedExemption}
                        onChange={handleExemptionChange}
                        className="block w-40 h-14 rounded-md border border-gray-300 shadow-m text-center font-medium focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      >
                        <option value="none">None</option>
                        <option value="BVLOS">BVLOS</option>
                        <option value="< 50m Person">&lt; 50m Person</option>
                        <option value="< 30m Property">
                          &lt; 30m Property
                        </option>
                        <option value=">400ft Ground">&gt;400ft Ground</option>
                      </select>
                    </div>
                  </div>

                  {/* Battery Charge Fields */}
                  <div className="flex flex-row space-x-4 mt-4">
                    <CustomDemoItem label="Takeoff Battery Charge %">
                      <input
                        type="number"
                        value={takeoffBatteryCharge}
                        onChange={handleTakeoffBatteryChargeChange} // Update state on change
                        onBlur={handleTakeoffBatteryChargeBlur} // Update server on blur
                        min="0"
                        max="100"
                        className="mr-4 block w-48 h-14 rounded-md border border-gray-300 shadow-m text-center font-semibold focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        placeholder="0-100"
                      />
                    </CustomDemoItem>
                    <CustomDemoItem label="Landing Battery Charge %">
                      <input
                        type="number"
                        value={landingBatteryCharge}
                        onChange={handleLandingBatteryChargeChange} // Update state on change
                        onBlur={handleLandingBatteryChargeBlur} // Update server on blur
                        min="0"
                        max="100"
                        className="block w-48 h-14 rounded-md border border-gray-300 shadow-m text-center font-semibold focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        placeholder="0-100"
                      />
                    </CustomDemoItem>
                  </div>
                </LocalizationProvider>
              </div>
            </div>

            {/* Right Section: Buttons */}
            <div className="w-full md:w-1/3 p-2 flex flex-col items-end">
              {/* Weather Details */}

              {/* Weather Report Button */}
              <StyledNavLink
                primary
                theme={theme}
                disabled={weatherData.length === 0} // Disable if weather data is not available
                className="block w-40 py-2 px-4 rounded-lg text-center"
                onClick={weatherData.length > 0 ? openModal : null} // Only open the modal if data is available
              >
                View Weather
                {weatherData.length === 0 && (
                  <FontAwesomeIcon icon={faLock} className="ml-2" /> // Show lock icon if disabled
                )}
              </StyledNavLink>

              {/* Render the Weather Report Modal */}
              {renderWeatherModal()}

              <StyledNavLink
                primary
                theme={theme}
                disabled={
                  auditDetails ? auditDetails.status === "Closed" : true
                }
                to={`/flight/audit_live/${auditId}`}
                className="w-40 block mt-2 mb-1 py-2 px-4 rounded-lg text-center"
              >
                Log Test Results
                {auditDetails && auditDetails.status === "Closed" && (
                  <FontAwesomeIcon icon={faLock} className="ml-2" />
                )}
              </StyledNavLink>
              <StyledNavLink
                primary
                theme={theme}
                disabled={
                  auditDetails ? auditDetails.status !== "Complete" : true
                }
                className="w-40 block mt-1 mb-1 py-2 px-4 rounded-lg text-center"
                onClick={closeAudit}
              >
                Close Flight Log
                {auditDetails && auditDetails.status !== "Complete" && (
                  <FontAwesomeIcon icon={faLock} className="ml-2" />
                )}
              </StyledNavLink>
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default auditProtection(CreateAuditPage);
