import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import dayjs from "dayjs";

const apiUrl = process.env.REACT_APP_API_URL;

const AuditProgramForm = ({ onProgramAdded }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const formInitiatedFromHome = location.state?.fromHome || false; // Access 'fromHome' state

  const currentYear = dayjs().year();
  const startOfYear = dayjs().startOf("year").format("YYYY-MM-DD"); // First day of the current year
  const endOfYear = dayjs().endOf("year").format("YYYY-MM-DD"); // Last day of the current year
  
  const [auditProgramHeader, setAuditProgramHeader] = useState({
    program_name: "",
    org_id: "",
    start_date: startOfYear, // Set default to the first day of the current year
    end_date: endOfYear, // Set default to the last day of the current year
    is_active: false,
  });

  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [programDuration, setProgramDuration] = useState(1);
  const [isFormVisible, setIsFormVisible] = useState(true);

  useEffect(() => {
    const membershipToken = localStorage.getItem("MToken");

    axios
      .post(`${apiUrl}/decode-membership-token/${membershipToken}`)
      .then((res) => {
        setAuditProgramHeader((prevState) => ({
          ...prevState,
          org_id: res.data.data.membership_data.org_id,
        }));
      })
      .catch((error) => {
        console.error("Error decoding membership token:", error);
      });
  }, []);

  const handleYearChange = (e) => {
    setSelectedYear(parseInt(e.target.value));
  };

  const handleDurationChange = (e) => {
    setProgramDuration(parseInt(e.target.value));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAuditProgramHeader((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const newAuditProgramHeader = {
        org_id: auditProgramHeader.org_id,
        program_name: auditProgramHeader.program_name,
        start_date: auditProgramHeader.start_date,
        end_date: auditProgramHeader.end_date,
        is_active: true,
      };
      console.log("new header", newAuditProgramHeader);
      const response = await axios.post(
        `${apiUrl}/audit/generate_program_header`,
        newAuditProgramHeader
      );
      console.log(response.data);
  
      setIsFormVisible(false);
  
      // Only navigate back to home if the form was initiated from the home page
      if (formInitiatedFromHome) {
        navigate("/home");
      }
  
      // Notify the parent component to refresh the dropdown
      if (onProgramAdded) {
        onProgramAdded();
      }
      
    } catch (error) {
      console.error("Error creating audit program:", error);
    }
  };
  
  return (
    <div
      className="flex items-center justify-center min-h-screen bg-gray-100" // Flexbox to center the form
    >
      {isFormVisible && (
        <form
          onSubmit={handleSubmit}
          className="flex flex-col w-full max-w-md p-8 space-y-8 bg-white shadow-xl rounded-lg"
        >
          <div className="flex items-center">
            <div className="flex-grow">
              <label className="text-lg mb-2">Test Schedule Name:</label>
              <input
                type="text"
                name="program_name"
                value={auditProgramHeader.program_name}
                onChange={handleChange}
                className="w-full border h-10 border-gray-300 px-3 rounded"
              />
            </div>
          </div>
  
          <div style={{ display: "none" }}>
            <label className="text-lg mb-2">Start Year:</label>
            <input
              type="number"
              name="start_year"
              value={selectedYear}
              onChange={handleYearChange}
              className="w-full border h-10 border-gray-300 px-3 rounded"
            />
          </div>
          <div style={{ display: "none" }}>
            <label className="text-lg mb-2">Program Duration:</label>
            <div>
              {[1, 2, 3].map((duration) => (
                <div key={duration} className="flex items-center">
                  <input
                    type="radio"
                    id={`duration${duration}`}
                    name="program_duration"
                    value={duration}
                    checked={programDuration === duration}
                    onChange={handleDurationChange}
                    className="mr-2"
                  />
                  <label htmlFor={`duration${duration}`}>
                    {duration} Year{duration > 1 && "s"}
                  </label>
                </div>
              ))}
            </div>
          </div>
  
          <button
            type="submit"
            className="bg-primary hover:bg-secondary text-white font-bold py-2 px-4 rounded block mx-auto"
          >
            Add Test Schedule
          </button>
        </form>
      )}
    </div>
  );
  
};

export default AuditProgramForm;
