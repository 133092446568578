import React, { useState } from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import { ButtonGroup, Button, Box } from '@mui/material';

const WeatherLineChart = ({ data }) => {
    // Define the available fields and their shortened labels
    const fields = {
        temperature_2m: "Temp (°C)",
        relative_humidity_2m: "Humidity (%)",
        dew_point_2m: "Dew Point (°C)",
        precipitation: "Precip (mm)",
        pressure_msl: "Pressure (hPa)",
        cloud_cover: "Cloud Cover (%)",
        wind_speed_10m: "Wind 10m (m/s)",
        wind_speed_100m: "Wind 100m (m/s)",
        wind_direction_10m: "Wind Dir 10m (°)",
        wind_direction_100m: "Wind Dir 100m (°)",
        wind_gusts_10m: "Wind Gusts (m/s)"
    };

    // State to hold the selected field
    const [selectedField, setSelectedField] = useState("temperature_2m");

    // Generate labels for the x-axis (time) and data for the selected field
    const labels = data.map(item => new Date(item.date).toLocaleTimeString());
    const chartData = data.map(item => item[selectedField]);

    // Set a single color for the chart line and buttons (e.g., dark blue)
    const darkBlue = "#1E3A8A"; // Dark blue color

    return (
      <Box display="flex" flexDirection="column" alignItems="center">
        {/* LineChart to display the selected data */}
        <LineChart
          width={700}
          height={500}
          series={[
            {
              data: chartData,
              label: fields[selectedField],
              color: darkBlue, // Set the color to dark blue
            }
          ]}
          xAxis={[{ scaleType: 'point', data: labels }]}
        />

        {/* Horizontal button group to select different weather parameters */}
        <ButtonGroup
          variant="contained"
          aria-label="horizontal primary button group"
          style={{ marginTop: '20px', backgroundColor: darkBlue }} // Add space and color
        >
          {Object.keys(fields).map((field) => (
            <Button
              key={field}
              onClick={() => setSelectedField(field)}
              style={{ backgroundColor: darkBlue, color: "#fff" }} // Set button color to match
            >
              {fields[field]} {/* Use the shortened label */}
            </Button>
          ))}
        </ButtonGroup>
      </Box>
    );
};

export default WeatherLineChart;
