import superAdminProtection from '../hoc/superAdminProtection';
import "../components/styles.css";

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
const apiUrl = process.env.REACT_APP_API_URL;


const List_Organisations = (props) => {
  const [organisations, setOrganisations] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
      getAllOrganisations();
  }, []);

  const getAllOrganisations = () => {
    axios({
      method: 'get',
      url: apiUrl + "/all_organisations", // fetch organisations
    })
    .then(response => {
      setOrganisations(response.data);
    })
    .catch(error => {
      console.error('There was an error!', error.response);
      alert("Failed to fetch organizations"); // error message
    });
  };
  return (
  
    <div className="flex flex-col items-center justify-top h-screen">
      <h2 className="text-primary text-4xl text-center mb-12">All Organisations</h2>
      <table className="table-auto w-1/2">
        <thead>
          <tr>
            <th className="px-4 py-2 text-left">ID</th>
            <th className="px-4 py-2 text-left">Name</th>
            <th className="px-4 py-2 text-left">County</th>
            <th className="px-4 py-2 text-left">is_Active</th>
            <th className="px-4 py-2 text-left">Last Updated</th>         
            <th className="px-4 py-2 text-left">Created</th>
          </tr>
        </thead>
        <tbody>
          {organisations.map((profile, index) => (
            <tr key={index} className={index % 2 === 0 ? 'bg-gray-200' : ''}>
              <td className="border px-4 py-2">{profile.id}</td>
              <td className="border px-4 py-2">{profile.name}</td>
                <td className="border px-4 py-2">{profile.address}</td>
                <td className="border px-4 py-2">{profile.is_active ? '✔️' : '❌'}</td>
                <td className="border px-4 py-2">{profile.updated_at ? new Date(profile.updated_at).toLocaleString() : 'N/A'}</td>
                <td className="border px-4 py-2">{new Date(profile.created_at).toLocaleString()}</td>
            </tr>
            ))}
        </tbody>
        </table>
    </div>

)
};
export default superAdminProtection(List_Organisations);