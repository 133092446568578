import React from "react";
import { StyledNavLink } from "./styledComponents.js";

const AdminNavLink = ({ theme, to, label, className = "bg-primary hover:bg-secondary" }) => {
  return (
    <StyledNavLink
      primary
      theme={theme}
      to={to}
      className={`w-4/5 mx-auto block mt-3 mb-3 py-2 px-4 rounded text-white text-center ${className}`}
    >
      {label}
    </StyledNavLink>
  );
};

export default AdminNavLink;