import React from "react";
import AdminNavLink from "./AdminNavLink";






const AdminMenu = ({ theme }) => {
  
  
  
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-4">
      {/* Admin Menu Options */}
      <div>
        <h2 className="text-2xl text-center mb-5 text-primary">Users</h2>
        <AdminNavLink theme={theme} to="/register" label="Register User" />
        <AdminNavLink theme={theme} to="/admin/all_global_profiles" label="List Users" />
        <AdminNavLink theme={theme} to="/admin/add_membership" label="Add Membership" />
      </div>
      <div>
        <h2 className="text-2xl text-center text-primary">Forces</h2>
        <AdminNavLink theme={theme} to="/admin/create_org" label="Register Force" />
        <AdminNavLink theme={theme} to="/admin/list_all_organisations" label="List Forces" />
        <AdminNavLink theme={theme} to="/admin/edit_org" label="Update Force" />
        <AdminNavLink theme={theme} to="/admin/add_org_function" label="Add/View Departments" />
        <AdminNavLink theme={theme} to="/admin/org_active_status" label="Deactivate Force" className="bg-red-300 hover:bg-red-500" />
      </div>
      <div>
        <h2 className="text-2xl text-center text-primary">DFR Data</h2>
        <AdminNavLink theme={theme} to="/flight/home" label="Flight Home" />
        <AdminNavLink theme={theme} to="/flight/createChecklistHeader" label="New Test Card" />
        <AdminNavLink theme={theme} to="/admin/assets" label="Manage Assets" /> 
        <AdminNavLink theme={theme} to="/admin/flight_dashboard" label="Flight Dashboard" />
       
      </div>
    
<div>
  <h2 className="text-2xl text-center text-primary">Maintenance</h2>
  <AdminNavLink theme={theme} to="/maint/home" label="Maintenance Home" disabled={true} />
  <AdminNavLink theme={theme} to="/maint/new_program" label="New Program" disabled={true} />
  <AdminNavLink theme={theme} to="/maint/createJobcardHeader" label="New Job Card" disabled={true} />
</div>


    </div>
  );
};

export default AdminMenu;
