import { useEffect, useState } from "react";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const useOrganisationFunctions = () => {
  const [organisations, setOrganisations] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState("");
  const [allFunctions, setAllFunctions] = useState([]);
  const [selectedFunction, setSelectedFunction] = useState(null);
  const [orgFunctions, setOrgFunctions] = useState([]);
  const [remainingFunctions, setRemainingFunctions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [alias, setAlias] = useState("");
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const orgRes = await axios.get(apiUrl + "/all_organisations");
      setOrganisations(orgRes.data);

      const funcRes = await axios.get(apiUrl + "/all_functions");
      setAllFunctions(funcRes.data);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (selectedOrg) {
      fetchOrgFunctions(selectedOrg.id);
    }
  }, [selectedOrg]);

  const fetchOrgFunctions = async (orgId) => {
    const res = await axios.get(`${apiUrl}/organisation_functions/${orgId}`);
    setOrgFunctions(res.data);
    return res.data;
  };

  const handleOrgSelect = (e) => {
    const selected = organisations.find((org) => org.id == e.target.value);
    setSelectedOrg(selected);

    fetchOrgFunctions(selected.id).then((orgFuncs) => {
      if (orgFuncs) {
        setOrgFunctions(orgFuncs);
        const newRemainingFunctions = allFunctions.filter(
          (func) => !orgFuncs.includes(func.id)
        );
        setRemainingFunctions(newRemainingFunctions);
      }
    });
  };

  const handleFunctionSelect = (id) => {
    setSelectedFunction(id);
  };

  const handleTransferFunction = () => {
    if (selectedFunction) {
      const selectedFunctionData = remainingFunctions.find(
        (func) => func.id === selectedFunction
      );
      if (selectedFunctionData) {
        setAlias(selectedFunctionData.name);
      }
      setIsModalOpen(true);
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedFunction(null);
  };

  const handleModalSubmit = async () => {
    try {
      await handleAddOrganisationFunction(
        selectedOrg.id,
        selectedFunction,
        alias
      );

      setRemainingFunctions((prevVal) =>
        prevVal.filter((func) => func.id !== selectedFunction)
      );

      setAlias("");
    } catch (err) {
      console.error("Failed in handleAddOrganisationFunction:", err);
    }

    handleModalClose();
  };

  const handleAddOrganisationFunction = async () => {
    if (selectedOrg && selectedFunction) {
      try {
        await axios.post(`${apiUrl}/organisation-functions`, {
          org_id: selectedOrg.id,
          function_id: selectedFunction,
          alias: alias,
          is_active: isActive,
        });

        setOrgFunctions((prev) => [...prev, selectedFunction]);
      } catch (error) {
        console.log(error);
      }
    } else {
      alert("Please select an organization and transfer at least one function");
    }
  };

  return {
    organisations,
    selectedOrg,
    allFunctions,
    selectedFunction,
    orgFunctions,
    remainingFunctions,
    isModalOpen,
    alias,
    isActive,
    setAlias,
    setIsActive,
    handleOrgSelect,
    handleFunctionSelect,
    handleTransferFunction,
    handleModalClose,
    handleModalSubmit,
  };
};