import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../components/styles.css";
import { ThemeContext } from "../components/theme.js";
import { DataGrid } from "@mui/x-data-grid";
import Swal from "sweetalert2";
import { StyledButton } from "../components/styledComponents.js";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHomeLgAlt } from "@fortawesome/free-solid-svg-icons";
import { ThemeProvider } from "styled-components";

const apiUrl = process.env.REACT_APP_API_URL;

const decodeMembershipToken = async (membershipToken) => {
  try {
    const response = await axios.post(`${apiUrl}/decode-membership-token/${membershipToken}`);
    return response.data.data.membership_data.org_id;
  } catch (error) {
    console.error('Error decoding membership token:', error);
    throw error;
  }
};

function Assets() {
  const [droneList, setDroneList] = useState([]);
  const [controllerList, setControllerList] = useState([]);
  const [softwareList, setSoftwareList] = useState([]);
  const [miscList, setMiscList] = useState([]);
  const [boxesList, setBoxesList] = useState([]);  
  const [batteryList, setBatteryList] = useState([]);  
  const [orgId, setOrgId] = useState(null);
  const [currentTab, setCurrentTab] = useState("drone");
  const { theme, setTheme } = useContext(ThemeContext);
  const [drones, setDrones] = useState([]);  

  const navigate = useNavigate();


  useEffect(() => {
    const fetchData = async () => {
      const membershipToken = localStorage.getItem("MToken");
      if (!membershipToken) {
        console.error("No membership token found");
        return;
      }

      try {
        const orgId = await decodeMembershipToken(membershipToken);
        console.log("Decoded orgId:", orgId);
        setOrgId(orgId);
      } catch (error) {
        console.error('Error decoding membership token:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (!orgId) {
      console.log("orgId not yet set");
      return;
    }

    console.log("Fetching asset lists with orgId:", orgId);
    fetchAssetList("drone", orgId);
    fetchAssetList("controller", orgId);
    fetchAssetList("software", orgId);
    fetchAssetList("misc", orgId);
    fetchAssetList("box", orgId);  // Fetch boxes list
    fetchAssetList("battery", orgId);  // Fetch battery list
  }, [orgId]);

  const fetchAssetList = async (assetType, orgId) => {
    try {
      const response = await axios.get(`${apiUrl}/assets/${assetType}/${orgId}`);
      console.log(`Fetched ${assetType} list:`, response.data);
      switch (assetType) {
        case "drone":
          setDroneList(response.data);
          break;
        case "controller":
          setControllerList(response.data);
          break;
        case "software":
          setSoftwareList(response.data);
          break;
        case "misc":
          setMiscList(response.data);
          break;
        case "box":  // Added case for boxes
          setBoxesList(response.data);
          break;
        case "battery":  // Added case for batteries
          setBatteryList(response.data);
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(`There was an error fetching the ${assetType} list!`, error);
    }
  };

  const updateAsset = async (asset, assetType) => {
    try {
      const apiMap = {
        drone: "drone",
        controller: "controller",
        software: "software",
        misc: "misc",
        box: "box",  // Added mapping for boxes
        battery: "battery",  // Added mapping for batteries
      };
      await axios.put(`${apiUrl}/assets/${apiMap[assetType]}/${asset.id}`, asset);
      fetchAssetList(`${assetType}`, orgId);
      Swal.fire("Updated!", `The ${assetType} has been updated.`, "success");
    } catch (error) {
      console.error(`There was an error updating the ${assetType}!`, error);
      Swal.fire("Error!", `There was an error updating the ${assetType}.`, "error");
    }
  };

  const deleteAsset = async (id, assetType) => {
    try {
      const apiMap = {
        drone: "drone",
        controller: "controller",
        software: "software",
        misc: "misc",
        box: "box",  // Added mapping for boxes
        battery: "battery",  // Added mapping for batteries
      };
      await axios.delete(`${apiUrl}/assets/${apiMap[assetType]}/${id}`);
      fetchAssetList(`${assetType}`, orgId);
      Swal.fire("Removed!", `The ${assetType} has been removed.`, "success");
    } catch (error) {
      console.error(`There was an error deleting the ${assetType}!`, error);
      Swal.fire("Error!", `There was an error deleting the ${assetType}.`, "error");
    }
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleEdit = (asset, assetType) => {
    const fields = {
      drone: ["code_name", "drone_manufacturer", "drone_model", "drone_category", "home_base", "drone_pn", "drone_sn"],
      controller: ["code_name", "cont_manufacturer", "cont_model", "cont_category", "home_base", "cont_pn", "cont_sn"],
      software: ["code_name", "soft_oem", "soft_category"],
      misc: ["code_name", "oem", "category"],
      box: ["code_name", "box_manufacturer", "home_base", "box_pn", "box_sn"],
      battery: ["drone_id", "code_name", "batt_dateIntoService", "batt_sn"],  // Include `drone_id` for the dropdown
    };
  
    const assetFields = fields[assetType];
    const assetHtml = assetFields.map((field) => {
      const label = `<label for="${field}" class="swal2-label">${field.replace('_', ' ').toUpperCase()}</label>`;
      
      if (field === "drone_id") {
        return `
          <div class="swal2-field-group">
            ${label}
            <select id="${field}" class="swal2-select">
              ${droneList.map(drone => `<option value="${drone.id}" ${asset.drone_id === drone.id ? 'selected' : ''}>${drone.code_name}</option>`).join('')}
            </select>
          </div>
        `;
      } else if (field === "batt_dateIntoService") {
        return `
          <div class="swal2-field-group">
            ${label}
            <input id="${field}" class="swal2-input" type="date" value="${asset[field] ? new Date(asset[field]).toISOString().split('T')[0] : ''}" placeholder="${field.replace('_', ' ').toUpperCase()}">
          </div>
        `;
      } else {
        return `
          <div class="swal2-field-group">
            ${label}
            <input id="${field}" class="swal2-input" value="${asset[field] || ''}" placeholder="${field.replace('_', ' ').toUpperCase()}">
          </div>
        `;
      }
    }).join('');
  
    Swal.fire({
      title: `Edit ${assetType.charAt(0).toUpperCase() + assetType.slice(1)}`,
      html: assetHtml,
      showCancelButton: true,
      confirmButtonText: "Save",
      preConfirm: () => {
        const updatedAsset = {};
        assetFields.forEach((field) => {
          updatedAsset[field] = document.getElementById(field).value;
        });
        return updatedAsset;
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const updatedAsset = result.value;
        updateAsset({ ...asset, ...updatedAsset }, assetType);
      }
    });
  };
  

  const handleDelete = (id, assetType) => {
    Swal.fire({
      title: "Are you sure?",
      text: "This asset will be removed!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, remove it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteAsset(id, assetType);
      }
    });
  };

  const handleAdd = (assetType) => {
    const fields = {
      drone: ["code_name", "drone_manufacturer", "drone_model", "drone_category", "home_base", "drone_pn", "drone_sn"],
      controller: ["code_name", "cont_manufacturer", "cont_model", "cont_category", "home_base", "cont_pn", "cont_sn"],
      software: ["code_name", "soft_oem", "soft_category"],
      misc: ["code_name", "oem", "category"],
      box: ["code_name", "box_manufacturer", "home_base", "box_pn", "box_sn"],
      battery: ["drone_id", "code_name", "batt_dateIntoService", "batt_sn"],  // Omit `batt_decayRate`
    };
  
    const assetFields = fields[assetType];
    const assetHtml = assetFields.map(field => {
      if (field === "drone_id") {
        return `
          <div class="swal2-field-group">
            <label for="${field}" class="swal2-label">Drone</label>
            <select id="${field}" class="swal2-select">
              ${droneList.map(drone => `<option value="${drone.id}">${drone.code_name}</option>`).join('')}
            </select>
          </div>
        `;
      } else if (field === "batt_dateIntoService") {
        return `
          <div class="swal2-field-group">
            <label for="${field}" class="swal2-label">Date Into Service</label>
            <input id="${field}" class="swal2-input" type="date" placeholder="${field.replace('_', ' ').toUpperCase()}">
          </div>
        `;
      } else {
        return `<input id="${field}" class="swal2-input" placeholder="${field.replace('_', ' ').toUpperCase()}">`;
      }
    }).join('');
  
    Swal.fire({
      title: `Add ${assetType.charAt(0).toUpperCase() + assetType.slice(1)}`,
      html: assetHtml,
      showCancelButton: true,
      confirmButtonText: "Add",
      preConfirm: () => {
        const newAsset = { owner_id: orgId, is_active: true };  // Add orgId and set is_active to true by default
        assetFields.forEach(field => {
          newAsset[field] = document.getElementById(field).value;
        });
        return newAsset;
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const newAsset = result.value;
        addAsset(newAsset, assetType);
      }
    });
  };
  
  

  const addAsset = async (asset, assetType) => {
    console.log("Adding asset:", asset);
    console.log("Asset type:", assetType);
    try {
      const apiMap = {
        drone: "drone",
        controller: "controller",
        software: "software",
        misc: "misc",
        box: "box",  // Added mapping for boxes
        battery: "battery",  // Added mapping for batteries
      };
      await axios.post(`${apiUrl}/assets/${apiMap[assetType]}`, asset);
      fetchAssetList(`${assetType}`, orgId);  // Fetch the updated list
      Swal.fire("Added!", `The ${assetType} has been added.`, "success");
    } catch (error) {
      console.error(`There was an error adding the ${assetType}!`, error);
      Swal.fire("Error!", `There was an error adding the ${assetType}.`, "error");
    }
  };

  const getRows = () => {
    switch (currentTab) {
      case "drone":
        return droneList;
      case "controller":
        return controllerList;
      case "software":
        return softwareList;
      case "misc":
        return miscList;
      case "box":  // Added case for boxes
        return boxesList;
      case "battery":  // Added case for batteries
        return batteryList;
      default:
        return [];
    }
  };

  const columns = {
    drone: [
      { field: "code_name", headerName: "Code Name", flex: 1 },
      { field: "drone_manufacturer", headerName: "Manufacturer", flex: 1 },
      { field: "drone_model", headerName: "Model", flex: 1 },
      { field: "drone_category", headerName: "Category", flex: 1 },
      { field: "home_base", headerName: "Home Base", flex: 1 },
      { field: "drone_pn", headerName: "Part Number", flex: 1 },
      { field: "drone_sn", headerName: "Serial Number", flex: 1 },
     
      {
        field: "actions",
        headerName: "Actions",
        flex: 1,
        renderCell: (params) => (
          <div>
            <button onClick={() => handleEdit(params.row, "drone")}>Edit</button>
            <button onClick={() => handleDelete(params.row.id, "drone")}>
              Remove
            </button>
          </div>
        ),
      },
    ],
    controller: [
      { field: "code_name", headerName: "Code Name", flex: 1 },
      { field: "cont_manufacturer", headerName: "Manufacturer", flex: 1 },
      { field: "cont_model", headerName: "Model", flex: 1 },
      { field: "cont_category", headerName: "Category", flex: 1 },
      { field: "home_base", headerName: "Home Base", flex: 1 },
      { field: "cont_pn", headerName: "Part Number", flex: 1 },
      { field: "cont_sn", headerName: "Serial Number", flex: 1 },
     
      {
        field: "actions",
        headerName: "Actions",
        flex: 1,
        renderCell: (params) => (
          <div>
            <button onClick={() => handleEdit(params.row, "controller")}>Edit</button>
            <button onClick={() => handleDelete(params.row.id, "controller")}>
              Remove
            </button>
          </div>
        ),
      },
    ],
    software: [
      { field: "code_name", headerName: "Code Name", flex: 1 },
      { field: "soft_oem", headerName: "OEM", flex: 1 },
      { field: "soft_category", headerName: "Category", flex: 1 },
      
      {
        field: "actions",
        headerName: "Actions",
        flex: 1,
        renderCell: (params) => (
          <div>
            <button onClick={() => handleEdit(params.row, "software")}>Edit</button>
            <button onClick={() => handleDelete(params.row.id, "software")}>
              Remove
            </button>
          </div>
        ),
      },
    ],
    misc: [
      { field: "code_name", headerName: "Code Name", flex: 1 },
      { field: "oem", headerName: "OEM", flex: 1 },
      { field: "category", headerName: "Category", flex: 1 },
     
      {
        field: "actions",
        headerName: "Actions",
        flex: 1,
        renderCell: (params) => (
          <div>
            <button onClick={() => handleEdit(params.row, "misc")}>Edit</button>
            <button onClick={() => handleDelete(params.row.id, "misc")}>
              Remove
            </button>
          </div>
        ),
      },
    ],
    box: [  // Added columns for boxes
      { field: "code_name", headerName: "Code Name", flex: 1 },
      { field: "box_manufacturer", headerName: "Manufacturer", flex: 1 },
      { field: "home_base", headerName: "Home Base", flex: 1 },
      { field: "box_pn", headerName: "Part Number", flex: 1 },
      { field: "box_sn", headerName: "Serial Number", flex: 1 },
      
      {
        field: "actions",
        headerName: "Actions",
        flex: 1,
        renderCell: (params) => (
          <div>
            <button onClick={() => handleEdit(params.row, "box")}>Edit</button>
            <button onClick={() => handleDelete(params.row.id, "box")}>
              Remove
            </button>
          </div>
        ),
      },
    ],
    battery: [
      { field: "code_name", headerName: "Code Name", flex: 1 },
      {
        field: "batt_dateIntoService",
        headerName: "Date Into Service",
        flex: 1,
        // Remove the valueFormatter since the date is already in string format
      },
      { field: "batt_sn", headerName: "Serial Number", flex: 1 },
     
      {
        field: "actions",
        headerName: "Actions",
        flex: 1,
        renderCell: (params) => (
          <div>
            <button onClick={() => handleEdit(params.row, "battery")}>Edit</button>
            <button onClick={() => handleDelete(params.row.id, "battery")}>
              Remove
            </button>
          </div>
        ),
      },
    ],
  };
    

  return (
    <ThemeProvider theme={theme}>
      <div className="container mx-auto px-6">
        <div className="flex items-center justify-between mt-5 mb-10">
          <div
            onClick={() => navigate("/admin")}
            className="text-primary cursor-pointer flex-shrink-0"
          >
            {theme.logoUrl ? (
              <img
                src={theme.logoUrl}
                alt="Membership Logo"
                className="h-20 w-auto"
              />
            ) : (
              <FontAwesomeIcon icon={faHomeLgAlt} size="2x" color="grey" />
            )}
          </div>
          <h1 className="text-4xl text-primary flex-grow text-center">
            Asset Management
          </h1>
          <div className="w-20"> {/* Placeholder to balance the header */}</div>
        </div>

        {/* Main content area */}
        <div className="relative flex bg-white min-h-screen">
          <div className="flex flex-col w-full p-6">
           
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={currentTab} onChange={handleTabChange}>
                <Tab label="Drones" value="drone" />
                <Tab label="Controllers" value="controller" />
                <Tab label="Software" value="software" />
                <Tab label="Miscellaneous" value="misc" />
                <Tab label="Boxes" value="box" />  {/* Added tab for boxes */}
                <Tab label="Batteries" value="battery" />  {/* Added tab for batteries */}
              </Tabs>
            </Box>
            {console.log("Current Tab:", currentTab)}
            <StyledButton onClick={() => handleAdd(currentTab)} style={{ marginBottom: "1rem", backgroundColor: '#1f305e', color: '#FFFFFF', padding: '10px 20px', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
              Add New {currentTab.charAt(0).toUpperCase() + currentTab.slice(1)}
            </StyledButton>

            <DataGrid
              columns={columns[currentTab]}
              rows={getRows()}
              autoHeight
              disableSelectionOnClick
              componentsProps={{
                cell: {
                  style: {
                    color: '#333', // Darker font color
                  },
                },
              }}
              style={{
                backgroundColor: theme.background,
                color: theme.primary_text_and_icon_colour,
              }}
            />
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default Assets;
