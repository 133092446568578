// components/fetchUserID.js
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchUserID = async (setUserID, navigate) => {
  const token = localStorage.getItem("access_token");

  try {
    const userRes = await axios.get(apiUrl + "/get_user_id_from_token", {
      headers: { Authorization: `Bearer ${token}` },
    });
    const userID = userRes.data.user_id;
    setUserID(userID);
  } catch (error) {
    console.error("There was an error!", error.response);
    if (error.response && error.response.status === 401) {
      navigate("/login");
    }
  }
};
