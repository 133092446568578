import superAdminProtection from "../hoc/superAdminProtection";
import "../components/styles.css";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const EditOrganisation = () => {
  const navigate = useNavigate();
  // Put all fields into the state
  const [Organisations, setOrganisations] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [orgDetails, setOrgDetails] = useState({
    is_active: false,
    name: "",
    address: "",
    primary_colour: "",
    accent_colour: "",
    primary_text_and_icon_colour: "",
    secondary_text_and_icon_colour: "",
    
  });

  useEffect(() => {
    fetchOrganisations();
  }, []);

  const fetchOrganisations = async () => {
    const res = await axios.get(apiUrl + "/all_organisations");
    setOrganisations(res.data);
  };

  const handleOrgSelect = (e) => {
    const selected = Organisations.find((org) => org.name === e.target.value);
    setSelectedOrg(selected);

    // update all states following selected organization
    setOrgDetails({
      ...orgDetails,
      is_active: selected?.is_active,
      name: selected?.name,
      address: selected?.address,
      primary_color: selected?.primary_colour,
      accent_colour: selected?.accent_colour,
      primary_text_and_icon_colour: selected?.primary_text_and_icon_colour,
      secondary_text_and_icon_colour: selected?.secondary_text_and_icon_colour,
      
    });
  };

  const handleInput = (e) => {
    setOrgDetails((prevState) => ({
      ...prevState,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    }));
  };

  const handleSubmit = async () => {
    if (selectedOrg) {
      const res = await axios.patch(
        apiUrl + `/update_organisations/${selectedOrg.id}`,
        orgDetails
      );
      console.log(res.data.message); // Log the response message.
      fetchOrganisations(); // Fetch Organisations again to get the most updated data.
      navigate("/admin/list_all_organisations"); // Navigates to desired page
    }
  };

  return (
<div className="flex flex-col items-center justify-center h-screen bg-secondary2 text-gray-800">
      <h2 className="text-4xl text-center mb-10">
        Update Force Details
      </h2>
      <div className="flex flex-col w-full max-w-md p-8 space-y-8 bg-white shadow-xl rounded-lg">
        <div>
          <label className="text-lg mb-2">Select a Force:</label>
          <select
            onChange={handleOrgSelect}
            className="w-full border h-10 border-gray-300 px-3 rounded"
          >
            <option value="">--Select a Force--</option>
            {Organisations.map((org) => (
              <option key={org.id} value={org.name}>
                {org.name}
              </option>
            ))}
          </select>
        </div>
        {orgDetails && (
          <div>
            <label className="text-lg mb-2">Name:</label>
            <input
              type="text"
              value={orgDetails.name}
              name="name"
              onChange={handleInput}
              className="w-full border h-10 border-gray-300 px-3 rounded"
            />
            <br />
            <label className="text-lg mb-2">Address:</label>
            <input
              type="text"
              value={orgDetails.address}
              name="address"
              onChange={handleInput}
              className="w-full border h-10 border-gray-300 px-3 rounded"
            />
            <br />
            <label className="text-lg mb-2">Primary Colour:</label>
            <input
              type="color"
              value={orgDetails.primary_colour}
              name="primary_colour"
              onChange={handleInput}
              className="w-full border h-10 border-gray-300 px-3 rounded"
            />
            <br />
            <label className="text-lg mb-2">Accent Colour:</label>
            <input
              type="color"
              value={orgDetails.accent_colour}
              name="accent_colour"
              onChange={handleInput}
              className="w-full border h-10 border-gray-300 px-3 rounded"
            />
            <br />
            <label className="text-lg mb-2">
              Primary Text and Icon Colour:
            </label>
            <input
              type="color"
              value={orgDetails.primary_text_and_icon_colour}
              name="primary_text_and_icon_colour"
              onChange={handleInput}
              className="w-full border h-10 border-gray-300 px-3 rounded"
            />
            <br />
            <label className="text-lg mb-2">
              Secondary Text and Icon Colour:
            </label>
            <input
              type="color"
              value={orgDetails.secondary_text_and_icon_colour}
              name="secondary_text_and_icon_colour"
              onChange={handleInput}
              className="w-full border h-10 border-gray-300 px-3 rounded"
            />
           
            <button
              type="submit"
              onClick={handleSubmit}
              className="bg-primary hover:bg-secondary text-white font-bold py-2 px-12 rounded mt-4 block mx-auto"
            >
              Submit
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default superAdminProtection(EditOrganisation);