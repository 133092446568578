import React, { useState, useEffect, useContext } from "react";
import { BounceLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  StyledButton,
  StyledListItem,
  StyledListItemContent,
  StyledNavLink,
} from "../components/styledComponents.js";
import { ThemeContext } from "../components/theme.js";
import Swal from "sweetalert2";
import axios from "axios";
import Switch from "react-switch";
import { ThemeProvider } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faEdit,
  faRobot,
  faPen,
  faHomeLgAlt,
  faCircleQuestion,
  faSquarePlus,
} from "@fortawesome/free-solid-svg-icons";

const apiUrl = process.env.REACT_APP_API_URL;

function AuditChecklistComponent() {
  const { theme, setTheme } = useContext(ThemeContext);
  const [auditDetails, setAuditDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [groupedAuditDetails, setGroupedAuditDetails] = useState([]);
  const [user_ID, setUserID] = useState("");
  const [topics, setTopics] = useState([]);
  const navigate = useNavigate();
  const [searchTerms, setSearchTerms] = useState("");
  const [results, setResults] = useState([]);
  const [andSearch, setAndSearch] = useState(true);
  const [activeIndex, setActiveIndex] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [unselectedItems, setUnselectedItems] = useState([]);
  const [noResults, setNoResults] = useState(false);
  const [parentSeqNums, setParentSeqNums] = useState({});
  const [selectedItemIds, setSelectedItemIds] = useState([]);
  const [aiModalOpen, setAIModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState([]);
  const [isOpen, setIsOpen] = useState({});
  const [questionSeqNums, setQuestionSeqNums] = useState({});
  const { checklistId } = useParams(); // get the id from the url
  const [topicUpdateStatus, setTopicUpdateStatus] = useState(false);
  let sortedKeys = [...selectedItems, ...unselectedItems];

  //***********Handlers*************
  const toggleOpen = (id, event) => {
    setIsOpen((prev) => ({ ...prev, [id]: !prev[id] }));
    event.stopPropagation();
  };

  const handleToggle = (parentKey) => {
    if (Array.isArray(results[parentKey])) {
      setModalContent(results[parentKey]);
      setAIModalOpen(true);
    }
  };

  const handleDeleteQuestion = async (topicID, questionID, checklistId) => {
    try {
      const response = await axios.get(
        `${apiUrl}/audit/checklist_detail_exists/${checklistId}`
      );
      const hasDetails = response.data.hasDetails;
      console.log(checklistId);
      console.log("hasDetails", hasDetails);

      if (hasDetails) {
        // When question cannot be deleted
        Swal.fire({
          title:
            "Questions in this checklist cannot be deleted because they have been associated with an Audit",
          icon: "warning",
          showCancelButton: false,
          cancelButtonColor: "#3085d6",
          cancelButtonText: "EXIT",
        });
      } else {
        // When question can be deleted
        Swal.fire({
          title: "Hold on a sec!",
          text: "Are you sure you want to delete this question?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          confirmButtonText: "DELETE QUESTION",
          input: "text",
          inputAttributes: {
            autocapitalize: "off",
          },
          inputPlaceholder: "Type 'DELETE' to confirm",
          showLoaderOnConfirm: true,
          preConfirm: async (input) => {
            if (input !== "DELETE") {
              Swal.showValidationMessage(
                "You need to type 'DELETE' to confirm"
              );
            } else {
              try {
                await deleteQuestion(topicID, questionID);
                // If deletion is successful, you may want to perform any additional actions or update UI accordingly
              } catch (error) {
                console.error("Error deleting question:", error);
                // Handle error appropriately
              }
            }
          },
          allowOutsideClick: () => !Swal.isLoading(),
        });
      }
    } catch (error) {
      console.error("Error handling checklist deletion:", error);
      // Handle error appropriately
    }
  };

  const deleteQuestion = async (topicId, questionId) => {
    // Create a copy of the topics array
    const updatedTopics = [...topics];
    console.log("removeQ started", topicId, questionId);
    // Find the target topic by its ID
    const targetTopic = updatedTopics.find((topic) => topic.id === topicId);

    if (targetTopic && targetTopic.questions) {
      // Find the index of the question within the target topic's questions array by its ID
      const questionIndexToRemove = targetTopic.questions.findIndex(
        (question) => question.ID === questionId
      );

      if (questionIndexToRemove !== -1) {
        // Remove the question from the target topic's questions array
        targetTopic.questions.splice(questionIndexToRemove, 1);
        try {
          // Make an Axios DELETE request to the remove_checklist_detail endpoint
          const response = await axios.delete(
            `${apiUrl}/audit/remove_checklist_question/${topicId}/${questionId}`
          );

          if (response.status === 200) {
            // Handle successful removal here, such as updating the UI state
            // ...
          } else {
            console.error("API Failed to remove checklist detail");
          }
        } catch (error) {
          console.error(
            "An error occurred while removing checklist detail",
            error
          );
        }
        // Update the state with the modified topics
        setTopics(updatedTopics);
      } else {
        console.error("Question not found within the topic's questions array.");
      }
    } else {
      console.error("Topic not found by ID or no questions found.");
    }
  };

  const deleteTopic = async (topicId) => {
    // Create a copy of the topics array
    const updatedTopics = [...topics];

    // Find the index of the target topic by its ID
    const topicIndexToRemove = updatedTopics.findIndex(
      (topic) => topic.id === topicId
    );

    if (topicIndexToRemove !== -1) {
      // Remove the topic from the topics array
      updatedTopics.splice(topicIndexToRemove, 1);
      try {
        const response = await axios.delete(
          `${apiUrl}/audit/remove_topic/${topicId}`
        );

        if (response.status === 200) {
          // Handle successful removal here, such as updating the UI state
          // ...
        } else {
          console.error("API Failed to remove topic");
        }
      } catch (error) {
        console.error("An error occurred while removing topic", error);
      }
      // Update the state with the modified topics
      setTopics(updatedTopics);
    } else {
      console.error("Topic not found by ID.");
    }
  };

  const handleEditTopic = async (topicId, currentTopicName) => {
    const { value: newName } = await Swal.fire({
      title: "Edit Topic Name",
      input: "text",
      inputValue: currentTopicName,
      inputPlaceholder: "Enter new name",
      showCancelButton: true,
    });

    if (newName) {
      try {
        await axios.put(`${apiUrl}/audit/update_topic_name/${topicId}`, {
          topic_name: newName,
        });
        // Optionally handle success
        setTopicUpdateStatus((prev) => !prev);
      } catch (error) {
        console.error("Error updating topic name:", error);
        // Optionally handle error
      }
    }
  };

  const handleEditQuestion = async (questionId, checklistId, topicId, qorder) => {
    try {
      // Fetch current question details from the database
      const response = await axios.get(`${apiUrl}/audit/get_question/${questionId}`);
      const questionData = response.data;
      console.log("Question Data:", questionData);
  
      // Show Swal popup to get user input with pre-filled current values
      const { value } = await Swal.fire({
        title: "Edit Question",
        html: `
          <textarea id="userInput" class="swal2-textarea" placeholder="Enter your question here..." style="resize: vertical; width: 90%; height: 20vh; margin-bottom: 10px;">${questionData.question}</textarea>
          <div style="margin-bottom: 40px;">
            <p>Select a Policing Outcome:</p>
            <select id="dropdownList" class="swal2-select" style="width: 90%; border: 1px solid #ccc;">
              <option value="">Select an outcome...</option>
            </select>
          </div>
          <div style="display: flex; align-items: center; justify-content: center; margin-bottom: 30px;">
            <label class="switch">
              <input type="checkbox" id="extraFieldCheckbox" ${questionData.add_ans_UOM ? 'checked' : ''}>
              <span class="slider round"></span>
            </label>
            <label for="extraFieldCheckbox" style="margin-left: 10px;">Additional data collection</label>
          </div>
          <div id="extraFieldContainer" style="${questionData.add_ans_UOM ? 'display: flex;' : 'display: none;'} flex-direction: column; justify-content: center; align-items: center; margin-bottom: 10px;">
            <select id="unitOfMeasureDropdown" class="swal2-select" style="width: 55%; margin-left: 5%; border: 1px solid #ccc;">
              <!-- Options will be dynamically populated below -->
            </select>
            <input id="addAnsPrompt" class="swal2-input" placeholder="Enter prompt text..." value="${questionData.add_ans_prompt || ''}" style="margin-top: 10px;" />
          </div>
        `,
        showCancelButton: true,
        width: "75vh",
        confirmButtonText: "Update Question",
        confirmButtonColor: "#0b5394",
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading(),
        preConfirm: async () => {
          const userInput = document.getElementById("userInput").value;
          const selectedRegulation = document.getElementById("dropdownList").value;
          const isExtraFieldChecked = document.getElementById("extraFieldCheckbox").checked;
          const unitOfMeasure = isExtraFieldChecked ? document.getElementById("unitOfMeasureDropdown").value : null;
          const addAnsPrompt = isExtraFieldChecked ? document.getElementById("addAnsPrompt").value : null;
  
          // Validate required fields
          if (!userInput || !selectedRegulation) {
            Swal.showValidationMessage('Please complete all required fields.');
            return null;
          }
  
          return { userInput, selectedRegulation, unitOfMeasure, addAnsPrompt };
        },
        didOpen: async () => {
          try {
            // Fetch all regulations and populate the dropdown
            const { allRegulations } = await fetchAllRegulations();
            populateOutcomesDropdown(allRegulations, questionData.erule);  // Populate with all options and preselect the current one
  
            // Populate the UOM dropdown with the preselected value
            populateUOMDropdown(questionData.add_ans_UOM); // Populate the UOM dropdown
          } catch (error) {
            console.error("Error fetching all regulations:", error);
          }
  
          // Attach event listener to show/hide extra fields
          document.getElementById("extraFieldCheckbox").addEventListener("change", (event) => {
            const extraFieldContainer = document.getElementById("extraFieldContainer");
            extraFieldContainer.style.display = event.target.checked ? "flex" : "none";
          });
        },
      });
  
      if (value) {
        const { userInput, selectedRegulation, unitOfMeasure, addAnsPrompt } = value;
  
        // Log the values for debugging
        console.log("User Input:", userInput);
        console.log("Selected Regulation:", selectedRegulation);
        console.log("Unit of Measure:", unitOfMeasure);
        console.log("Additional Answer Prompt:", addAnsPrompt);
  
        // Update the question in the database
        await axios.put(`${apiUrl}/audit/update_question/${questionId}`, {
          question: userInput,
          erule: selectedRegulation,
          add_ans_UOM: unitOfMeasure,
          add_ans_prompt: addAnsPrompt,
        });
  
        // Optionally trigger a UI update or success message
        setTopicUpdateStatus((prevStatus) => !prevStatus);
      }
    } catch (error) {
      console.error("Error displaying Swal or updating question:", error);
      Swal.fire({
        title: "Error",
        text: "Failed to update question",
        icon: "error",
      });
    }
  };
  
  function populateOutcomesDropdown(allRegulations, selectedErule) {
    const dropdownList = document.getElementById('dropdownList');
    dropdownList.innerHTML = '';  // Clear existing options
  
    allRegulations.forEach((regulation) => {
      const option = document.createElement('option');
      option.value = regulation.erule;  // Set the value to the erule
      option.textContent = regulation.title;  // Display the title
  
      // Preselect the option that matches the current erule
      if (regulation.erule === selectedErule) {
        option.selected = true;
      }
  
      dropdownList.appendChild(option);  // Add the option to the dropdown
    });
  
    console.log("Policing Outcome dropdown populated");
  }
   
  function populateUOMDropdown(selectedUOM) {
    const uomDropdown = document.getElementById('unitOfMeasureDropdown');
    uomDropdown.innerHTML = '';  // Clear existing options
  
    const unitOfMeasures = [
      "Time (Hours)",
      "Time (Minutes)",
      "Time (Seconds)",
      "Distance (Kilometers)",
      "Distance (Meters)",
      "Distance (Feet)",
      "Weight (Kilograms)",
      "Weight (Grams)",
      "Height (Meters)",
      "Height (Feet)",
      "Speed (m/s)",
      "Speed (km/h)",
      "Speed (mph)",
      "Speed (knots)",
      "Data Speed (Mb/s)",
      "Temperature (Celsius)",
      "Area (Square Meters)",
      "Area (Square Kilometers)",
      "Area (Square Miles)"
    ];
  
    unitOfMeasures.forEach((uom) => {
      const option = document.createElement('option');
      option.value = uom;
      option.textContent = uom;
  
      // Preselect the option that matches the current UOM
      if (uom === selectedUOM) {
        option.selected = true;
      }
  
      uomDropdown.appendChild(option);
    });
  
    console.log("UOM dropdown populated with", selectedUOM);
  }
  

  const handleDeleteTopic = async (topicID, checklistId) => {
    try {
      const response = await axios.get(
        `${apiUrl}/audit/checklist_detail_exists/${checklistId}`
      );
      const hasDetails = response.data.hasDetails;
      console.log(checklistId);
      console.log("hasDetails", hasDetails);

      if (hasDetails) {
        // When question cannot be deleted
        Swal.fire({
          title:
            "Topics in this checklist cannot be deleted because they have been associated with an Audit",
          icon: "warning",
          showCancelButton: false,
          cancelButtonColor: "#3085d6",
          cancelButtonText: "EXIT",
        });
      } else {
        // When question can be deleted
        Swal.fire({
          title: "Hold on a sec!",
          text: "Are you sure you want to delete this topic?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          confirmButtonText: "DELETE TOPIC",
          input: "text",
          inputAttributes: {
            autocapitalize: "off",
          },
          inputPlaceholder: "Type 'DELETE' to confirm",
          showLoaderOnConfirm: true,
          preConfirm: async (input) => {
            if (input !== "DELETE") {
              Swal.showValidationMessage(
                "You need to type 'DELETE' to confirm"
              );
            } else {
              try {
                await deleteTopic(topicID);
                // If deletion is successful, you may want to perform any additional actions or update UI accordingly
              } catch (error) {
                console.error("Error deleting question:", error);
                // Handle error appropriately
              }
            }
          },
          allowOutsideClick: () => !Swal.isLoading(),
        });
      }
    } catch (error) {
      console.error("Error handling checklist deletion:", error);
      // Handle error appropriately
    }
  };

  const handleSelectItem = (parentKey, event) => {
    event.stopPropagation();
    console.log("Checkbox clicked with index:", parentKey);
    const newSelectedItems = [...selectedItems];
    const newUnselectedItems = [...unselectedItems];

    if (unselectedItems.includes(parentKey)) {
      const selectedIndex = unselectedItems.indexOf(parentKey);
      newUnselectedItems.splice(selectedIndex, 1);
      newSelectedItems.push(parentKey);
    } else if (selectedItems.includes(parentKey)) {
      const unselectedIndex = selectedItems.indexOf(parentKey);
      newSelectedItems.splice(unselectedIndex, 1);
      newUnselectedItems.push(parentKey);
    }
    setSelectedItems(newSelectedItems);
    setUnselectedItems(newUnselectedItems);
  };

  const handleAddTopic = () => {
    Swal.fire({
      title: "Enter the new topic",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Add",
      showLoaderOnConfirm: true,

      preConfirm: async (newTopic) => {
        if (newTopic) {
          // Log the values you're about to send in the Axios request
          console.log("Adding Topic with the following data:");
          console.log("title:", newTopic);
          console.log("checklist_id:", checklistId); // Make sure checklistId is defined
          console.log("topic_order:", topics.length);

          try {
            const response = await axios.post(`${apiUrl}/audit/add_topic`, {
              topic_name: newTopic,
              checklist_id: checklistId, // Replace with the actual checklist ID
              topic_order: topics.length, // Use the current length as the topic order
            });
            setTopics([...topics, response.data]);
          } catch (error) {
            console.error("Error adding topic:", error);
          }
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  //*********fetch functions******************

  function groupByParent(results) {
    return results.reduce((acc, current) => {
      if (!acc.hasOwnProperty(current.regulatory_source_parent)) {
        acc[current.regulatory_source_parent] = [];
      }
      acc[current.regulatory_source_parent].push(current);

      return acc;
    }, {});
  }

  const fetchUserID = async () => {
    const token = localStorage.getItem("access_token");
    try {
      const userRes = await axios.get(apiUrl + "/get_user_id_from_token", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUserID(userRes.data.user_id);
      console.log(userRes.data.user_id);
      console.log(user_ID);
    } catch (error) {
      console.error("There was an error!", error.response);
      if (error.response && error.response.status === 401) {
        navigate("/login");
      }
    }
  };

  const fetchSearchResults = async () => {
    setNoResults(false);
    setIsLoading(true);
    // clear the previous results
    setResults([]);
    const endpoint = andSearch
      ? "/and-search-regulation-keywords/"
      : "/or-search-regulation-keywords/";
    try {
      const response = await axios.get(
        apiUrl + `/audit${endpoint}${searchTerms}`
      );
      const groupedResults = groupByParent(response.data);
      if (Object.keys(groupedResults).length === 0) {
        setNoResults(true);
        console.log("No results found!");
      } else {
        setUnselectedItems(Object.keys(groupedResults));
        setResults(groupedResults);
        console.log(groupedResults);
      }
    } catch (error) {
      console.error("There was an error in fetching the results!", error);
    }
    setIsLoading(false);
  };


  const handleAddQuestionClick = async (topicIndex, topic_id, qorder) => {
    try {
      // Show Swal popup to get user input
      const { value } = await Swal.fire({
        title: "Write Your Own Question",
        html: `
          <textarea id="userInput" class="swal2-textarea" placeholder="Enter your question here..." style="resize: vertical; width: 90%; height: 20vh; margin-bottom: 10px;"></textarea>
          <div style="margin-bottom: 40px;">
            <p>Select a Policing Outcome:</p>
            <select id="dropdownList" class="swal2-select" style="width: 90%; border: 1px solid #ccc;">
              <option value="">Select an outcome...</option>
            </select>
          </div>
          <div style="display: flex; align-items: center; justify-content: center; margin-bottom: 30px;">
            <label class="switch">
              <input type="checkbox" id="extraFieldCheckbox">
              <span class="slider round"></span>
            </label>
            <label for="extraFieldCheckbox" style="margin-left: 10px;">Additional data collection</label>
          </div>
          <div id="extraFieldContainer" style="display: none; flex-direction: column; justify-content: center; align-items: center; margin-bottom: 10px;">
            <select id="unitOfMeasureDropdown" class="swal2-select" style="width: 55%; margin-left: 5%; border: 1px solid #ccc;"></select>
            <input id="addAnsPrompt" class="swal2-input" placeholder="Enter prompt text..." style="margin-top: 10px;" />
          </div>
        `,
        showCancelButton: true,
        width: "75vh",
        confirmButtonText: "Add Question",
        confirmButtonColor: "#0b5394",
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading(),
        preConfirm: async () => {
          const userInput = document.getElementById("userInput").value;
          const selectedRegulation = document.getElementById("dropdownList").value;
  
          // Check if the additional field checkbox is checked
          const isExtraFieldChecked = document.getElementById("extraFieldCheckbox").checked;
          const unitOfMeasure = isExtraFieldChecked ? document.getElementById("unitOfMeasureDropdown").value : null;
          const addAnsPrompt = isExtraFieldChecked ? document.getElementById("addAnsPrompt").value : null;
  
          // Validate required fields
          if (!userInput || !selectedRegulation) {
            Swal.showValidationMessage('Please complete all required fields.');
            return null;
          }
  
          return { userInput, erule: selectedRegulation, unitOfMeasure, addAnsPrompt };
        },
        didOpen: async () => {
          try {
            // Fetch all regulations and populate the dropdown
            const { allRegulations } = await fetchAllRegulations();
            populateOutcomesDropdown(allRegulations); // Populate the outcome dropdown
  
            // Populate the UOM dropdown
            populateUOMDropdown(); // Use the predefined populateUOMDropdown function
          } catch (error) {
            console.error("Error fetching all regulations or UOM options:", error);
          }
  
          // Attach event listener to show/hide the additional field section
          document.getElementById("extraFieldCheckbox").addEventListener("change", (event) => {
            const extraFieldContainer = document.getElementById("extraFieldContainer");
            extraFieldContainer.style.display = event.target.checked ? "flex" : "none";
          });
        },
      });
  
      if (value) {
        const { userInput, erule, unitOfMeasure, addAnsPrompt } = value;
  
        // Log the values for debugging
        console.log("User Input:", userInput);
        console.log("Selected Policing Outcome (erule):", erule);
        console.log("Unit of Measure:", unitOfMeasure);
        console.log("Additional Answer Prompt:", addAnsPrompt);
  
        // Add logic to send the user input, erule, and additional fields to the endpoint
        const questionID = await addQuestionToDB(userInput, erule, topicIndex, 0, unitOfMeasure, addAnsPrompt);
  
        // Update the question in the topic
        const updateQuestionData = {
          checklist_id: checklistId,
          topic_id: topic_id,
          audit_regulation_id: erule,
          audit_question_id: questionID,
          q_list_order: qorder,
        };
  
        axios
          .post(apiUrl + "/audit/add_checklist_detail", updateQuestionData)
          .then((response) => {
            updateQuestionOrderInTopic(topicIndex, topic_id, questionID);
            setTopicUpdateStatus((prevStatus) => !prevStatus);
          })
          .catch((error) => {
            console.error("Error updating database:", error);
          });
      }
    } catch (error) {
      console.error("Error displaying Swal:", error);
      Swal.fire({
        title: "Error",
        text: "Failed to display popup",
        icon: "error",
      });
    }
  };

  const addQuestionToDB = async (userInput, erule, topicIndex, checklistIndex, unitOfMeasure, addAnsPrompt) => {
    try {
      // Send the data to the endpoint to add the question
      const response = await axios.post(`${apiUrl}/audit/add_user_question`, {
        user_input: userInput,
        erule: erule,
        owner_id: user_ID,
        unit_of_measure: unitOfMeasure,
        add_ans_prompt: addAnsPrompt, // Include the new field here
      });
  
      const questionID = response.data.ID;
  
      // Call the endpoint to add the user checklist detail already called in other function
      //await axios.post(`${apiUrl}/audit/add_user_checklist_detail`, {
      //  topicIndex: topicIndex,
      //  checklistIndex: checklistIndex,
      //  AuditId: user_ID,
      //  QuestionId: questionID,
      //  erule: erule,
      //});
  
      return questionID;
    } catch (error) {
      console.error("Error adding question to DB:", error);
    }
  };
  

  const fetchAllRegulations = async () => {
    try {
      // Fetch all regulations
      const response = await fetch(`${apiUrl}/audit/get_all_regulations`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch all regulations");
      }

      const data = await response.json();

      // Extract regulations from the response data
      const allRegulations = data || [];

      return { allRegulations };
    } catch (error) {
      console.error("Error fetching all regulations:", error);
      throw error;
    }
  };

  //***************Use Effects***********************

  useEffect(() => {
    // Make a GET request to fetch checklist details
    console.log("checklist recovery started for ", checklistId);
    axios
      .get(`${apiUrl}/audit/checklist_details/${checklistId}`)
      .then((response) => {
        // Organize the checklist_details data into the desired 'topics' array structure
        const checklistDetails = response.data.checklist_details;
        const updatedTopics = [];
  
        // Loop through checklist details to organize data
        checklistDetails.forEach((detail) => {
          const topicIndex = updatedTopics.findIndex(
            (topic) => topic.id === detail.topic_id
          );
  
          if (topicIndex === -1) {
            // Create a new topic entry
            const newTopic = {
              topic_name: detail.topic_name,
              id: detail.topic_id,
              questions: [
                {
                  ID: detail.audit_question_id,
                  erule: detail.audit_regulation_id,
                  question: detail.question, // Use the question text from the response data
                },
              ],
            };
            updatedTopics.push(newTopic);
          } else {
            // Add question to an existing topic
            updatedTopics[topicIndex].questions.push({
              ID: detail.audit_question_id,
              erule: detail.audit_regulation_id,
              question: detail.question, // Use the question text from the response data
            });
          }
        });
  
        setTopics(updatedTopics);
      })
      .catch((error) => {
        console.error("Error fetching checklist details:", error);
      });
  }, [topicUpdateStatus]);

  useEffect(() => {
    fetchUserID();
  }, [user_ID]);

  useEffect(() => {
    // Transform the flat auditDetails array into a nested groupedAuditDetails array
    console.log("users questions", auditDetails);
    const newGroupedAuditDetails = auditDetails.reduce((groups, item) => {
      const group = groups.find(
        (group) =>
          group.audit_regulation_parent_id === item.audit_regulation_parent_id
      );
      if (group) {
        group.details.push(item);
        if (group.audit_regulation_parent_id === item.audit_regulation_id) {
          group.title = item.title;
        }
      } else {
        groups.push({
          title: item.title,
          audit_regulation_parent_id: item.audit_regulation_parent_id,
          details: [item],
        });
      }
      return groups;
    }, []);

    // Update the groupedAuditDetails state
    setGroupedAuditDetails(newGroupedAuditDetails);
  }, [auditDetails]);

  useEffect(() => {
    console.log("Updated Topics", topics);
  }, [topics]);

  //**********Drag and Drop**********************

  const updateQuestionOrderInTopic = (topicIndex, topicId) => {
    const targetTopic = topics[topicIndex];

    if (!targetTopic || !targetTopic.questions) {
      return;
    }

    // Create an array to hold the updated question order data
    const questionOrderData = targetTopic.questions.map(
      (question, questionIndex) => {
        // Include the question ID in the data
        return {
          topic_id: topicId, // Include the topic_id
          audit_question_id: question.ID, // Include the question ID
          q_list_order: questionIndex, // Use the current index as the new order
        };
      }
    );

    // Send a request to the backend to update the question order within the topic
    axios
      .put(`${apiUrl}/audit/reorder/questions`, questionOrderData)
      .then((response) => {
        // Handle the response if needed
        console.log(
          "Question order within topic updated successfully:",
          response.data
        );
      })
      .catch((error) => {
        // Handle errors if the request fails
        console.error("Error updating question order within topic:", error);
      });
  };

  const updateGroupOrder = async (items) => {
    const itemsToUpdate = items.map((item, index) => {
      return {
        audit_regulation_parent_id: item.audit_regulation_parent_id,
        reg_list_order: index,
        user_id: user_ID,
      };
    });
    console.log("GitemsToUpdate", itemsToUpdate);
    console.log("items", items);

    try {
      await axios.put(`${apiUrl}/audit/reorder/groups`, itemsToUpdate);
    } catch (err) {
      console.error("There was an error updating the group order!", err);
    }
  };

  const handleDragOverTopic = (e) => {
    e.preventDefault();
    // Add a class to indicate where the question can be dropped
    e.target.classList.add("drag-over");
  };

  const handleDragStart = (
    e,
    sourceTopicIndex,
    sourceQuestionIndex,
    question,
    sourceType
  ) => {
    const data = JSON.stringify({
      sourceTopicIndex,
      sourceQuestionIndex,
      question,
      sourceType,
    });

    e.dataTransfer.setData("text/plain", data);

    console.log("Drag started with data:", data);
    console.log("question", question);
  };

  const handleDrop = (e, targetTopicIndex, targetQuestionIndex) => {
    e.preventDefault();

    const data = e.dataTransfer.getData("text/plain");

    const parsedData = JSON.parse(data);
    const sourceQuestion = parsedData.question;
    const sourceQuestionID = parsedData.question.ID;
    const sourceQuestionReg = parsedData.question.erule; // Access the question directly
    const sourceType = parsedData.sourceType;
    const targetTopicID = topics[targetTopicIndex];
    const updatedTopics = [...topics];

    if (sourceType === "withinTopics") {
      // Handle reorder/move within topics
      const sourceTopicIndex = parsedData.sourceTopicIndex;
      const sourceQuestionIndex = parsedData.sourceQuestionIndex;

      const sourceTopic = updatedTopics[sourceTopicIndex];
      const targetTopic = updatedTopics[targetTopicIndex] || updatedTopics[0];

      if (sourceTopicIndex === targetTopicIndex) {
        // Handle reorder within the same topic
        const movedQuestion = sourceTopic.questions.splice(
          sourceQuestionIndex,
          1
        )[0];
        targetTopic.questions.splice(targetQuestionIndex, 0, movedQuestion);
        updateQuestionOrderInTopic(
          targetTopicIndex,
          targetTopicID.id,
          sourceQuestionID
        );
      } else {
        // Handle move between topics
        const movedQuestion = sourceTopic.questions.splice(
          sourceQuestionIndex,
          1
        )[0];
        if (!targetTopic.questions) {
          targetTopic.questions = [];
        }
        targetTopic.questions.splice(targetQuestionIndex, 0, movedQuestion);
        console.log("sourceTopic", sourceTopic.id);
        console.log("targetTopic", targetTopic.id);
        console.log("sourceQuestionID", sourceQuestionID);
        axios
          .put(`${apiUrl}/audit/move_question`, {
            sourceTopicID: sourceTopic.id,
            targetTopicID: targetTopic.id,
            sourceQuestionID: sourceQuestionID,
          })
          .then((response) => {
            updateQuestionOrderInTopic(
              targetTopicIndex,
              targetTopicID.id,
              sourceQuestionID
            );
            console.log("Question moved successfully:", response.data);
          })
          .catch((error) => {
            console.error("Error moving question:", error);
          });
      }
    } else if (sourceType === "parentChild" && sourceQuestion) {
      const targetTopic = updatedTopics[targetTopicIndex] || updatedTopics[0];
      const questionToAdd = {
        question: sourceQuestion.question,
        ID: sourceQuestion.ID, // Include the question ID
        erule: sourceQuestion.erule, // Include the question erule
      };

      if (!targetTopic.questions) {
        targetTopic.questions = [];
      }
      targetTopic.questions.splice(targetQuestionIndex, 0, questionToAdd);

      const updateQuestionData = {
        checklist_id: checklistId,
        topic_id: targetTopicID.id, // Replace with the actual topic ID
        audit_regulation_id: sourceQuestionReg, // Replace with the actual regulation ID if available, otherwise set to null
        audit_question_id: sourceQuestionID, // Replace with the actual question ID if available, otherwise set to null
        q_list_order: targetQuestionIndex, // Use the targetQuestionIndex as the q_list_order
      };

      axios
        .post(apiUrl + "/audit/add_checklist_detail", updateQuestionData)

        .then((response) => {
          // Handle reorder the questions within the topic
          // Pass the question ID as an argument
          updateQuestionOrderInTopic(
            targetTopicIndex,
            targetTopicID.id,
            sourceQuestionID
          );

          console.log("Database updated successfully:", response.data);
        })
        .catch((error) => {
          // Handle errors if the request fails
          console.error("Error updating database:", error);
        });
    } else {
      console.error("Invalid drag data");
    }

    setTopics(updatedTopics);
  };

  const handleGenerateSmartQuestion = async (questionID) => {
    const { value: result } = await Swal.mixin({
      title: "Generate a new AI question!",
      text: "Choose the level of difficulty:",
      icon: "question",
      input: "select",
      inputOptions: {
        basic: "Basic",
        intermediate: "Intermediate",
        advanced: "Advanced",
      },
      inputPlaceholder: "Select level",
      showCancelButton: true,
      confirmButtonText: "Generate",
      cancelButtonText: "Cancel",
      showLoaderOnConfirm: true,
      preConfirm: async (level) => {
        try {
          let endpoint = "";

          // Determine the appropriate endpoint based on the selected level
          switch (level) {
            case "basic":
              endpoint = `/audit/generate_basic_question/${questionID}`;
              break;
            case "intermediate":
              endpoint = `/audit/generate_intermediate_question/${questionID}`;
              break;
            case "advanced":
              endpoint = `/audit/generate_advanced_question/${questionID}`;
              break;
            default:
              // Handle an invalid level here
              Swal.fire({
                title: "Invalid Level",
                text: "Please select a valid level",
                icon: "error",
              });
              return false; // Return false to prevent confirmation
          }

          // Make an API call to the determined endpoint
          const response = await axios.get(apiUrl + endpoint);

          // Handle the response from the API and display the generated question
          if (response.status === 200) {
            Swal.fire({
              title: "Generated " + level + " Question",
              html: `<strong>${response.data.question}</strong> <br><br> <div style="background-color: #f2f2f2; padding: 10px; color: gray;">Regulation: <br>${response.data.content}</div>`,
              icon: "success",
              showCancelButton: true,
              confirmButtonText: "Keep Question",
              cancelButtonText: "Cancel",
            }).then((result) => {
              if (result.isConfirmed) {
                // Handle the "Keep Question" button click here
              }
            });
          } else {
            Swal.fire({
              title: "Error",
              text: "Failed to generate the question",
              icon: "error",
            });
          }
        } catch (error) {
          Swal.fire({
            title: "Error",
            text: "An error occurred while generating the question",
            icon: "error",
          });
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).fire();
  };

  //*************Parent Detail Component***************
  const ChildQuestion = ({
    question,
    sourceTopicIndex,
    sourceQuestionIndex,
  }) => {
    return (
      <li>
        <div
          className="flex items-center space-x-2"
          draggable
          onDragStart={(e) =>
            handleDragStart(
              e,
              sourceTopicIndex,
              sourceQuestionIndex,
              question,
              "parentChild"
            )
          } // Pass additional variables
        >
          <FontAwesomeIcon icon={faCircleQuestion} />
          <span>{question.question}</span>
        </div>
      </li>
    );
  };

  const ParentDetail = ({ parentKey, parentData, handleClick }) => {
    const parent = parentData.find(
      (item) => item.regulatory_source_parent === item.erule
    );
    const children = parentData.filter(
      (item) => item.regulatory_source_parent !== item.erule
    );

    // Return if there is no parent
    if (!parent) return null;

    return (
      <details
        open={isOpen[parentKey]}
        onClick={(event) => {
          toggleOpen(parentKey, event);
        }}
      >
        <summary>
          <div
            role="button"
            tabIndex="0"
            onClick={(e) => {
              e.stopPropagation();
              handleClick(parentKey);
            }}
          >
            {parent.title}
          </div>
        </summary>
        {/*Render parent */}
        <div>
          <h4 className="font-light text-xs text-gray-500">
            {parent.regulatory_source}
          </h4>
          <ul>
            {parent.questions.map((question, qIndex) => (
              <li key={qIndex}>
                <div
                  className="flex items-center space-x-2 m-1"
                  draggable // Make the question div draggable
                  onDragStart={(e) =>
                    handleDragStart(
                      e,
                      parentKey,
                      qIndex,
                      question,
                      "parentChild"
                    )
                  } // Pass additional variables
                >
                  <FontAwesomeIcon icon={faCircleQuestion} />
                  <span> {question.question}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>

        {/*Render children */}
        {children.map((result, index) => {
          return (
            <details
              key={index}
              open={isOpen[result.ID]}
              onClick={(event) => {
                toggleOpen(result.ID, event);
              }}
            >
              <summary>{result.title}</summary>
              <h4 className="font-light text-xs text-gray-500">
                {result.regulatory_source}
              </h4>

              <ul>
                {result.questions.map((question, qIndex) => (
                  <ChildQuestion
                    key={qIndex}
                    question={question}
                    sourceTopicIndex={index} // Pass the source topic index
                    sourceQuestionIndex={qIndex} // Pass the source question index
                  />
                ))}
              </ul>
            </details>
          );
        })}
      </details>
    );
  };

  ///*************MAIN RENDER***************

  return (
    <ThemeProvider theme={theme}>
      <div className="flex items-center mt-5 mb-10 ml-10">
        <div
          onClick={() => navigate("/flight/home")}
          className="text-primary cursor-pointer flex-shrink-0"
        >
          {theme.logoUrl ? (
            <img
              src={theme.logoUrl}
              alt="Membership Logo"
              className="h-16 w-auto"
            />
          ) : (
            <FontAwesomeIcon icon={faHomeLgAlt} size="2x" color="grey" />
          )}
        </div>

        <h1 className="text-4xl text-primary flex-grow text-center">
          Build Your <span className="text-primary">Test Card</span>
        </h1>

        <div className="w-20"> {/* Placeholder to balance the header */}</div>
      </div>

      <div className="flex w-full">
        <div className="flex flex-col w-1/2 h-screen m-5">
          <div
            className="flex flex-col w-full text-primary p-1"
            style={{ overflowY: "auto", maxHeight: "90vh" }}
          >
            <div className="flex flex-col w-full  p-8 space-y-8 mb-10 bg-white text-primary shadow-xl rounded-lg">
              <div>
                <label className="text-lg mb-2">Keywords:</label>
                <div className="flex">
                  <input
                    name="searchTerms"
                    value={searchTerms}
                    onChange={(e) => setSearchTerms(e.target.value)}
                    className="flex-grow border h-10 border-gray-300 px-3 rounded mb-4"
                    placeholder="separate keywords with commas, eg: flight, rules, weather"
                  />
                  <StyledNavLink
                    onClick={fetchSearchResults}
                    className="p-4 rounded h-10 ml-4 flex items-center justify-center"
                  >
                    Search
                  </StyledNavLink>
                </div>

                <label>
                  <span>Logic OR </span>
                  <Switch
                    onChange={setAndSearch}
                    checked={andSearch}
                    height={20}
                    width={40}
                    uncheckedIcon={false}
                    checkedIcon={false}
                  />
                  <span> AND </span>
                </label>
              </div>
            </div>

            {isLoading ? (
              <BounceLoader color={"#E0A80F"} loading={isLoading} size={60} />
            ) : noResults ? (
              <h3>No results found. Please try another search query.</h3>
            ) : (
              <ul className="space-y-2" onDragOver={(e) => e.preventDefault()}>
                {noResults && (
                  <h3>No results found. Please try another search query.</h3>
                )}
                {sortedKeys.map((parentKey, index) => {
                  // Find the parent in the results for this key

                  const parent = results[parentKey].find((item) => {
                    return item.regulatory_source_parent === item.erule;
                  });
                  console.log("item", results[parentKey]);

                  // If parent does not exist, don't render anything
                  if (!parent) {
                    return null;
                  }

                  // Prepare ParentDetail
                  const parentDetail = (
                    <ParentDetail
                      parentKey={parentKey}
                      parentData={results[parentKey]}
                      handleClick={handleToggle}
                    />
                  );

                  return (
                    <li
                      key={parentKey}
                      className={`border bg-white shadow-md p-5 rounded-lg flex flex-col cursor-pointer transition-all duration-200 ${
                        activeIndex !== index ? "hover:bg-secondary2" : ""
                      }`}
                      onClick={handleToggle.bind(
                        index,
                        results[parentKey].title,
                        results[parentKey].erule,
                        results[parentKey].source_path
                      )}
                    >
                      {/* Render the ParentDetail component */}
                      {parentDetail}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </div>

        <div className="flex flex-col w-1/2 h-screen">
          <div
            className="flex flex-col items-center justify-top h-screen text-primary p-1"
            style={{ overflowY: "auto", maxHeight: "90vh" }}
          >
            <div className="flex flex-col w-full max-w-5xl p-8 space-y-8 mb-10 bg-white text-primary shadow-xl rounded-lg">
              <div className="flex flex-row text-primary">
                <StyledNavLink
                  onClick={handleAddTopic}
                  className="flex-1 p-2 bg-primary text-white rounded text-center mx-2"
                >
                  Add Topic
                </StyledNavLink>

                {/* Render these buttons only if there are topics */}
                {topics.length > 0 && (
                  <>
                    <StyledNavLink
                      className="flex-1 p-2 bg-primary text-white rounded text-center mx-2"
                      to="/flight/home"
                    >
                      Finished
                    </StyledNavLink>
                  </>
                )}
              </div>

              <ul className="space-y-4">
                {topics.map((topic, topicIndex) => (
                  <li
                    key={topic.id}
                    className="p-4 border rounded-md shadow-md bg-white"
                    onDrop={(e) => handleDrop(e, topicIndex, -1)}
                    onDragOver={(e) => handleDragOverTopic(e, topicIndex)}
                  >
                    <div className="flex justify-between items-center">
                      <div className="flex items-center">
                        <h4 className="mb-2 text-lg font-bold">
                          {topic.topic_name}
                        </h4>
                        <button
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent parent click event from firing
                            handleEditTopic(topic.id, topic.topic_name);
                          }}
                        >
                          ..
                          <FontAwesomeIcon
                            icon={faPen}
                            style={{ fontSize: "0.6em", color: "grey" }}
                          />
                        </button>
                      </div>
                      <button
                        className="mr-2 text-red-400"
                        onClick={() => handleDeleteTopic(topic.id, checklistId)}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </button>
                    </div>

                    <ul>
                      {topic.questions &&
                        topic.questions.map((question, questionIndex) => (
                          <StyledListItem
                            key={question.ID}
                            draggable="true"
                            onDragStart={(e) =>
                              handleDragStart(
                                e,
                                topicIndex,
                                questionIndex,
                                question,
                                "withinTopics"
                              )
                            }
                            onDragOver={(e) => e.preventDefault()}
                          >
                            <StyledListItemContent>
                              {question.question}
                            </StyledListItemContent>
                            <div className="flex justify-end items-center">
                            <button
                                className="mr-2 text-secondary"
                                onClick={() => handleEditQuestion(question.ID, question.question)}
                              >
                                <FontAwesomeIcon
                                  icon={faPen}
                                  style={{ fontSize: "0.8em", color: "grey" }}
                                />
                              </button>

                              <button
                                className="mr-2 text-red-400"
                                onClick={() =>
                                  handleDeleteQuestion(
                                    topic.id,
                                    question.ID,
                                    checklistId
                                  )
                                }
                              >
                                <FontAwesomeIcon icon={faTimes} />
                              </button>
                            </div>
                          </StyledListItem>
                        ))}
                      <div className="flex justify-center items-center">
                        <button
                          className="p-2 mt-2"
                          style={{ fontSize: "20px" }} // Make the icon bigger
                          onClick={(e) => handleAddQuestionClick(topicIndex, topic.id, 0)}
                        >
                          <FontAwesomeIcon icon={faSquarePlus} size="2xl" />
                        </button>
                      </div>
                    </ul>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default AuditChecklistComponent;
