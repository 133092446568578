import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  GlobalStyle,
  YearViewContainer3,
  YearViewContainer4,
  MonthSquare,
  MonthTitle,
  EventList,
  EventItem,
} from "./styledComponents";
import { useNavigate } from "react-router-dom";
import { Global } from "@emotion/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHomeLgAlt,
  faCircleHalfStroke,
  faCircleCheck,
  faClock,
  faTriangleExclamation,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";

function AuditCalendar({ programId }) {
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear()); // Current year by default
  const [numberOfYears, setNumberOfYears] = useState(1); // Default number of years
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  const fetchAuditProgramDetails = async () => {
    try {
      // Fetch program details including start and end dates
      const programResponse = await axios.get(
        `${apiUrl}/audit/program/${programId}`
      );
      const programDetails = programResponse.data;

      // Extract start and end dates from program details
      const startDate = new Date(programDetails.start_date);
      const endDate = new Date(programDetails.end_date);

      // Calculate the number of years between the start and end dates
      const calculatedNumberOfYears =
        endDate.getFullYear() - startDate.getFullYear() + 1;
      setNumberOfYears(calculatedNumberOfYears);

      // Fetch audit details
      const auditsResponse = await axios.get(
        `${apiUrl}/audit/program_details/${programId}`
      );
      const audits = auditsResponse.data.audits;
      console.log("Audit program details:", auditsResponse.data);
      const events = audits.map((audit) => ({
        id: audit.audit_id,
        calendarId: "cal1", // You might need to adjust this based on your calendar setup
        title: audit.audit_name,
        category: "time",
        start: audit.date_scheduled,
        end: audit.date_scheduled,
        status: audit.status,
      }));

      setCalendarEvents(events);
      console.log("Calendar Events:", events);
    } catch (error) {
      console.error(
        "There was an error fetching the audit program details:",
        error
      );
    }
  };

  useEffect(() => {
    fetchAuditProgramDetails();
  }, [programId]);

  const handleDragStart = (e, event) => {
    e.dataTransfer.setData(
      "application/json",
      JSON.stringify({ eventId: event.id })
    );
  };

  const getEventIcon = (status, startDate) => {
    const today = new Date();
    const scheduledDate = new Date(startDate);
    console.log("Start Date:", startDate);
  
    if (status === "Closed") {
      return <FontAwesomeIcon icon={faCircleCheck} style={{ color: "green" }} />;
    } else if (status === "Complete") {
      return <FontAwesomeIcon icon={faCircle} style={{ color: "green" }} />;
    } else if (status === "In Progress") {
        return <FontAwesomeIcon icon={faCircleHalfStroke} style={{ color: "green" }} />;
    } else if (status === "Scheduled" && scheduledDate > today) {
        return <FontAwesomeIcon icon={faCircle} style={{ color: "grey" }} />;
    } else if (status === "Scheduled" && scheduledDate < today) {
        return <FontAwesomeIcon icon={faClock} style={{ color: "orange" }} />;
    }
    return null; // Default case
  };
  
      

  const handleDrop = async (e, year, month) => {
    e.preventDefault();

    try {
      // Retrieve the event ID from the drop event data
      const eventData = JSON.parse(e.dataTransfer.getData("application/json"));
      const { eventId } = eventData;

      // Fetch the event from calendarEvents using eventId
      const eventToUpdate = calendarEvents.find(
        (event) => event.id === eventId
      );

      // Calculate the new date based on the dropped month
      const newDate = new Date(
        year,
        month,
        new Date(eventToUpdate.start).getDate()
      );

      // Update the event date on the server
      await axios.put(`${apiUrl}/audit/update_event_date/${eventId}`, {
        new_date: newDate.toISOString(),
      });

      // Update the date_scheduled of the event
      eventToUpdate.start = newDate;
      eventToUpdate.end = newDate;

      // Update the state with the modified event
      setCalendarEvents((prevEvents) =>
        prevEvents.map((existingEvent) =>
          existingEvent.id === eventId ? eventToUpdate : existingEvent
        )
      );

      console.log("Event date updated successfully.");
    } catch (error) {
      console.error("Error updating event date:", error);
    }
  };

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleMonthClick = (year, month) => {
    // Navigate to the month detail page and pass calendarEvents data as a prop
    console.log("Year:", year, "Month:", month, "Program ID:", programId);
    navigate(`/flight/month-detail/${year}/${month}/${programId}`, {
      state: { calendarEvents, programId },
    });
  };

  const renderMonths = () => {
    const monthsToShow = [];
    for (let i = 0; i < months.length; i++) {
      for (let j = 0; j < numberOfYears; j++) {
        monthsToShow.push({
          year: year + j,
          month: i,
        });
      }
    }

    return (
      <div>
        {numberOfYears === 2 ? (
          <YearViewContainer4>
            {monthsToShow.map((monthData, index) => (
              <div
                key={index}
                onClick={() =>
                  handleMonthClick(monthData.year, monthData.month)
                }
                onDrop={(e) => handleDrop(e, monthData.year, monthData.month)}
                onDragOver={(e) => e.preventDefault()}
              >
                <MonthSquare>
                  <MonthTitle>{`${months[monthData.month]} ${
                    monthData.year
                  }`}</MonthTitle>
                  <EventList>
                    {calendarEvents
                      .filter(
                        (event) =>
                          new Date(event.start).getMonth() ===
                            monthData.month &&
                          new Date(event.start).getFullYear() === monthData.year
                      )
                      .map((event) => (
                        <div
                          key={event.id}
                          draggable
                          onDragStart={(e) => handleDragStart(e, event)}
                        >
                          <EventItem>
                          {getEventIcon(event.status, event.start)}{" "}
                            {event.title} -{" "}
                            {new Date(event.start).toLocaleDateString()}
                          </EventItem>
                        </div>
                      ))}
                  </EventList>
                </MonthSquare>
              </div>
            ))}
          </YearViewContainer4>
        ) : (
          <YearViewContainer3>
            {monthsToShow.map((monthData, index) => (
              <div
                key={index}
                onClick={() =>
                  handleMonthClick(monthData.year, monthData.month)
                }
                onDrop={(e) => handleDrop(e, monthData.year, monthData.month)}
                onDragOver={(e) => e.preventDefault()}
              >
                <MonthSquare>
                  <MonthTitle>{`${months[monthData.month]} ${
                    monthData.year
                  }`}</MonthTitle>
                  <EventList>
                    {calendarEvents
                      .filter(
                        (event) =>
                          new Date(event.start).getMonth() ===
                            monthData.month &&
                          new Date(event.start).getFullYear() === monthData.year
                      )
                      .map((event) => (
                        <div
                          key={event.id}
                          draggable
                          onDragStart={(e) => handleDragStart(e, event)}
                        >
                          <EventItem>
                            {getEventIcon(event.status, event.start)}{" "}
                            {/* Render the appropriate icon */}
                            {event.title} -{" "}
                            {new Date(event.start).toLocaleDateString()}
                          </EventItem>
                        </div>
                      ))}
                  </EventList>
                </MonthSquare>
              </div>
            ))}
          </YearViewContainer3>
        )}
      </div>
    );
  };

  return (
    <>
      <GlobalStyle />
      <div className="flex flex-col items-center w-full mt-5">
        {renderMonths()}
      </div>
    </>
  );
}

export default AuditCalendar;