import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeContext, defaultTheme } from "./theme.js";
import LoginForm from "../pages/LoginForm.js";
import { loginService } from "../context/loginService.js";
import axios from "axios";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { setTheme } = useContext(ThemeContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    // Clear the cached theme from local storage and reset to default on component mount
    localStorage.removeItem("theme");
    localStorage.removeItem("MToken");
    setTheme(defaultTheme);
  }, [setTheme]);

  const login = async () => {
    try {
      const response = await loginService(username, password);
      localStorage.setItem("access_token", response.access_token);

      // Check if the user is active.
      const activeStatusResponse = await axios.get(
        apiUrl + "/check_active_status",
        {
          headers: { Authorization: `Bearer ${response.access_token}` },
        }
      );

      // if user is active navigate to home else navigate to /inactive
      if (activeStatusResponse.data.is_active) {
        navigate("/home");
      } else {
        navigate("/inactive");
      }
    } catch (error) {
      console.log("There was an error with the login!", error.response);
      if (error.response && error.response.status === 401) {
        setIsModalOpen(true);
      } else {
        navigate("/login");
      }
    }
  };

  return (
    <LoginForm
      username={username}
      setUsername={setUsername}
      password={password}
      setPassword={setPassword}
      login={login}
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
    />
  );
};

export default Login;