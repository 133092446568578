import superAdminProtection from "../hoc/superAdminProtection";
import "../components/styles.css";

import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
const apiUrl = process.env.REACT_APP_API_URL;

const CreateOrganisation = (props) => {
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [primaryColour, setPrimaryColour] = useState("#444651");
  const [accentColour, setAccentColour] = useState("#E0A80F");
  const [primaryTextAndIconColour, setPrimaryTextAndIconColour] =
    useState("#000000");
  const [secondaryTextAndIconColour, setSecondaryTextAndIconColour] =
    useState("#000000");
  //Organisation types
  const [type_camo, setType_camo] = useState(false);
  const [type_mro, setType_mro] = useState(false);
  const [type_design, setType_design] = useState(false);
  const [type_aoc_charter, setType_aoc_charter] = useState(false);
  const [type_aoc_scheduled, setType_aoc_scheduled] = useState(false);
  const [type_ncc, setType_ncc] = useState(false);
  const [type_fbo, setType_fbo] = useState(false);
  const [type_ground_services, setType_ground_services] = useState(false);

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleClose = () => {
    setOpen(false);
    navigate("/admin"); // add this line
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    register();
  };

  // Function to close error dialog
  const handleErrorClose = () => {
    setOpenErrorDialog(false);
  };

  const register = () => {
    axios({
      method: "post",
      url: apiUrl + "/organisations",
      data: JSON.stringify({
        name: name,
        address: address,
        is_active: isActive,
        primary_colour: primaryColour,
        accent_colour: accentColour,
        primary_text_and_icon_colour: primaryTextAndIconColour,
        secondary_text_and_icon_colour: secondaryTextAndIconColour,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        console.log(response);
        //navigate("/admin");
        setOpen(true); // Open the modal here.
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setErrorMessage(error.message); // Save the error message to a state.
        setOpenErrorDialog(true); // Open the modal here.
      });
  };
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-secondary2 text-gray-800">
      <h2 className="text-4xl text-center mb-10">Create New Force</h2>
      <div className="flex flex-col w-full max-w-md p-8 space-y-8 bg-white shadow-xl rounded-lg">
        <div>
          <label className="text-lg mb-2">Name:</label>
          <input
            type="text"
            value={name}
            onChange={e => setName(e.target.value)}
            name="name"
            className="w-full border h-10 border-gray-300 px-3 rounded"
            placeholder="Name"
          />
        </div>
        <div>
          <label className="text-lg mb-2">County:</label>
          <input
            type="text"
            value={address}
            onChange={e => setAddress(e.target.value)}
            name="address"
            className="w-full border h-10 border-gray-300 px-3 rounded"
            placeholder="Address"
          />
        </div>
        <div>
          <label className="text-lg mb-2">Primary Colour:</label>
          <input
            type="color"
            value={primaryColour}
            onChange={e => setPrimaryColour(e.target.value)}
            name="primary_colour"
            className="w-full border h-10 border-gray-300 px-3 rounded"
            placeholder="Primary Colour"
          />

          <label className="text-lg mb-2">Accent Colour:</label>
          <input
            type="color"
            value={accentColour}
            onChange={e => setAccentColour(e.target.value)}
            name="accent_colour"
            className="w-full border h-10 border-gray-300 px-3 rounded"
            placeholder="Accent Colour"
          />

          <label className="text-lg mb-2">Primary Text and Icon Colour:</label>
          <input
            type="color"
            value={primaryTextAndIconColour}
            onChange={e => setPrimaryTextAndIconColour(e.target.value)}
            name="primary_text_and_icon_colour"
            className="w-full border h-10 border-gray-300 px-3 rounded"
            placeholder="Primary Text and Icon Colour"
          />

          <label className="text-lg mb-2">
            Secondary Text and Icon Colour:
          </label>
          <input
            type="color"
            value={secondaryTextAndIconColour}
            onChange={e => setSecondaryTextAndIconColour(e.target.value)}
            name="secondary_text_and_icon_colour"
            className="w-full border h-10 border-gray-300 px-3 rounded"
            placeholder="Secondary Text and Icon Colour"
          />
        </div>
        <div>

          <div className="mt-4">
            <button
              onClick={handleFormSubmit}
              className="bg-primary hover:bg-secondary text-white font-bold py-2 px-4 rounded block mx-auto"
            >
              Create Organisation
            </button>
          </div>
        </div>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
        >
          <DialogTitle id="alert-dialog-title">{`Organisation ${name} Created`}</DialogTitle>
          <div className="flex justify-end pa-4">
            <Button onClick={handleClose} color="primary" autoFocus>
              Close
            </Button>
          </div>
        </Dialog>
        <Dialog
          open={openErrorDialog}
          onClose={handleErrorClose}
          aria-labelledby="alert-dialog-title"
        >
          <DialogTitle id="alert-dialog-title">{`Error  ${errorMessage}`}</DialogTitle>
          <div className="flex justify-end pa-4">
            <Button onClick={handleErrorClose} color="primary" autoFocus>
              Close
            </Button>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default superAdminProtection(CreateOrganisation);