import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;
console.log(apiUrl);
export const loginService = async (username, password) => {
  const data = new URLSearchParams();
  data.append("username", username);
  data.append("password", password);

  const response = await axios({
    method: "post",
    url: apiUrl + "/login",
    data: data.toString(),
    headers: { "content-type": "application/x-www-form-urlencoded" },
  });

  return response.data;
};