import React from 'react';
import { useNavigate } from 'react-router-dom';

function Inactive() {
  let navigate = useNavigate();
  return (
    <div className="flex flex-col items-center justify-center h-screen text-center bg-white px-4">
    <h2 className="text-primary text-2xl mb-6">Your account has not been activated</h2>
    <p className="text-gray-500">Please check your email and follow the link to activate it.</p>

    <button className="bg-primary hover:bg-secondary text-white font-bold py-3 px-6 rounded, mt-10" 
            onClick={() => navigate('/login')}>
      Go back to Login
    </button>
</div>
  );
}

export default Inactive;