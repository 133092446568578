import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const apiUrl = process.env.REACT_APP_API_URL;

const useFetchOrgId = () => {
  const [orgId, setOrgId] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrgId = async () => {
      const mToken = localStorage.getItem("MToken");
      if (!mToken) {
        navigate("/maint/home"); // Redirect to home if no MToken
        return;
      }

      try {
        const response = await axios.post(`${apiUrl}/decode-membership-token/${mToken}`);
        const membershipData = response.data;

        if (membershipData && membershipData.data && membershipData.data.membership_data) {
          const orgId = membershipData.data.membership_data.org_id;
          setOrgId(orgId); // Set the organization ID
        }
      } catch (error) {
        console.error("Error decoding membership token", error);
        setError("Error decoding token");
        navigate("/maint/home"); // Redirect if error in token decoding
      }
    };

    fetchOrgId();
  }, [navigate]);

  return { orgId, error };
};

export default useFetchOrgId;
