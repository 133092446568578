// LoginForm.js
import React from 'react';
import { Link } from 'react-router-dom';
import ReactModal from 'react-modal';
import Logo from '../assets/login_logo.png';
import '../components/styles.css';

const LoginForm = ({
  username,
  setUsername,
  password,
  setPassword,
  login,
  isModalOpen,
  setIsModalOpen,
}) => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <form
        onSubmit={(event) => {
          event.preventDefault();
          login();
        }}
        className="bg-white p-8 rounded shadow-md w-full max-w-sm"
      >
        <div className="flex justify-center mb-8">
          <img src={Logo} alt="Logo" className="logo-image-size" />
        </div>

        <div className="flex flex-col">
          <div className="flex border-2 rounded px-4 py-2 mb-4 items-center">
            <input
              className="flex-1 outline-none text-center"
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <span>
              <i className="fas fa-user text-gray-400"></i>
            </span>
          </div>

          <div className="flex border-2 rounded px-4 py-2 mb-4 items-center">
            <input
              className="flex-1 outline-none text-center"
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <span>
              <i className="fas fa-key text-gray-400"></i>
            </span>
          </div>

          <div className="flex justify-center mt-6">
            <button
              type="submit"
              className="bg-primary hover:bg-secondary text-white font-bold py-2 px-16 rounded"
            >
              Login
            </button>
          </div>

         
        </div>
      </form>

      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '2rem',
          },
        }}
        contentLabel="Access Denied Modal"
      >
        <div className="flex flex-col items-center bg-white text-gray-400 rounded">
          <h2 className="text-2xl mb-4 text-center">Login Failed</h2>
          <p className="text-center">Username or password incorrect</p>
        </div>
      </ReactModal>
    </div>
  );
};

export default LoginForm;