import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useParams, useLocation } from 'react-router-dom';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import { ThemeContext } from "../components/theme.js";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHomeLgAlt, faCircleHalfStroke, faCircleCheck, faClock, faTriangleExclamation, faCircle  } from '@fortawesome/free-solid-svg-icons';

const localizer = momentLocalizer(moment);
const DraggableCalendar = withDragAndDrop(Calendar);
const apiUrl = process.env.REACT_APP_API_URL;

function MonthDetailPage() {
  const { year, month, programId  } = useParams();
  const { state } = useLocation();
  const [calendarEvents, setCalendarEvents] = useState(state.calendarEvents || []);
  
  const [auditDetails, setAuditDetails] = useState([]);
  const navigate = useNavigate();
  const { theme, setTheme } = useContext(ThemeContext);


  useEffect(() => {
    if (calendarEvents.length > 0) {
        try {
            
            navigate(`/flight/month-detail/${year}/${month}/${programId}`, { state: { calendarEvents, programId } });
        } catch (error) {
            console.error('Error fetching audit details:', error);
        }
    }
}, [calendarEvents, year, month]);


  const events = auditDetails.map(audit => ({
    id: audit.id,
    title: audit.audit_name,
    start: new Date(audit.date_scheduled),
    end: new Date(audit.date_scheduled),
  }));

  const getMonthName = (monthNumber) => {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    return months[monthNumber - 1];
  };


  const onEventDrop = async ({ event, start }) => {
    try {
        // Update the event date on the server
        await axios.put(`${apiUrl}/audit/update_event_date/${event.id}`, {
            new_date: start.toISOString() // Assuming start is the new date
        });

        // Update the date_scheduled of the event
        event.start = start
        event.end = start
        console.log('Event:', event);
        // Update the state with the modified event
        setCalendarEvents(prevEvents =>
            prevEvents.map(existingEvent =>
                existingEvent.id === event.id ? event : existingEvent
            )
        );

        console.log('Event date updated successfully.');
    } catch (error) {
        console.error('Error updating event date:', error);
    }
};

  // Custom style for Day component to make it bigger
  const dayStyle = (isWeekend) => ({
    height: '150px',
    border: '1px solid #ddd',
    backgroundColor: isWeekend ? '#f7f7f7' : 'white', // Change background color for weekends
    position: 'relative',
  });

  // Custom Day component to render larger cells for each day
  const CustomDay = ({ children, date }) => {
    const isWeekend = date.getDay() === 0 || date.getDay() === 6; // Check if it's a weekend
    return (
      <div style={dayStyle(isWeekend)}>
        {children}
      </div>
    );
  };

  return (
    <div>
         <div className="flex items-center justify-between mt-5 mb-10">
            <div
       onClick={() => navigate(`/flight/test_schedule/${state.programId}`)}

        >
        {theme.logoUrl ? (
        <img
        src={theme.logoUrl}
        alt="Membership Logo"
        className="h-16 w-auto"
        />
        ) : (
        <FontAwesomeIcon icon={faHomeLgAlt} size="2x" color="grey" />
        )}
        </div>
        </div>
       
       
    
    
    <div style={{ height: 1000 }}>
     
    <DraggableCalendar
  localizer={localizer}
  events={[...calendarEvents, ...events]}
  startAccessor="start"
  endAccessor="end"
  style={{ height: '100%' }}
  views={['month', 'agenda']}
  onEventDrop={onEventDrop}
  resizable
  selectable
  defaultView={Views.MONTH}
  defaultDate={new Date(year, month, 1)} // Set the default start date to the 1st day of the selected month
  popup
  components={{ day: CustomDay }} // Use CustomDay component for rendering days
/>

    </div>
    </div>
   
  );
}

export default MonthDetailPage;