import React from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import axios from 'axios';

function FormBuilder() {
    const { register, handleSubmit, control, watch } = useForm({
        defaultValues: {
            formName: '',
            questions: [{ id: 1, text: '', type: 'text', options: [{ text: '', next: null }], next: null }]
        }
    });
    const { fields, append, update } = useFieldArray({
        control,
        name: 'questions'
    });

    const onSubmit = async (data) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/create_form`, data);
            alert('Form created successfully!');
        } catch (error) {
            console.error('There was an error creating the form!', error);
        }
    };

    const addQuestion = () => {
        append({ id: fields.length + 1, text: '', type: 'text', options: [{ text: '', next: null }], next: null });
    };

    const addOption = (index) => {
        const newOptions = [...fields[index].options, { text: '', next: null }];
        update(index, { ...fields[index], options: newOptions });
    };

    const questionType = watch('questions');

    return (
        <div className="max-w-2xl mx-auto p-4 bodyBackgrond">
            <h1 className="text-2xl font-bold mb-4 primary-text-color">Create a Risk Assessment</h1>
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
                <div>
                    <label className="block text-sm font-medium text-gray-700">Risk Assessment Name:</label>
                    <input
                        type="text"
                        {...register('formName')}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                </div>
                {fields.map((question, index) => (
                    <div key={question.id} className="border p-4 rounded-md shadow-sm element-modern">
                        <label className="block text-sm font-medium text-gray-700">Question {index + 1}:</label>
                        <input
                            type="text"
                            {...register(`questions.${index}.text`)}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                        <div className="mt-2">
                            <label className="block text-sm font-medium text-gray-700">Type:</label>
                            <select
                                {...register(`questions.${index}.type`)}
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            >
                                <option value="text">Free Text</option>
                                <option value="multiple_choice">Multiple Choice</option>
                            </select>
                        </div>
                        {questionType[index]?.type === 'multiple_choice' && (
                            <div className="mt-2 space-y-2">
                                {question.options.map((option, optionIndex) => (
                                    <div key={optionIndex} className="flex items-center space-x-2">
                                        <input
                                            type="text"
                                            {...register(`questions.${index}.options.${optionIndex}.text`)}
                                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        />
                                        <select
                                            {...register(`questions.${index}.options.${optionIndex}.next`)}
                                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        >
                                            <option value="">None</option>
                                            {fields.map((q, i) => (
                                                <option key={q.id} value={q.id}>
                                                    Question {i + 1}
                                                </option>
                                            ))}
                                            <option value="end">End</option>
                                        </select>
                                    </div>
                                ))}
                                <button
                                    type="button"
                                    onClick={() => addOption(index)}
                                    className="mt-2 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white primary-color hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                    Add Option
                                </button>
                            </div>
                        )}
                        {questionType[index]?.type !== 'multiple_choice' && (
                            <div className="mt-2">
                                <label className="block text-sm font-medium text-gray-700">Next Question:</label>
                                <select
                                    {...register(`questions.${index}.next`)}
                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                >
                                    <option value="">None</option>
                                    {fields.map((q, i) => (
                                        <option key={q.id} value={q.id}>
                                            Question {i + 1}
                                        </option>
                                    ))}
                                    <option value="end">End</option>
                                </select>
                            </div>
                        )}
                    </div>
                ))}
                <button
                    type="button"
                    onClick={addQuestion}
                    className="mt-4 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white primary-color hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Add Question
                </button>
                <button
                    type="submit"
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white primary-color hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Create Form
                </button>
            </form>
        </div>
    );
}

export default FormBuilder;