import React, { createContext, useState, useEffect } from 'react';
import Logo from "../assets/logo.png";

// Define your default theme outside of the ThemeProvider
export const defaultTheme = {
    primary_colour: '#1f305e',
    accent_colour: '#d3d3d3', 
    primary_text_and_icon_colour: '#C0C0C0',
    secondary_text_and_icon_colour: '#718096',
    logoUrl: Logo
};


export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
    // Check for a theme in local storage
    const localTheme = JSON.parse(window.localStorage.getItem("theme"));
    
    // Initialize the theme state with the local theme or the default theme
    // Make sure to include the logoUrl in the state
    const [theme, setTheme] = useState({
        ...defaultTheme,
        ...localTheme,
        logoUrl: localTheme?.logoUrl || defaultTheme.logoUrl
    });

    // Update local storage when the theme changes
    useEffect(() => {
        window.localStorage.setItem("theme", JSON.stringify(theme));
    }, [theme]);

    // Provide theme and setTheme as values
    return (
        <ThemeContext.Provider value={{ theme, setTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};
