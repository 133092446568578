import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const apiUrl = process.env.REACT_APP_API_URL;

export default function CreateMembership() {
  const [profiles, setProfiles] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState("");
  const [organisations, setOrganisations] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState("");
  const [orgFunctions, setOrgFunctions] = useState([]);
  const [allFunctions, setAllFunctions] = useState([]);
  const [selectedFunction, setSelectedFunction] = useState(null);
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      const profileRes = await axios.get(apiUrl + "/profiles");
      setProfiles(profileRes.data);

      const orgRes = await axios.get(apiUrl + "/all_organisations");
      console.log("Organisations:", orgRes.data);
      setOrganisations(orgRes.data);
    }

    fetchData();
  }, []);

  const fetchOrgFunctions = async (orgId) => {
    const res = await axios.get(`${apiUrl}/organisation_functions_all_data/${orgId}`);
    setOrgFunctions(res.data);
    console.log('Org Functions: ', orgFunctions);
};


  const fetchRoles = async () => {
    const res = await axios.get(`${apiUrl}/all_roles`);
    setRoles(res.data);
  };

  useEffect(() => {
    if (selectedOrg) {
      fetchOrgFunctions(selectedOrg);
      fetchRoles();
    }
  }, [selectedOrg]);
  
  const handleSubmit = async () => {
    console.log('Selected profile: ', typeof selectedProfile, selectedProfile);
    console.log('Selected org: ', typeof selectedOrg, selectedOrg);
    console.log('Selected function: ', typeof selectedFunction, selectedFunction);
    console.log('Selected role: ', typeof selectedRole, selectedRole);
   
    // Make sure all required fields are selected
    if (!selectedProfile || !selectedOrg || !selectedFunction || !selectedRole) {
      alert("Please fill out all fields before submitting!");
      return;  // Stop here if not all fields are filled
    }
    try {
        const response = await axios.post(`${apiUrl}/memberships`, {
            global_id: selectedProfile,
            org_id: selectedOrg,
            org_function_id: selectedFunction,
            role_id: selectedRole,
            is_active: true,
          });
    navigate("/admin")
  } catch (error) {
    console.error(error.response.data) // log the error's details
  }
};



  return (
    <div className="flex flex-col items-center justify-center h-screen bg-secondary2 text-primary">
      <h2 className="text-4xl text-center mb-10">Add New Membership</h2>
      <div className="flex flex-col w-full max-w-md p-8 space-y-8 bg-white text-primary shadow-xl rounded-lg">
        <div>
          <label className="text-lg mb-2">User Profile:</label>
          <select
            onChange={(e) => setSelectedProfile(e.target.value)}
            className="w-full border h-10 border-gray-300 px-3 rounded"
          >
            <option value="">Select a user profile...</option>
            {profiles.map((profile) => (
              <option key={profile.id} value={profile.id}>
                  {`${profile.first_name} ${profile.last_name}`}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label className="text-lg mb-2">Force:</label>
          <select 
  onChange={(e) => setSelectedOrg(e.target.value)}
  className="w-full border h-10 border-gray-300 px-3 rounded"
>
  <option value="">Select a Force...</option>
  {organisations.map((org) => (
    <option key={org.id} value={org.id}>
      {org.name}
    </option>
  ))}
</select>
        </div>

        <div>
  <label className="text-lg mb-2">Departments:</label>
  <select 
    onChange={(e) => setSelectedFunction(e.target.value)}
    className="w-full border h-10 border-gray-300 px-3 rounded"
  >
    <option value="">Select a department...</option>
    
    {orgFunctions.map((func) => {
  console.log(`Function id: ${func.id}`);  // Log func.id

  return (
    <option key={func.id} value={func.id}>
      {func.alias || `No alias for function with id: ${func.id}`}
    </option>
  );
})}

  </select>

</div>


        <div>
          <label className="text-lg mb-2">Role:</label>
          <select
            onChange={(e) => setSelectedRole(e.target.value)}
            className="w-full border h-10 border-gray-300 px-3 rounded"
            value={selectedRole}
          >
            <option value="">Select a role...</option>
            {roles.map((role) => (
              <option key={role.id} value={role.id}>
                {role.name}
              </option>
            ))}
          </select>
        </div>

        <div className="flex justify-center">
          <button
            className="bg-primary text-white p-2 rounded mt-4"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}