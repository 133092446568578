import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Carousel } from 'react-responsive-carousel';
import { createGlobalStyle } from 'styled-components';



export const BottomContainer = styled.div`
  position: fixed; // Fixed position
  bottom: 0; // At the bottom of the page
  left: 0;
  width: 100vw; // Full width
  padding: 20px; // Add some padding
  box-sizing: border-box; // Include padding in width calculation
  z-index: 1000; // Ensure it's above other elements

`;

export const AddButton = styled.button`
  position: absolute;
  top: 10px; // Adjust the position as needed
  right: 10px;
  background-color: transparent;
  border: none;
  color: ${props => props.theme.primary_text_and_icon_colour};
  cursor: pointer;
  z-index: 10; // Ensure it's above other elements

 
  &:disabled {
    color: #ccc; // Example color for disabled state
    cursor: not-allowed;
  }
`;

export const NoChecklistsMessage = styled.div`
  text-align: center;
  padding: 20px;
  color: ${props => props.theme.primary_text_and_icon_colour};
  font-size: 20px; /* Adjust font size as needed */
  // Add any other styles you want for this message
`;



export const StyledContainer = styled.div`
  background-color: ${props => props.theme.primary};
  color: ${props => props.theme.secondary};
`;

export const Logo = styled.img.attrs(props => ({
    src: props.theme.logo,
  }))``;

  export const StyledButton = styled.button`
  font-weight: 700;
  padding: 12px 24px;
  border-radius: 999px;
  margin-top: 16px;
  color: white;
  ${(props) =>
    props.primary &&
    css`
    background: ${props.theme.primary_colour};
    color: ${props.theme.primary_text_and_icon_colour};
    &:hover {
        background: ${props.theme.accent_colour};

      }
  `}
`;

export const StyledNavLink = styled(NavLink)`
  && {
    background-color: ${props => props.theme.primary_colour};
    color: ${props => props.theme.primary_text_and_icon_colour};

    &:hover {
      background-color: ${props => props.theme.accent_colour};
    }

    ${props => props.disabled && css`
      background-color: #ccc; // Example gray color for disabled state
      color: #666; // Example dimmed text color for disabled state
      pointer-events: none; // Disables click events
      cursor: default;
    `}
  }
`;

export const jobCardTextArea = styled.textarea`
  min-height: 100px; // Increased minimum height
  width: 50%; // Wider
  height: 80px; // Increased height
  overflow: hidden;
  resize: none; // Disable resize
  border: 1px solid #ccc; // Border style
  padding: 10px; // Padding inside the textarea
  border-radius: 5px; // Rounded corners
  margin: 0 12px; // Margin for spacing

  &:focus {
    outline: none; // Removes default outline
    border-color: #007bff; // Changes border color on focus
  }
`;

export const AutoResizeTextarea = styled.textarea`
  min-height: 20px; // Minimum height
  width: 40%; // Full width
  height: 40px; // Full height
  overflow: hidden;
  resize: none; // Remove the default resize handle
  border: 1px solid #ccc; // Example border styling
  padding: 10px; // Padding inside the textarea
  border-radius: 5px; // Rounded corners
  margin: 0 12px; // Margin for spacing

  &:focus {
    outline: none; // Removes the default focus outline
    border-color: #007bff; // Changes border color on focus, for example
  }
`;


export const CenteredTile = styled.div`
  max-width: 80vw;
  height: 90vh;
  margin: auto;
  padding: 20px;
  display: flex;    /* Keeps using flex layout */
  flex-direction: column;
  align-items: start; /* Aligns items to the start (left) */
  border-radius: 1rem;
  margin-top: 2rem;
  box-shadow: 0px 4px 10px ${props => props.theme.primary};
  overflow-y: auto;
`;

export const AuditHeader = styled.div`
  width: 100%;  /* Ensure it takes full width */
  text-align: left;
  margin-bottom: 20px;
  position: relative; 
  /* Other styles */
`;

export const Mask = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8); // Adjust the transparency as needed
  z-index: 10;
`;

export const AuditDetails = styled.div`
  display: flex;
  flex-direction: column; /* Arrange items vertically */
  gap: 20px; /* Space between items */
  background-color: #ffffff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  p {
    margin: 0;
    line-height: 1.6;
  }

  strong {
    font-weight: 600;
  }
`;



export const ChecklistsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
  grid-gap: 20px;
  width: 100%; /* Ensures the grid takes full width */
`;



export const StyledDiv = styled.div`
  padding: 2rem; // Adjust padding as needed
  border-radius: 0.5rem; // Adjust border-radius as needed
  background-color: white;
  color: ${props => props.selected ? props.theme.primary_text_and_icon_colour : 'inherit'};
  display: flex;
  flex-direction: column;
  justify-content: center; // This will center the content vertically
  align-items: flex-start; // This will align the content to the left
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); // Adjust the shadow as needed
  cursor: pointer;
  border: ${props => props.selected ? `2px solid ${props.theme.primary_colour}` : '1px solid #ddd'}; // Adjust border as needed
  transition: all 0.3s ease; // Smooth transition for hover effect

  &:hover {
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2);
  }
`;

export const StyledListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ccc;
  padding: 8px;
  margin: 8px 0;
  background-color: #fff;
`;

export const StyledListItemLiveAudit = styled.li`

  border: 1px solid #ccc;
  padding: 8px;
  margin: 8px 0;
  background-color: #fff;
`;

export const StyledListItemContent = styled.div`
  flex-grow: 1;
`;

export const StyledListItemDeleteButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #ff0000;
  font-size: 1.2rem;
  margin-left: 8px;
`;

export const StyledListItemDeleteButtonHover = styled(StyledListItemDeleteButton)`
  color: #f00; /* Change color on hover */
`;

export const CalendarContainer = styled.div`
  max-width: 95vw;  /* 90% of the viewport width */
  height: 80vh;     /* 80% of the viewport height */
  margin: auto;
  padding: 20px;
  background-color: ${props => props.theme.primary_colour}
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  .tui-full-calendar-month {
    /* Styles for the month view */
  }

  .tui-full-calendar-weekday {
    /* Styles for weekdays */
  }

  .tui-full-calendar-weekday-schedule {
    /* Styles for schedules */
  }

  /* Add more custom styles or overrides here */
`;
export const CalendarNavigation = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  button {
    padding: 5px 10px;
    margin: 0 5px;
    background-color: #ccc;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background-color: #999;
    }
  }
`;

export const YearViewContainer4 = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr); // 4 months in a row
  gap: 20px;
  width: 90vw; /* Ensure the container spans the entire width */
`;

export const YearViewContainer3 = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); // 3 months in a row
  gap: 20px;
  width: 90vw; /* Ensure the container spans the entire width */
`;

export const MonthSquare = styled.div`
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 16vh;
  width: 100%;
  overflow: hidden; // Add overflow property to prevent content from affecting width
  overflow-y: auto; // Add overflow-y property to enable vertical scrolling
`;

export const MonthTitle = styled.h4`
  margin: 0;
  padding: 0;
  // Additional styling for the month title
`;

export const EventList = styled.ul`
  list-style: none;
  padding: 0;
  margin-top: 10px;
  text-align: left;
  width: 100%;
`;

export const EventItem = styled.li`
  margin-bottom: 5px;
  // Additional styling for event items
`;



export const STab = styled.button`
  padding: 10px 20px;
  cursor: pointer;
  border: 1px solid #ccc;
  background-color: #f0f0f0; // Inactive tab background
  color: ${props => props.theme.primary_colour};
  margin-right: 10px; // Add some spacing between tabs
  border-bottom: none; // Align with the content area
  border-top-left-radius: 5px; // Rounded corners for the top-left
  border-top-right-radius: 5px; // Rounded corners for the top-right

  &:hover {
    background-color: #e0e0e0; // Slightly darker on hover for inactive tabs
  }

  &.active {
    background-color: #fff; // Active tab matches the content area
    color: ${props => props.theme.accent_colour};
    border-top: 2px solid ${props => props.theme.accent_colour}; // Highlight color for active tab
    position: relative;
    top: 1px; // 
  }
`;

export const GlobalStyle = createGlobalStyle`
  #app {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  html, body {
    position: relative;
    height: 100%;
    background: #fff;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;
  }


`;

export const StyledCarousel = styled(Carousel)`
  /* Add your desired styles here */
  .carousel .slider-wrapper {
    width: 90vw; /* Set the overall width of the carousel */
  }

  .carousel .slider {
    display: flex;
  }

  .carousel .slide {
    height: 200px; /* Adjust the height as needed */
    margin-right: 10px; /* Add some margin between items */
    border: 1px solid #ccc; /* Add a border for a card-like appearance */
    border-radius: 8px; /* Adjust the border radius for rounded corners */
    background-color: #fff; /* Background color for the items */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add shadow for a card effect */

    /* Center text vertically and horizontally */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    /* Change font size */
    font-size: 18px; /* Adjust the font size as needed */

    /* Apply theme-based styles using props */
    ${(props) =>
      props.theme &&
      css`
        background-color: ${props.theme.primary_colour};
        color: ${props.theme.primary_text_and_icon_colour};
      `}
  }

  .carousel .thumbs-wrapper {
    /* Add styles for the thumbs wrapper here */
    /* For example, you can set a background color, padding, and other styles */
    background-color:  #f3f3f3; /* Example background color */
    padding: 10px; /* Add padding around the thumbs */
  }
`;

export const Section = styled.div`

  position: relative;
  border: 1px solid #ddd;
  padding-left: 20px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;

  &:before {
    content: attr(data-title);
    writing-mode: vertical-lr;
    text-orientation: mixed;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    ${(props) =>
      props.theme &&
      css`
        background-color: ${props.theme.primary_colour};
        color: ${props.theme.primary_text_and_icon_colour};
      `}
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }

  h2 {
    display: none; /* Hide the original h2, as the title is now in the pseudo-element */
  }
`;