import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import auditProtection from "../hoc/auditProtection.js";

const apiUrl = process.env.REACT_APP_API_URL;

const MaintProgramForm = ({ onProgramAdded }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const formInitiatedFromHome = location.state?.fromHome || false;
  const [assets, setAssets] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState("");
  const [isDrone, setIsDrone] = useState(false); // New state to track if the selected asset is a drone
  const [isHours, setIsHours] = useState(false); // Toggle between minutes and hours

  const [maintProgramHeader, setMaintProgramHeader] = useState({
    program_name: "",
    org_id: "",
    start_date: dayjs().format("YYYY-MM-DD"),
    is_active: false,
  });

  const [task, setTask] = useState({
    task_name: "",
    interval_days: "",
    interval_minutes: "",
  });

  const [tasks, setTasks] = useState([]); // For holding multiple tasks
  const [isFormVisible, setIsFormVisible] = useState(true);

  useEffect(() => {
    const membershipToken = localStorage.getItem("MToken");

    axios
      .post(`${apiUrl}/decode-membership-token/${membershipToken}`)
      .then((res) => {
        const orgId = res.data.data.membership_data.org_id;
        setMaintProgramHeader((prevState) => ({
          ...prevState,
          org_id: orgId,
        }));

        // Fetch the assets
        axios
          .get(`${apiUrl}/assets/all/${orgId}`)
          .then((assetRes) => {
            setAssets(assetRes.data); // Set the asset list in state
          })
          .catch((error) => {
            console.error("Error fetching assets:", error);
          });
      })
      .catch((error) => {
        console.error("Error decoding membership token:", error);
      });
  }, []);

  const handleTaskChange = (e) => {
    const { name, value } = e.target;
    setTask((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMaintProgramHeader((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

 // Handle asset selection and check if the selected asset is a drone
const handleAssetChange = (e) => {
  const selectedAssetId = e.target.value;
  setSelectedAsset(selectedAssetId);

  const selectedAssetObj = assets.find((asset) => asset.id === e.target.value);
  console.log("Selected Asset:", selectedAssetObj);

  if (selectedAssetObj && selectedAssetObj.asset_type_id === 1) {
    setIsDrone(true); // Disable the minutes/hours inputs
  } else {
    setIsDrone(false); // Enable the minutes/hours inputs
  }
};

// useEffect to log `isDrone` state after it updates
useEffect(() => {
  console.log("Is Drone updated:", isDrone);
}, 
[isDrone]);

  // Toggle between minutes and hours
  const handleToggleChange = () => {
    setIsHours(!isHours); // Switch between hours and minutes
  };

  // Add a task to the task list
  const handleAddTask = () => {
    if (!task.task_name || (!task.interval_days && !task.interval_minutes)) {
      alert("Task name and at least one interval (days or minutes) are required");
      return;
    }

    // Convert hours to minutes if the toggle is set to hours
    const intervalMinutes = isHours
      ? parseInt(task.interval_minutes) * 60 // Convert hours to minutes
      : parseInt(task.interval_minutes);

    // Add task to task list
    setTasks((prevTasks) => [
      ...prevTasks,
      {
        ...task,
        interval_minutes: intervalMinutes,
      },
    ]);

    // Clear the task input form
    setTask({
      task_name: "",
      interval_days: "",
      interval_minutes: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const newMaintProgramHeader = {
        org_id: maintProgramHeader.org_id,
        program_name: maintProgramHeader.program_name,
        start_date: maintProgramHeader.start_date,
        is_active: true,
        asset_id: selectedAsset,
      };

      // Call the API to create the maintenance program header
      const programResponse = await axios.post(
        `${apiUrl}/maint/generate_program_header`,
        newMaintProgramHeader
      );

      const programId = programResponse.data.id;

      // Submit each task to the backend
      for (const task of tasks) {
        const newTask = {
          program_id: programId,
          task_name: task.task_name,
          interval_days: task.interval_days ? parseInt(task.interval_days) : null,
          interval_flight_minutes: task.interval_minutes ? parseInt(task.interval_minutes) : null,
          is_active: true,
        };

        const taskResponse = await axios.post(`${apiUrl}/maint/add_task`, newTask);
        const taskId = taskResponse.data.id;

        const taskRecord = {
          task_id: taskId,
          asset_id: selectedAsset,
          drop_dead_days: task.interval_days ? parseInt(task.interval_days) : null,
          drop_dead_flight_minutes: task.interval_minutes ? parseInt(task.interval_minutes) : null,
          start_date: maintProgramHeader.start_date, // Pass start_date for calculation
          complete: false,
        };
        await axios.post(`${apiUrl}/maint/add_task_record`, taskRecord);
      }

      setIsFormVisible(false);

      
        navigate("/maint/home");
      

      if (onProgramAdded) {
        onProgramAdded();
      }
    } catch (error) {
      console.error("Error creating maint program:", error);
    }
  };

  return (
    <div className="flex items-start justify-center min-h-screen bg-gray-100 p-4">
    {isFormVisible && (
      <div className="flex w-full max-w-6xl">
        {/* Main Form */}
        <form
          onSubmit={handleSubmit}
          className="flex flex-col w-full max-w-lg p-8 space-y-8 bg-white shadow-xl rounded-lg"
        >
          <div className="flex items-center">
            <div className="flex-grow">
              <label className="text-lg mb-2">Maintenance Program Name:</label>
              <input
                type="text"
                name="program_name"
                value={maintProgramHeader.program_name}
                onChange={handleChange}
                className="w-full border h-10 border-gray-300 px-3 rounded"
              />
            </div>
          </div>
  
          <div>
            <label className="text-lg mb-2">Select Asset:</label>
            <select
              name="selectedAsset"
              value={selectedAsset}
              onChange={handleAssetChange} // Use the new handleAssetChange function
              className="w-full border h-10 border-gray-300 px-3 rounded"
            >
              <option value="">-- Select an Asset --</option>
              {assets.map((asset) => (
                <option key={asset.id} value={asset.id}>
                  {asset.code_name} ({asset.asset_type})
                </option>
              ))}
            </select>
          </div>
  
          <div>
            <label className="text-lg mb-2">Start Date:</label>
            <input
              type="date"
              name="start_date"
              value={maintProgramHeader.start_date}
              onChange={handleChange}
              className="w-full border h-10 border-gray-300 px-3 rounded"
            />
          </div>
  
          {/* Task Information Panel */}
          <div className="p-4 border border-gray-300 rounded-lg bg-gray-200">
            <h3 className="text-lg font-bold mb-4">Task Information</h3>
  
            <div>
              <label className="text-lg mb-2">Task Name:</label>
              <input
                type="text"
                name="task_name"
                value={task.task_name}
                onChange={handleTaskChange}
                className="w-full border h-10 border-gray-300 px-3 rounded"
              />
            </div>
  
            <div className="mt-4">
              <label className="text-lg mb-2">Interval (Days):</label>
              <input
                type="number"
                name="interval_days"
                value={task.interval_days}
                onChange={handleTaskChange}
                className="w-full border h-10 border-gray-300 px-3 rounded"
              />
            </div>
  
            {/* Conditionally disable hours/minutes based on asset type */}
            {isDrone && (
              <div className="mt-4">
                <label className="text-lg mb-2">Flight Interval ({isHours ? "Hours" : "Minutes"}):</label>
                <input
                  type="number"
                  name="interval_minutes"
                  value={task.interval_minutes}
                  onChange={handleTaskChange}
                  className="w-full border h-10 border-gray-300 px-3 rounded"
                />
                <div className="flex items-center mt-2">
                  <label className="mr-2">Enter Interval in Hours</label>
                  <input
                    type="checkbox"
                    checked={isHours}
                    onChange={handleToggleChange}
                    className="h-5 w-5"
                  />
                </div>
              </div>
            )}
  
            <div className="flex justify-center items-center mt-4">
              <button
                type="button"
                onClick={handleAddTask}
                className="bg-primary hover:bg-secondary text-white font-bold py-2 px-4 rounded"
              >
                + Add Task
              </button>
            </div>
          </div>
  
          <button
            type="submit"
            className="bg-primary hover:bg-secondary text-white font-bold py-2 px-4 rounded block mx-auto"
          >
            Submit Maintenance Program
          </button>
        </form>
  
        {/* Task Panel (Right Side) */}
        <div className="w-full max-w-sm p-4 bg-gray-200 rounded-lg ml-6">
          <h3 className="text-lg font-bold mb-4">Tasks Added:</h3>
          {tasks.length > 0 ? (
            <ul className="space-y-2">
              {tasks.map((task, index) => (
                <li key={index} className="bg-white p-3 rounded shadow-md">
                  <strong>{task.task_name}</strong>
                  <div>
                    {task.interval_days || 0} days / {task.interval_minutes || 0} minutes
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p>No tasks added yet.</p>
          )}
        </div>
      </div>
    )}
  </div>
  
  );
};

export default auditProtection(MaintProgramForm);
