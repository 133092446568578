// React and React-related imports

import React, { useState, useEffect, useContext } from "react";
import Modal from "react-modal";

// Third-party libraries
import axios from "axios";
import Swal from "sweetalert2";
import { debounce } from "lodash";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { LineChart } from "@mui/x-charts/LineChart";
import { ButtonGroup, Button, Box } from "@mui/material";

// Router imports
import { useNavigate, useParams } from "react-router-dom";

// Context imports
import { ThemeContext } from "../components/theme.js";
// Icon imports
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faPlus,
  faHomeLgAlt,
  faLock,
} from "@fortawesome/free-solid-svg-icons";

// CSS and Style imports

import "../components/styles.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

// Styled-components and Related Imports
import { ThemeProvider } from "styled-components";
import {
  GlobalStyle,
  StyledCarousel,
  BottomContainer,
  AddButton,
  NoChecklistsMessage,
  ChecklistsGrid,
  AuditHeader,
  AuditDetails,
  StyledNavLink,
  Mask,
  Section,
} from "../components/styledComponents.js";

// Component imports
import auditProtection from "../hoc/auditProtection.js";
import CircularProgress from "../components/CircularProgress.js";
import CircularScore from "../components/CircularScore.js";
import { ta } from "date-fns/locale";

const apiUrl = process.env.REACT_APP_API_URL;

function formatDate(dateString) {
  const options = { year: "numeric", month: "long", day: "numeric" };
  const formattedDate = new Date(dateString).toLocaleDateString(
    undefined,
    options
  );
  return formattedDate;
}

Modal.setAppElement("#root");

function CreateMaintPage(props) {
  const { theme, setTheme } = useContext(ThemeContext);
  const [userID, setUserID] = useState("");
  const [checklists, setChecklists] = useState([]);
  const navigate = useNavigate();
  const { auditId } = useParams();
  const [isAdding, setIsAdding] = useState(false);
  const [addedChecklists, setAddedChecklists] = useState([]);
  const [auditDetails, setAuditDetails] = useState(null);
  const [progress, setProgress] = useState({ total: 0, answered: 0 });
  const [showBottomContainer, setShowBottomContainer] = useState(false);
  const [availableBlueprints, setAvailableBlueprints] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const [isExpanded, setIsExpanded] = useState(false);
  const [assets, setAssets] = useState([]);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
    handleToggleBottomContainer();
  };

  const fetchAuditDetails = async (auditId) => {
    try {
      //console.log("Fetching audit details for audit ID:", auditId);
      // Call the function to update the status on the server
      if (auditId) {
        await updateAuditStatusOnServer(auditId);
      }

      const response = await axios.get(
        `${apiUrl}/maint/get_maint_header/${auditId}`
      );
      const auditDetailsData = response.data;
      console.log("Audit details:", auditDetailsData);
      // Set the audit details in the state
      setAuditDetails(auditDetailsData);
      
     // Fetch the asset detail
     axios
     .get(`${apiUrl}/assets/${auditDetailsData.asset_id}`)
     .then((assetRes) => {
       setAssets(assetRes.data); // Set the specific asset in state
       console.log("Asset:", assetRes.data);
     })
     .catch((error) => {
       console.error("Error fetching asset:", error);
     });
   

    } catch (error) {
      console.error("Error fetching audit details:", error);
      // Handle error appropriately
    }

     
     
  };

  const fetchChecklists = async (auditId) => {
    try {
      // Fetch checklists already in the audit
      const responseAuditChecklists = await axios.get(
        `${apiUrl}/maint/get_maint_checklists/${auditId}`
      );

      const auditChecklists = responseAuditChecklists.data.checklist_names; // Updated property name
      console.log(
        "Audit checklists:",
        responseAuditChecklists.data.checklist_names
      );

      // Process the audit checklists
      const formattedChecklists = auditChecklists.map((checklist) => ({
        id: checklist.id, // Add the id property
        checklist_name: checklist.checklist_name,
        created_at: checklist.created_at,
        updated_at:
          checklist.updated_at !== undefined ? checklist.updated_at : null,
        // Add other checklist details as needed
      }));

      setAddedChecklists(formattedChecklists);

      // Fetch organization ID from decoded membership token
      const mToken = localStorage.getItem("MToken");
      if (!mToken) {
        navigate("/maint/home"); // Redirect to home if there is no MToken
        return;
      }

      const response = await axios.post(
        `${apiUrl}/decode-membership-token/${mToken}`
      );
      const membershipData = response.data;

      if (
        membershipData &&
        membershipData.data &&
        membershipData.data.membership_data
      ) {
        const orgId = membershipData.data.membership_data.org_id;
        console.log("Organization ID:", orgId);

        // Fetch available blueprints for the organization
        const responseAvailableBlueprints = await axios.get(
          `${apiUrl}/maint/get_available_blueprints/${orgId}`
        );

        const availableBlueprints = responseAvailableBlueprints.data;
        //console.log("Available blueprints:", availableBlueprints);
        setAvailableBlueprints(availableBlueprints);

       
      }
    } catch (error) {
      console.error("Error fetching audit checklists:", error);
    }
  };

  const fetchProgress = async () => {
    try {
      const response = await axios.get(`${apiUrl}/maint/progress/${auditId}`);
      setProgress(response.data);
    } catch (error) {
      console.error("Error fetching progress:", error);
      // Handle error appropriately
    }
  };

  const AuditScores = ({ auditId }) => {
    const [score, setScore] = useState(null);

    useEffect(() => {
      const fetchScore = async () => {
        try {
          const response = await axios.get(
            `${apiUrl}/maint/scores/maint/${auditId}`
          );
          // Assuming the response is an array and you need the first item's average_score
          const fetchedScore = response.data[0]?.average_score;
          if (fetchedScore !== undefined) {
            setScore(fetchedScore);
          } else {
            // Handle the case where average_score is not in the response
            //console.error("Average score not found in the response");
            setScore(0);
          }
        } catch (error) {
          console.error("Error fetching score", error);
          setScore(0); // Or handle differently
        }
      };

      if (auditId) {
        fetchScore();
      }
    }, [auditId, apiUrl]);

    if (score === null || score === 0) {
      return null; // Don't render if score is zero or not fetched
    }

    return (
      <div className="circular-score-container">
        <CircularScore averageScore={score} />{" "}
        {/* Make sure prop names match */}
      </div>
    );
  };

  const ChecklistScores = ({ checklistId, auditId }) => {
    const [score, setScore] = useState(null);

    useEffect(() => {
      const fetchScore = async () => {
        try {
          const response = await axios.get(
            `${apiUrl}/maint/scores/checklist/${checklistId}/maint/${auditId}`
          );
          // Assuming the response is an array and you need the first item's average_score
          const fetchedScore = response.data[0]?.average_score;
          if (fetchedScore !== undefined) {
            setScore(fetchedScore);
          } else {
            // Handle the case where average_score is not in the response
            //console.error("Average score not found in the response");
            setScore(0);
          }
        } catch (error) {
          console.error("Error fetching score", error);
          setScore(0); // Or handle differently
        }
      };

      if (checklistId && auditId) {
        fetchScore();
      }
    }, [checklistId, auditId, apiUrl]);

    if (score === null || score === 0) {
      return null; // Don't render if score is zero or not fetched
    }

    return (
      <div className="circular-score-container">
        <CircularScore averageScore={score} />{" "}
        {/* Make sure prop names match */}
      </div>
    );
  };

  const updateAuditStatusOnServer = async (auditId) => {
    try {
      await axios.post(`${apiUrl}/maint/update_maint_status/${auditId}`);
    } catch (error) {
      console.error("Error updating audit status:", error);
      // Handle error appropriately
    }
  };

  const handleDownloadReport = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/maint/generate_maint_report/${auditId}`,
        {
          responseType: "blob", // Important: handle the response as a Blob
        }
      );

      // Create a URL for the blob
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement("a");

      fileLink.href = fileURL;
      fileLink.setAttribute("download", `audit_report_${auditId}.pdf`);
      document.body.appendChild(fileLink);

      // Programmatically click the link to trigger the download
      fileLink.click();

      // Clean up: remove the link
      fileLink.parentNode.removeChild(fileLink);
    } catch (error) {
      console.error("Error generating audit report: ", error);
      Swal.fire({
        title: "Error!",
        text: "Failed to generate audit report.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const handleDeleteChecklist = async (checklistId, auditId) => {
    try {
      const response = await axios.get(
        `${apiUrl}/maint/checklist_answers_exist/${checklistId}/${auditId}`
      );
      const hasAnswers = response.data.hasAnswers;

      if (hasAnswers) {
        Swal.fire({
          title: `This checklist cannot be deleted because it has questions with answers.`,
          icon: "warning",
          showCancelButton: false,
          cancelButtonColor: "#3085d6",
          cancelButtonText: "OK",
        });
      } else {
        Swal.fire({
          title: "Hold on a sec!",
          text: "Are you sure you want to delete this checklist?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          confirmButtonText: "DELETE CHECKLIST",
          input: "text",
          inputAttributes: {
            autocapitalize: "off",
          },
          inputPlaceholder: "Type 'DELETE' to confirm",
          showLoaderOnConfirm: true,
          preConfirm: async (input) => {
            if (input !== "DELETE") {
              Swal.showValidationMessage(
                "You need to type 'DELETE' to confirm"
              );
            } else {
              try {
                await deleteChecklist(checklistId, auditId);
                fetchChecklists(auditId);
              } catch (error) {
                console.error("Error deleting checklist:", error);
                // Handle error appropriately
              }
            }
          },
          allowOutsideClick: () => !Swal.isLoading(),
        });
      }
    } catch (error) {
      console.error("Error handling audit deletion:", error);
      // Handle error appropriately
    }
  };

  const deleteChecklist = async (checklistId) => {
    try {
      await axios.delete(
        `${apiUrl}/maint/delete_maint_checklist/${checklistId}/${auditId}`
      );
      fetchChecklists(auditId);
    } catch (error) {
      console.error("Error deleting checklist:", error);
      // Handle error appropriately
    }
  };

  const handleAddToAudit = async (checklistId) => {
    if (isAdding) return;

    setIsAdding(true);
    console.log("Adding checklist to audit:", checklistId, auditId);
    try {
      const response = await axios.post(`${apiUrl}/maint/add_maint_checklist`, {
        maintHeaderId: auditId,
        checklist_header_id: checklistId,
      });

      // Assuming fetchChecklists updates the state after fetching
      await fetchChecklists(auditId);
    } catch (error) {
      console.error("Error adding audit detail:", error);
    } finally {
      setIsAdding(false);
    }
  };

  const renderAddedChecklists = () => {
    //console.log("Added checklists:", addedChecklists);
    
    return addedChecklists.map((checklist) => (
     
      <div
        key={checklist.id}
        className="border p-4 rounded-lg shadow-md relative flex flex-col justify-between"
      >
        {/* Title and Trash Icon */}
        <div className="flex justify-between items-center">
          <h3 className="text-xl font-semibold truncate">
            {checklist.checklist_name}
          </h3>
          <button
            className="text-red-400"
            onClick={() => handleDeleteChecklist(checklist.id, auditId)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>

        {/* Date Added and Updated On */}
        <div className="mb-2 mt-2">
          <p>Date Added: {formatDate(checklist.created_at)}</p>
          {checklist.updated_at && (
            <p>Updated On: {formatDate(checklist.updated_at)}</p>
          )}
        </div>

        {/* Scores */}
        <div className="flex justify-end mt-auto">
          {/* Align the scores to the bottom-right */}
          <ChecklistScores checklistId={checklist.id} auditId={auditId} />
        </div>
      </div>
    ));
  };

  const closeAudit = async () => {
    try {
      await axios.post(`${apiUrl}/maint/close_maint/${auditId}`);
      fetchAuditDetails(auditId);
    } catch (error) {
      console.error("Error closing audit:", error);
      // Handle error appropriately
    }
  };

  const handleToggleBottomContainer = () => {
    setShowBottomContainer(!showBottomContainer);
  };

  const handleAddSummary = () => {
    Swal.fire({
      title: "Workpack Summary",
      html: `
        <textarea id="swal-input" class="swal2-textarea" placeholder="Type your summary here..." style="width: 60vh; height: 30vh;">${
          auditDetails && auditDetails.summary !== null
            ? auditDetails.summary
            : ""
        }</textarea>
      `,
      focusConfirm: false,
      width: "75vh",
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonColor: theme.primary_colour,
      cancelButtonText: "Close",
      didOpen: () => {
        const textarea = document.getElementById("swal-input");
        textarea.addEventListener(
          "input",
          debounce((event) => {
            handleCommentChange(auditDetails.id, event.target.value);
          }, 500)
        );
        textarea.focus();
        const textLength = textarea.value.length;
        textarea.setSelectionRange(textLength, textLength);
      },
      willClose: () => {
        fetchAuditDetails(auditId);
      },
    });
  };

  const handleCommentChange = debounce(async (auditContentId, summary) => {
    try {
      const response = await axios.post(`${apiUrl}/maint/update_summary`, {
        id: auditContentId,
        summary,
      });

      // Check if response data is null and handle accordingly
      if (response.data && response.data.message) {
        console.log(response.data.message);
      } else {
        console.log("Summary updated successfully.");
      }
    } catch (error) {
      console.error("Error updating comment", error);
    }
  }, 500);

  useEffect(() => {
    if (auditId) {
      console.log("Audit ID:", auditId);
      // Fetch audit details and progress
      fetchAuditDetails(auditId);
      fetchProgress();

      // Fetch checklists and render added checklists
      fetchChecklists(auditId).then(() => {
        renderAddedChecklists();
      });
    }
  }, [auditId]);

  useEffect(() => {
    // Render added checklists whenever addedChecklists state changes
    renderAddedChecklists();
  }, [addedChecklists]);

  //if (isLoading) {
  //  return <div>Loading...</div>; // Show loading message while fetching data
  //}

  if (!auditDetails) {
    return <div>Loading maintenance..</div>; // Handle case where auditDetails is null
  }

  //Main Render
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />

      <div className="container mx-auto px-6">
        <div className="flex items-center justify-between mt-5 mb-10">
          <div
            onClick={() => navigate("/maint/home")}
            className="text-primary cursor-pointer flex-shrink-0"
          >
            {theme.logoUrl ? (
              <img
                src={theme.logoUrl}
                alt="Membership Logo"
                className="h-16 w-auto"
              />
            ) : (
              <FontAwesomeIcon icon={faHomeLgAlt} size="2x" color="grey" />
            )}
          </div>
          {auditDetails && (
            <h1 className="text-4xl text-primary flex-grow text-center">
              {auditDetails.maint_name}
            </h1>
          )}

          <div className="flex-shrink-0 flex items-center justify-end">
            <StyledNavLink
              to={`/maint/edit_maint/${auditId}`}
              primary
              theme={theme}
              className="w-40 py-2 px-4 rounded-lg text-center"
            >
              Edit Workpack
            </StyledNavLink>
          </div>
        </div>

        {/* Top Section */}
        <div className="border rounded-lg p-4 mb-4">
          <div className="flex flex-wrap justify-between items-stretch space-x-2">
            {/* Pilot Details */}
            <AuditDetails className="flex-1 min-h-full p-2">
              <h2 className="text-lg font-bold mb-2">Workpack Details</h2>
              <p>
                <strong>Maintainer:</strong> {auditDetails.maintainer_name}
              </p>

              <p>
                <strong>Scheduled:</strong>{" "}
                {auditDetails ? formatDate(auditDetails.date_scheduled) : "N/A"}
              </p>
            </AuditDetails>

            {/* Drone Details */}
            <AuditDetails className="flex-1 min-h-full p-2">
              <h2 className="text-lg font-bold mb-2">Asset Details</h2>
              <p>
                <strong>Type:</strong>{" "}
                {assets ? (assets.asset_type) : "N/A"}
              </p>
              <p>
                <strong>Code Name:</strong>{" "}
                {assets ? (assets.code_name) : "N/A"}
              </p>
            </AuditDetails>
          </div>
        </div>

        {/* Middle Section */}
        <div
          className={`border rounded-lg p-4 mb-4 transition-all duration-300 ${
            isExpanded ? "h-auto" : "h-50"
          }`}
        >
          <div className="flex flex-row space-x-4">
            {/* Checklists Section */}
            <div className="flex-1">
              {showBottomContainer && (
                <BottomContainer>
                  {availableBlueprints && availableBlueprints.length > 0 ? (
                    <StyledCarousel
                      showThumbs={false}
                      emulateTouch={true}
                      theme={theme}
                      centerMode={true}
                      centerSlidePercentage={100 / 5}
                    >
                      {availableBlueprints.map((blueprint) => (
                        <div key={blueprint[2]}>
                          <div style={{ textAlign: "center" }}>
                            <h3>{blueprint[3]}</h3>
                          </div>
                          <AddButton
                            onClick={() => handleAddToAudit(blueprint[2])}
                            disabled={isAdding}
                          >
                            <FontAwesomeIcon icon={faPlus} />
                          </AddButton>
                        </div>
                      ))}
                    </StyledCarousel>
                  ) : (
                    <NoChecklistsMessage>
                      No jobcards available to add.
                    </NoChecklistsMessage>
                  )}
                </BottomContainer>
              )}

              {/* Render added checklists */}
              <ChecklistsGrid>{renderAddedChecklists()}</ChecklistsGrid>
            </div>

            {/* Buttons Section */}
            <div className="flex-1 flex flex-col items-end mb-4 space-y-2">
              <StyledNavLink
                primary
                theme={theme}
                disabled={
                  auditDetails ? auditDetails.status === "Closed" : true
                }
                onClick={handleAddSummary}
                className="w-40 py-2 px-4 rounded-lg text-center"
              >
                Add Summary
                {auditDetails && auditDetails.status === "Closed" && (
                  <FontAwesomeIcon icon={faLock} className="ml-2" />
                )}
              </StyledNavLink>

              <StyledNavLink
                primary
                theme={theme}
                disabled={
                  auditDetails ? auditDetails.status === "Closed" : true
                }
                onClick={handleToggle}
                className="w-40 py-2 px-4 rounded-lg text-center"
              >
                {showBottomContainer ? "Hide Jobcard Menu" : "Add Jobcard"}
                {auditDetails && auditDetails.status === "Closed" && (
                  <FontAwesomeIcon icon={faLock} className="ml-2" />
                )}
              </StyledNavLink>

              <StyledNavLink
                primary
                theme={theme}
                disabled={
                  auditDetails
                    ? auditDetails.status === "Closed" ||
                      addedChecklists.length === 0
                    : true
                }
                to={`/maint/maint_live/${auditId}`}
                className="w-40 py-2 px-4 rounded-lg text-center"
              >
                Perform Maintenance
                {auditDetails &&
                  (auditDetails.status === "Closed" ||
                    addedChecklists.length === 0) && (
                    <FontAwesomeIcon icon={faLock} className="ml-2" />
                  )}
              </StyledNavLink>

              <StyledNavLink
                primary
                theme={theme}
                disabled={
                  auditDetails ? auditDetails.status !== "Complete" : true
                }
                className="w-40 py-2 px-4 rounded-lg text-center"
                onClick={closeAudit}
              >
                Close Workpack
                {auditDetails && auditDetails.status !== "Complete" && (
                  <FontAwesomeIcon icon={faLock} className="ml-2" />
                )}
              </StyledNavLink>
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default auditProtection(CreateMaintPage);
