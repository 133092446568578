import React, { useState, useEffect } from "react";
import axios from "axios";
import Switch from "react-switch";
import { useNavigate } from "react-router-dom";

const apiUrl = process.env.REACT_APP_API_URL;


const ChecklistHeaderForm = () => {
  const navigate = useNavigate();
  const [orgID, setOrgId] = useState([]);
  const [userID, setUserID] = useState([]);
  const membershipToken = localStorage.getItem("MToken");

  const fetchUserID = async () => {
    const token = localStorage.getItem("access_token");
    try {
      const userRes = await axios.get(apiUrl + "/get_user_id_from_token", {
        headers: { Authorization: `Bearer ${token}` },
      });
      const userID = userRes.data.user_id;
      setUserID(userID);

    } catch (error) {
      console.error("There was an error!", error.response);
      if (error.response && error.response.status === 401) {
        navigate("/login");
      }
    }
  };

  useEffect(() => {
    fetchUserID();
  }, [userID]);

  // decode the membership token to get org details
  useEffect(() => {
    axios
      .post(`${apiUrl}/decode-membership-token/${membershipToken}`)
      .then((res) => {
        const organisation_id =
          res.data.data.membership_data.org_id; // validate this path yourself
        
        // directly set checklistHeader's OrgId 
        setChecklistHeader({ ...checklistHeader, orgID: organisation_id });
        setOrgId(organisation_id);

       
      });
  }, []); // Empty dependency array

  const handleChange = (e) => {
    const { name, value } = e.target;
   
      setChecklistHeader((prevState) => ({
        ...prevState,
        [name]: value,
        }));
    };
    
  const [checklistHeader, setChecklistHeader] = useState({
    checklist_name: "",
    user_id: "",
    org_id: "",
    is_active: true, // default value
    blueprint: true, // default value
  });

  let newChecklistId; // declaring beforehand

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        const newChecklistHeader = {
            ...checklistHeader,
            user_id: userID, // Assuming this is a single user ID value
            org_id: orgID, // Assuming the first element has the ID
          };
          
      console.log(newChecklistHeader);
      const response = await axios.post(
        apiUrl + "/audit/generate_checklist_header",
        newChecklistHeader
      );
      newChecklistId = response.data.id;
  navigate(`/flight/create_test_blueprint/${newChecklistId}`);
} catch (e) {
  console.error(e);
  // Handle error, maybe stay on the page or show a message

}
  };


  return (
    <div className="flex flex-col items-center justify-center h-screen bg-secondary2 text-gray-800">
   
      <h2 className="text-4xl mb-10 text-center">
            Create <span className="text-blue-900">Testcard</span>
            </h2>
      
      <form
        onSubmit={handleSubmit}
        className="flex flex-col w-full max-w-md p-8 space-y-8 bg-white shadow-xl rounded-lg"
      >
        
        <div>
          <label className="text-lg mb-2">Testcard Name/Reference:</label>
          <input
  type="text"
  name="checklist_name" // This should match the key in your state
  value={checklistHeader.checklist_name}
  onChange={handleChange}
  className="w-full border h-10 border-gray-300 px-3 rounded"
/>

        </div>

        <div className="mt-4 flex justify-center">
          <button
            type="submit"
            className="bg-primary hover:bg-secondary text-white font-bold py-2 px-4 rounded block mx-auto"
          >
            OK
          </button>
        </div>
      </form>
    </div>
  );
};

export default ChecklistHeaderForm;