import React, { useState, useEffect, useContext } from "react";

import { useParams } from 'react-router-dom';
import auditProtection from "../hoc/auditProtection.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHomeLgAlt } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

import AuditCalendar from '../components/AuditCalendar'; // Import your component
import axios from 'axios';
import { ThemeContext } from "../components/theme.js";
// Styled-components and Related Imports
import { ThemeProvider } from "styled-components";
import {
  GlobalStyle,

} from "../components/styledComponents";


const apiUrl = process.env.REACT_APP_API_URL;
// Define your theme and other necessary imports


function MaintProgram() {
    const { programId } = useParams(); // get the id from the url
    const navigate = useNavigate(); // Initialize navigate hook
    const { theme, setTheme } = useContext(ThemeContext);
    const [programName, setProgramName] = useState("");

    const fetchMaintProgramDetails = async () => {
        try {
          // Fetch program details including start and end dates
          const programResponse = await axios.get(`${apiUrl}/maint/program/${programId}`);
          const programName = programResponse.data.program_name;
          
          setProgramName(programName);
       
        } catch (error) {
            console.error("There was an error fetching the maint program details:", error);
          }
        };
    
        useEffect(() => {
            fetchMaintProgramDetails();
          }, []);


    const Header = () => (
       
       <div className="flex items-center justify-between mt-5 mb-10">
             <div
        onClick={() => navigate("/maint/home")}
        className="text-primary cursor-pointer flex-shrink-0"
      >
        {theme.logoUrl ? (
          <img
            src={theme.logoUrl}
            alt="Membership Logo"
            className="h-16 w-auto"
          />
        ) : (
          <FontAwesomeIcon icon={faHomeLgAlt} size="2x" color="grey" />
        )}
      </div>
      <h1 className="text-4xl text-primary flex-grow text-center">
             {programName}
          </h1>
          <div className="w-20"> {/* Placeholder to balance the header */}</div>
        </div>
    );

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyle />
            <div>
                <Header />
                <AuditCalendar programId={programId} />
            </div>
        </ThemeProvider>
    );
}

export default auditProtection(MaintProgram);