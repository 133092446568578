import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ReactModal from "react-modal";


const apiUrl = process.env.REACT_APP_API_URL;

const withAdminProtection = (Component) => {
    return (props) => {  // Notice the props here
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [admin, setAdmin] = useState(false);

    useEffect(() => {
      const fetchData = async () => {
        try {
          let token = localStorage.getItem("access_token");
          if (!token) {
            navigate("/login"); // redirect to login if there is no token
            return;
          }
          const response = await axios.get(apiUrl + "/check_superAdmin_status", {
            headers: { Authorization: `Bearer ${token}` },
          });
          setAdmin(response.data.is_superAdmin); 
          if(!response.data.is_superAdmin) {
            setIsModalOpen(true);
          } 
        } catch (err) {
          setIsModalOpen(true); 
        }
      };
      fetchData();
    }, [navigate]);

    if (!admin) {
      return (
        <ReactModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            padding: "2rem",
          },
        }}
        contentLabel="Access Denied Modal"
      >
        <div className="flex flex-col items-center bg-white text-gray-400 rounded">
          <h2 className="text-2xl mb-4 text-center">Not Authorised</h2>
          <p className="text-center">
            This area is not available to your account
          </p>
          <button
            onClick={() => {
              setIsModalOpen(false);
              navigate("/home");
            }}
            className="mt-4 bg-red-500 text-white py-2 px-4 rounded"
          >
            Close
          </button>
        </div>
      </ReactModal>
      );
    }
       // If user is admin, render the wrapped component
       return <Component {...props} />;
    };
  };
  
  export default withAdminProtection; 