import React, { useState, useEffect } from "react";
import axios from "axios";
import Switch from "react-switch";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const apiUrl = process.env.REACT_APP_API_URL;

function MonthPicker({ value, onChange }) {
  const dateValue = value ? new Date(value) : null;

  return (
    <div>
      <label className="text-lg mb-2">Date Scheduled:</label>
      <DatePicker
        selected={dateValue}
        onChange={(date) => onChange(date.toISOString())}
        dateFormat="MM/dd/yyyy"
        className="w-full border h-10 border-gray-300 px-3 rounded"
      />
    </div>
  );
}

const AuditHeaderForm = () => {
  const navigate = useNavigate();
  const [auditOwners, setAuditOwners] = useState([]);
  const [orgFunctions, setOrgFunctions] = useState([]);
  const [auditType, setAuditType] = useState(false);
  const [supplier, setSupplier] = useState(""); // Add this new state
  const [auditPrograms, setAuditPrograms] = useState([]); // State to hold audit programs
  const [selectedAuditProgram, setSelectedAuditProgram] = useState(""); // State to hold selected audit program

  const membershipToken = localStorage.getItem("MToken");

  // decode the membership token to get org details and fetch audit programs when component mounts
useEffect(() => {
  // Decode membership token
  axios
    .post(`${apiUrl}/decode-membership-token/${membershipToken}`)
    .then((res) => {
      const organisation_name =
        res.data.data.membership_data.organisation_name; // validate this path yourself

      // directly set auditHeader's audit_owner and auditOwners state
      setAuditHeader({ ...auditHeader, audit_owner: organisation_name });

      const owner = {
        orgId: res.data.data.membership_data.org_id,
        name: organisation_name,
      };
      setAuditOwners([owner]);

      // Fetch organizational functions
      fetchOrgFunctions(owner.orgId);

      // Fetch audit programs
      axios
        .get(`${apiUrl}/audit/programs/${owner.orgId}`)
        .then((res) => {
          setAuditPrograms(res.data);
        })
        .catch((error) => {
          console.error("Error fetching audit programs: ", error);
        });
    })
    .catch((error) => {
      console.error("Error decoding membership token: ", error);
    });
}, []); // Empty dependency array

  const fetchOrgFunctions = (orgId) => {
    axios
      .get(`${apiUrl}/organisation_functions_all_data/${orgId}`)
      .then((res) => {
        setOrgFunctions(res.data);
      });
  };

  const handleAuditProgramSelect = (e) => {
    setSelectedAuditProgram(e.target.value);
  };

  const [auditHeader, setAuditHeader] = useState({
    audit_name: "",
    audit_owner: "",
    audit_function: "",
    auditor: "",
    auditee: "",
    month_scheduled: "",
    status: "Scheduled", // default value
  });

  const handleFunctionSelect = (e) => {
    setAuditHeader({ ...auditHeader, audit_function: e.target.value });
  };

  let newAuditId; // declaring beforehand

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const newAuditHeader = {
        ...auditHeader,
        month_scheduled: new Date(
          new Date().getFullYear(),
          auditHeader.month_scheduled
        ).toISOString(),
        auditee: auditType ? auditHeader.audit_function : supplier,
        audit_owner: auditOwners[0].orgId,
        audit_type: auditType ? "Internal" : "Supplier",
      };
      const headerResponse = await axios.post(
        apiUrl + "/audit/generate_header",
        newAuditHeader
      );
  
      // Extract the new audit ID from the response
      const newAuditId = headerResponse.data.id;
  
      // Create payload for adding program details
      const programDetailsPayload = {
        program_id: selectedAuditProgram, // Assuming you have the selected audit program ID available
        audit_id: newAuditId,
      };
  
      // Call the endpoint to add program details
      const programDetailsResponse = await axios.post(
        `${apiUrl}/audit/add_program_detail`,
        programDetailsPayload
      );
  
      console.log(programDetailsResponse.data);
      
      // Navigate to the created audit
      navigate(`/audit/create_audit/${newAuditId}`);
    } catch (error) {
      console.error(error);
    }
  };
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "supplier") {
      setSupplier(value);
    } else {
      setAuditHeader((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        className="flex flex-col w-full max-w-md p-8 space-y-8 bg-white shadow-xl rounded-lg"
      >
        <div>
          <label className="text-lg mb-2">Force:</label>
          <input
            type="text"
            name="audit_owner"
            value={auditHeader.audit_owner}
            disabled
            className="w-full border h-10 border-gray-300 px-3 rounded"
          />
        </div>
        <div>
          <label className="text-lg mb-2">Test Reference:</label>
          <input
            type="text"
            name="audit_name"
            value={auditHeader.audit_name}
            onChange={handleChange}
            className="w-full border h-10 border-gray-300 px-3 rounded"
          />
        </div>

        <div>
          <label className="text-lg mb-2">Select Location:</label>
          <select
            name="audit_program"
            value={selectedAuditProgram}
            onChange={handleAuditProgramSelect}
            className="w-full border h-10 border-gray-300 px-3 rounded"
          >
            <option value="">  </option>
            {auditPrograms.map((program) => (
              <option key={program.id} value={program.id}>
                {program.program_name}
              </option>
            ))}
          </select>
        </div>

        <div>
          <span> NPAS not deployed </span>
          <Switch
            onChange={setAuditType}
            checked={auditType} // Fixed attribute name
            height={20}
            width={40}
            uncheckedIcon={false}
            checkedIcon={false}
          />
          <span> NPAS deployed </span>
        </div>

        {auditType ? ( // check if auditType is Internal
          orgFunctions.length > 0 && (
            <select
              name="function"
              onChange={handleFunctionSelect}
              className="w-full border h-10 border-gray-300 px-3 rounded"
            >
              <option value="">--Operation Type--</option>
              {orgFunctions.map((func) => (
                <option key={func.id} value={func.id}>
                  {func.alias}
                </option>
              ))}
            </select>
          )
        ) : (
          // if auditType is Supplier
          // if auditType is Supplier
          <input
            type="text"
            name="supplier"
            placeholder="Enter Test Reference"
            value={supplier}
            onChange={handleChange}
            className="w-full border h-10 border-gray-300 px-3 rounded"
          />
        )}

        <div>
          <label className="text-lg mb-2">Drone Pilot:</label>
          <input
            type="text"
            name="auditor"
            value={auditHeader.auditor}
            onChange={handleChange}
            className="w-full border h-10 border-gray-300 px-3 rounded"
          />
        </div>

        <div className="mb-6">
          <MonthPicker
            value={auditHeader.date_scheduled}
            onChange={(date_scheduled) =>
              setAuditHeader({ ...auditHeader, date_scheduled })
            }
          />
        </div>

        <div className="mt-4 flex justify-center">
          <button
            type="submit"
            className="bg-primary hover:bg-secondary text-white font-bold py-2 px-4 rounded block mx-auto"
          >
            Create Log
          </button>
        </div>


      </form>
    </div>
  );
};

export default AuditHeaderForm;