import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import auditProtection from "../hoc/auditProtection.js";
import Switch from "react-switch";

const apiUrl = process.env.REACT_APP_API_URL;

const JobcardHeaderForm = () => {
  const navigate = useNavigate();
  const [orgID, setOrgId] = useState([]);
  const [userID, setUserID] = useState([]);
  const [assetTypes, setAssetTypes] = useState([]); // To hold asset types
  const [selectedAssetType, setSelectedAssetType] = useState(""); // To hold the selected asset type

  const membershipToken = localStorage.getItem("MToken");

  // Fetch user ID
  const fetchUserID = async () => {
    const token = localStorage.getItem("access_token");
    try {
      const userRes = await axios.get(apiUrl + "/get_user_id_from_token", {
        headers: { Authorization: `Bearer ${token}` },
      });
      const userID = userRes.data.user_id;
      setUserID(userID);
    } catch (error) {
      console.error("There was an error!", error.response);
      if (error.response && error.response.status === 401) {
        navigate("/login");
      }
    }
  };

  useEffect(() => {
    fetchUserID();
  }, [userID]);

  // Fetch asset types
  const fetchAssetTypes = async () => {
    try {
      const res = await axios.get(`${apiUrl}/assets/types/`); // No params needed
      setAssetTypes(res.data); // Set the asset types into state
    } catch (error) {
      console.error("Error fetching asset types", error);
    }
  };
  

  // Decode membership token
  useEffect(() => {
    axios
      .post(`${apiUrl}/decode-membership-token/${membershipToken}`)
      .then((res) => {
        const organisation_id = res.data.data.membership_data.org_id;
        setOrgId(organisation_id);
        fetchAssetTypes(); // Fetch asset types after orgID is set
      });
  }, [orgID]); // Dependency on orgID

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setJobcardHeader((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [jobcardHeader, setJobcardHeader] = useState({
    checklist_name: "",
    user_id: "",
    org_id: "",
    is_active: true, // default value
    blueprint: true, // default value
  });

  let newJobcardId; // declaring beforehand

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const newJobcardHeader = {
        ...jobcardHeader,
        user_id: userID,
        org_id: orgID,
        asset_type: selectedAssetType, // Add the selected asset type
      };

      const response = await axios.post(
        apiUrl + "/maint/generate_checklist_header",
        newJobcardHeader
      );
      newJobcardId = response.data.id;
      navigate(`/maint/create_jobcard_blueprint/${newJobcardId}`);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-secondary2 text-gray-800">
      <h2 className="text-4xl mb-10 text-center">
        Create <span className="text-blue-900">Jobcard</span>
      </h2>

      <form
        onSubmit={handleSubmit}
        className="flex flex-col w-full max-w-md p-8 space-y-8 bg-white shadow-xl rounded-lg"
      >
        <div>
          <label className="text-lg mb-2">Jobcard Title:</label>
          <input
            type="text"
            name="checklist_name"
            value={jobcardHeader.checklist_name}
            onChange={handleChange}
            className="w-full border h-10 border-gray-300 px-3 rounded"
          />
        </div>

        <div>
          <label className="text-lg mb-2">Select Asset Type:</label>
          <select
            value={selectedAssetType}
            onChange={(e) => setSelectedAssetType(e.target.value)}
            className="w-full border h-10 border-gray-300 px-3 rounded"
          >
            <option value="">Select Asset Type</option>
            {assetTypes.map((type) => (
              <option key={type.id} value={type.id}>
                {type.asset_type}
              </option>
            ))}
          </select>
        </div>

        <div className="mt-4 flex justify-center">
          <button
            type="submit"
            className="bg-primary hover:bg-secondary text-white font-bold py-2 px-4 rounded block mx-auto"
          >
            OK
          </button>
        </div>
      </form>
    </div>
  );
};

export default auditProtection(JobcardHeaderForm);
