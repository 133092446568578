import React from "react";
import ReactModal from "react-modal";
import { useNavigate } from "react-router-dom";
import { useOrganisationFunctions } from "../hooks/useOrganisationFunction.js";

export default function AddOrganisationFunction() {
  const {
    organisations,
    selectedOrg,
    allFunctions,
    selectedFunction,
    orgFunctions,
    remainingFunctions,
    isModalOpen,
    alias,
    isActive,
    setAlias,
    setIsActive,
    handleOrgSelect,
    handleFunctionSelect,
    handleTransferFunction,
    handleModalClose,
    handleModalSubmit,
  } = useOrganisationFunctions();

  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-secondary2 text-primary">
      <h2 className="text-4xl text-center mb-10">Departments</h2>
      <div className="flex flex-col w-full max-w-md p-8 space-y-8 bg-white text-primary shadow-xl rounded-lg">
        <div>
          <label className="text-lg mb-2">Force:</label>
          <select
            value={selectedOrg ? selectedOrg.id : ""}
            onChange={handleOrgSelect}
            className="w-full border h-10 border-gray-300 px-3 rounded"
          >
            <option value="" disabled>
              Select a Force...
            </option>
            {organisations.map((org) => (
              <option key={org.id} value={org.id}>
                {org.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          {remainingFunctions.length > 0 ? (
            <>
              <label className="text-lg mb-2">Remaining Departments:</label>
              {remainingFunctions.map((func) => (
                <div
                  key={func.id}
                  className={`border h-10 border-gray-300 px-3 rounded my-2 ${
                    selectedFunction === func.id ? "bg-primary2" : ""
                  }`}
                  onClick={() => handleFunctionSelect(func.id)}
                >
                  {func.name}
                </div>
              ))}
            </>
          ) : (
            <label className="text-lg mb-2">Remaining Departments: None</label>
          )}
        </div>
        <div>
          <div className="flex justify-center">
            <button
              className="bg-primary text-white p-2 rounded mt-4"
              onClick={handleTransferFunction}
            >
              Add Selected Department
            </button>
          </div>
        </div>
        <div>
          <label className="text-lg mb-2">Departments:</label>
          {orgFunctions.map((funcId) => {
            const correspondingFunction = allFunctions.find(
              (f) => f.id === funcId
            );
            return (
              <div
                key={funcId}
                className="border h-10 border-gray-300 px-3 rounded my-2"
              >
                {correspondingFunction?.name ||
                  `No Name for function with id: ${funcId}`}
              </div>
            );
          })}
        </div>
      </div>

      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={handleModalClose}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            padding: "2rem",
          },
        }}
        contentLabel="Function Transfer Confirmation"
      >
        <div className="flex flex-col items-center bg-white text-gray-400 rounded">
          <h2 className="text-2xl mb-4 text-center">Department Alias</h2>
          <p className="text-center mb-4">
            Change the text below if you would like to set an alias.
          </p>
          <input
            type="text"
            className="border h-10 border-gray-300 px-3 rounded my-2"
            value={alias}
            onChange={(e) => setAlias(e.target.value)}
            placeholder="Enter alias here (optional)"
          />
          <button
            className="mt-4 bg-blue-500 text-white py-2 px-4 rounded"
            onClick={handleModalSubmit}
          >
            Continue
          </button>
        </div>
      </ReactModal>
    </div>
  );
}