import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import superAdminProtection from "../hoc/superAdminProtection";
import { ThemeContext } from "../components/theme.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHomeLgAlt } from "@fortawesome/free-solid-svg-icons";
import AdminMenu from "../components/AdminMenu";
import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

function AdminPage(props) {
  const { theme, setTheme } = useContext(ThemeContext);
  const [logoUrl, setLogoUrl] = useState(theme.logoUrl); // Use theme's logoUrl as default
  const [userID, setUserID] = useState(null); // Add state for userID
  const [memberships, setMemberships] = useState([]);
  const [selectedMembership, setSelectedMembership] = useState(null);

  useEffect(() => {
    fetchUserID();
  }, []);

  useEffect(() => {
    if (userID) {
      fetchMemberships(userID);
      fetchUserProfile(userID);
    }
  }, [userID]);

  const fetchUserID = async () => {
    // Fetch userID logic here
    // setUserID(fetchedUserID);
  };

  const fetchMemberships = async (userID) => {
    try {
      const membershipsRes = await axios.get(
        apiUrl + `/user-memberships/${userID}`
      );
      const memberships = membershipsRes.data;
      setMemberships(memberships);

      // Automatically select the first membership if available
      if (memberships.length > 0) {
        handleMembershipClick(memberships[0]);
      }
    } catch (error) {
      console.error("There was an error fetching the memberships!", error);
    }
  };

  const fetchUserProfile = async (userID) => {
    // Fetch user profile logic here
  };

  const handleMembershipClick = async (membership) => {
    setSelectedMembership(membership);

    try {
      const token = localStorage.getItem("access_token");
      const orgId = membership.org_id;

      const membershipTokenRes = await axios.post(
        `${apiUrl}/membership-token`,
        {
          membership_data: membership,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      localStorage.setItem("MToken", membershipTokenRes.data.membership_token);

      const organisationRes = await axios.get(
        `${apiUrl}/organisations/${orgId}`
      );

      const {
        primary_colour,
        accent_colour,
        primary_text_and_icon_colour,
        secondary_text_and_icon_colour,
      } = organisationRes.data;

      try {
        const logoRes = await axios.get(
          `${apiUrl}/organisations/retrieve_file/${orgId}/logo.png`
        );
        const newLogoUrl = logoRes.data.url;

        setTheme({
          primary_colour: primary_colour,
          accent_colour: accent_colour,
          primary_text_and_icon_colour: primary_text_and_icon_colour,
          secondary_text_and_icon_colour: secondary_text_and_icon_colour,
          logoUrl: newLogoUrl,
        });
      } catch (err) {
        console.error("There was an error retrieving the logo:", err);
      }
    } catch (error) {
      console.error(
        "There was an error creating the membership token!",
        error.response
      );
    }
  };

  return (
    <div className=" w-full flex flex-col bg-white">
      <div className="flex justify-between items-center p-4 bg-white ">
        <Link to="/home">
          <img
            src={theme.logoUrl}
            alt="Membership Logo"
            className="w-48 h-auto"
          />
        </Link>
      </div>
      <div className="flex-grow py-6">
        <h1 className="font-bold text-3xl text-primary text-center flex-grow">
          Administration Menu
        </h1>
        <div className="max-w-7xl mx-auto bg-white p-10 rounded-lg ">
          <AdminMenu theme={theme} />
        </div>
      </div>
    </div>
  );
}

export default superAdminProtection(AdminPage);
