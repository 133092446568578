import React, { useState, useEffect } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate, useLocation } from "react-router-dom";
import auditProtection from "../hoc/auditProtection.js";
import { fetchUserID } from "../components/fetchUserID";

const apiUrl = process.env.REACT_APP_API_URL;

const MaintHeaderForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  
  // Retrieve the passed program data from the previous page
  const { programId, programName, tasks } = location.state || {};

  const [auditOwners, setAuditOwners] = useState([]);
  const [orgFunctions, setOrgFunctions] = useState([]);
  const [userID, setUserID] = useState(null);
  const [selectedTasks, setSelectedTasks] = useState([]); // State for selected tasks
  const [dropdownOpen, setDropdownOpen] = useState(false); // Dropdown toggle state

  const [auditHeader, setAuditHeader] = useState({
    maint_name: "", // Workpack title starts blank
    org_id: "",
    maintainer: "",
    date_scheduled: "",
    maint_type: "Internal",
    status: "Scheduled",
  });

  const membershipToken = localStorage.getItem("MToken");

  useEffect(() => {
    fetchUserID(setUserID);
    axios
      .post(`${apiUrl}/decode-membership-token/${membershipToken}`)
      .then((res) => {
        const organisation_name = res.data.data.membership_data.organisation_name;
        setAuditHeader({ ...auditHeader, audit_owner: organisation_name });
        const owner = {
          orgId: res.data.data.membership_data.org_id,
          name: organisation_name,
        };
        setAuditOwners([owner]);
        fetchOrgFunctions(owner.orgId);
      })
      .catch((error) => console.error("Error decoding membership token:", error));
  }, []);

  const fetchOrgFunctions = (orgId) => {
    axios.get(`${apiUrl}/organisation_functions_all_data/${orgId}`).then((res) => {
      setOrgFunctions(res.data);
    });
  };

  // Handle task selection for multi-select
  const handleTaskSelection = (taskId) => {
    console.log("Task data:", selectedTasks);
    setSelectedTasks((prevSelected) =>
      prevSelected.includes(taskId)
        ? prevSelected.filter((id) => id !== taskId)
        : [...prevSelected, taskId]
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const newAuditHeader = {
        ...auditHeader,
        date_scheduled: new Date(auditHeader.date_scheduled).toISOString(),
        maintainer: userID,
        org_id: auditOwners[0].orgId,
      };

      // Create the workpack (audit header)
      const headerResponse = await axios.post(apiUrl + "/maint/generate_header", newAuditHeader);
      const newAuditId = headerResponse.data.id;

      // Add selected program details
      const programDetailsPayload = {
        program_id: programId,
        maint_id: newAuditId,
      };
      await axios.post(`${apiUrl}/maint/add_program_detail`, programDetailsPayload);
      await axios.post(`${apiUrl}/maint/update_workpack_tasks`, {
        maint_id: newAuditId, // UUID string
        task_ids: selectedTasks, // Array of UUID strings
      });
      
      

      // Navigate to the newly created workpack
      navigate(`/maint/create_workpack/${newAuditId}`);
    } catch (error) {
      console.error("Error creating workpack:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAuditHeader((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <form
        onSubmit={handleSubmit}
        className="w-full max-w-lg p-8 bg-white shadow-xl rounded-lg space-y-6"
      >
        <h2 className="text-2xl font-bold text-center mb-6">Create Workpack</h2>

        <div>
          <label className="block text-lg mb-2">Force:</label>
          <input
            type="text"
            name="org_id"
            value={auditHeader.audit_owner}
            disabled
            className="w-full border h-10 border-gray-300 px-3 rounded  bg-gray-200"
          />
        </div>
       {/* Display the Program Name as non-editable text */}
       <div>
          <label className="block text-lg mb-2">Program:</label>
          <input
            type="text"
            name="program_name"
            value={programName} // Display passed program name
            disabled
            className="w-full border h-10 border-gray-300 px-3 rounded bg-gray-200"
          />
        </div>
 
        <div>
          <label className="block text-lg mb-2">Workpack Title:</label>
          <input
            type="text"
            name="maint_name"
            value={auditHeader.maint_name}
            onChange={handleChange}
            className="w-full border h-10 border-gray-300 px-3 rounded"
          />
        </div>

        {/* Multi-select tasks dropdown */}
        <div className="mb-6 relative">
          <label className="block text-lg mb-2">Available Tasks:</label>
          <div
            className="border border-gray-300 px-3 h-10 rounded cursor-pointer"
            onClick={() => setDropdownOpen(!dropdownOpen)}
          >
            {selectedTasks.length > 0
              ? `${selectedTasks.length} task(s) selected`
              : "Select tasks"}
          </div>

          {dropdownOpen && (
  console.log("Tasks:", tasks),
  <div className="absolute z-10 w-full bg-white border border-gray-300 rounded shadow-md mt-1 max-h-40 overflow-y-auto">
    {tasks &&
      [...tasks] // Create a shallow copy to avoid mutating the original array
        .sort((a, b) => new Date(a.drop_dead_days) - new Date(b.drop_dead_days)) // Sort by due date
        .map((task) => (
          <div key={task.task_id} className="p-2 flex items-center">
            <input
              type="checkbox"
              id={`task-${task.task_id}`}
              value={task.task_id}
              checked={selectedTasks.includes(task.task_id)}
              onChange={() => handleTaskSelection(task.task_id)}
              className="mr-2"
            />
            <label htmlFor={`task-${task.task_id}`}>
              {task.task_name} {" ("}
              {new Date(task.drop_dead_days).toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
              {")"}
            </label>
          </div>
        ))}
  </div>
)}

        </div>
        <div className="mb-6">
          <label className="block text-lg mb-2">Date Scheduled:</label>
          <DatePicker
            selected={auditHeader.date_scheduled}
            onChange={(date_scheduled) => setAuditHeader({ ...auditHeader, date_scheduled })}
            dateFormat="yyyy-MM-dd"
            className="w-full border h-10 border-gray-300 px-3 rounded"
            placeholderText="Select a date"
          />
        </div>

        <div className="mt-4 flex justify-center">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Create Workpack
          </button>
        </div>
      </form>
    </div>
  );
};

export default auditProtection(MaintHeaderForm);
