import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom"; // Import useParams 
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import AuditProgramForm from "../pages/create_program.js"; // Import the form
import LocationForm from "../pages/create_location.js"; // Import the form
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const apiUrl = process.env.REACT_APP_API_URL;

const EditFlightPage = () => {
  const navigate = useNavigate();
  
  const [auditOwners, setAuditOwners] = useState([]);
  const { flightId } = useParams(); // Get flightId from URL
  const [orgFunctions, setOrgFunctions] = useState([]);
  const [auditPrograms, setAuditPrograms] = useState([]);
  const [auditLocationList, setAuditLocationList] = useState([]);
  const [selectedAuditProgram, setSelectedAuditProgram] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const membershipToken = localStorage.getItem("MToken");
  const [errors, setErrors] = useState({});
  const [filteredBatteries, setFilteredBatteries] = useState([]);
  const [forces, setForces] = useState([]);
  const [pilots, setPilots] = useState([]);
  const [drones, setDrones] = useState([]);
  const [controllers, setControllers] = useState([]);
  const [softwareList, setSoftwareList] = useState([]);
  const [miscList, setMiscList] = useState([]);
  const [batteries, setBatteries] = useState([]);
  const [boxes, setBoxes] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  useEffect(() => {
    const fetchAuditDetails = async (auditId) => {
      try {
        const response = await axios.get(`${apiUrl}/audit/get_audit_header/${auditId}`);
        const auditDetailsData = response.data;
        console.log("AuditID:", response.data);

        // Populate formData with fetched data
        setFormData({
          test_reference: auditDetailsData.audit_name || "",
          auditor: auditDetailsData.auditor || "",
          pilot_id: auditDetailsData.auditor || "", // Assuming you want the pilot's global ID here
          program: auditDetailsData.program_id || "",
          location: auditDetailsData.location_id || "",
          observer: auditDetailsData.observer || "",
          scheduled_date: new Date(auditDetailsData.date_scheduled) || null,
          drone: auditDetailsData.drone_id || "",
          controller: auditDetailsData.cont_id || "",
          controllerROI: auditDetailsData.cont_rpo_id || "",
          rpo_location: auditDetailsData.rpo_location || "",
          software: auditDetailsData.soft_id || "",
          misc: auditDetailsData.misc_id || [],
          battery: auditDetailsData.battery_id || "",
          box: auditDetailsData.box_id || "",
        });
      } catch (error) {
        console.error("Error fetching audit details:", error);
      }
    };

    fetchAuditDetails(flightId); // Fetch audit details when the component loads
  }, [flightId]);


  const [formData, setFormData] = useState({
    test_reference: "",
    auditor: "",
    pilot_id: "",
    program: "",
    location: "",
    observer: "",
    scheduled_date: null,
    drone: "",
    controller: "",
    controllerROI: "",
    rpo_location: "",
    software: "",
    misc: [],
    battery: "",
    box: "",
  });

  const [auditHeader, setAuditHeader] = useState({
    audit_owner: "",
  });

  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [islocationPopupVisible, setIslocationPopupVisible] = useState(false);

  useEffect(() => {
    // Decode membership token and fetch initial data
    axios
      .post(`${apiUrl}/decode-membership-token/${membershipToken}`)
      .then((res) => {
        const organisation_name =
          res.data.data.membership_data.organisation_name;
        const owner = {
          orgId: res.data.data.membership_data.org_id,
          name: organisation_name,
        };
        setAuditOwners([owner]);
        setAuditHeader({ ...auditHeader, audit_owner: organisation_name });

        fetchInitialData(owner.orgId); // Fetch the programs, locations, assets, etc.
        fetchAuditHeaderData(flightId); // Fetch the audit header data for editing
      })
      .catch((error) => {
        console.error("Error decoding membership token: ", error);
      });
  }, [flightId]); // Re-fetch when audit_header_id changes

  const fetchInitialData = (orgId) => {
    // Fetch audit programs, locations, pilots, and assets
    axios.get(`${apiUrl}/audit/programs/${orgId}`).then((res) => {
      setAuditPrograms(res.data);
    });
    axios.get(`${apiUrl}/audit/locations`).then((res) => {
      setAuditLocationList(res.data);
    });
    axios.get(`${apiUrl}/profiles/by_pilot/`, {
      params: {
        org_id: orgId,
        function_id: "617ae024-12ca-4ebf-852d-ca769780a09a",
        role_id: "486de4e8-9b90-447e-8ed5-0a9f29b834a1",
      },
    }).then((res) => {
      setPilots(res.data);
    });
    axios.get(`${apiUrl}/assets/drone/${orgId}`).then((res) => {
      setDrones(res.data);
    });
    axios.get(`${apiUrl}/assets/controller/${orgId}`).then((res) => {
      setControllers(res.data);
    });
    axios.get(`${apiUrl}/assets/software/${orgId}`).then((res) => {
      setSoftwareList(res.data);
    });
    axios.get(`${apiUrl}/assets/misc/${orgId}`).then((res) => {
      setMiscList(res.data);
    });
    axios.get(`${apiUrl}/assets/battery/${orgId}`).then((res) => {
      setBatteries(res.data);
    });
    axios.get(`${apiUrl}/assets/box/${orgId}`).then((res) => {
      setBoxes(res.data);
    });
  };

  const fetchAuditHeaderData = (auditHeaderId) => {
    // Fetch the audit header data by ID
    axios
      .get(`${apiUrl}/audit/get_audit_header/${auditHeaderId}`)
      .then((res) => {
        const data = res.data;
        setFormData({
          test_reference: data.audit_name,
          auditor: data.auditor,
          pilot_id: data.auditor,
          program: data.program_id,
          location: data.location_id,
          observer: data.observer,
          scheduled_date: new Date(data.date_scheduled),
          drone: data.drone_id,
          controller: data.cont_id,
          controllerROI: data.cont_rpo_id,
          rpo_location: data.rpo_location,
          software: data.soft_id,
          misc: data.misc_id || [],
          battery: data.battery_id,
          box: data.box_id,
        });
      })
      .catch((error) => {
        console.error("Error fetching audit header data: ", error);
      });
  };

  useEffect(() => {
    if (formData.drone) {
      const filteredBatteries = batteries.filter(
        (battery) => battery.drone_id === formData.drone
      );
      setFilteredBatteries(filteredBatteries);
    }
  }, [formData.drone, batteries]);

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;

    let updatedMisc;
    if (checked) {
      updatedMisc = [...(formData.misc || []), value];
    } else {
      updatedMisc = formData.misc.filter((item) => item !== value);
    }

    setFormData({
      ...formData,
      misc: updatedMisc,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePilotChange = (e) => {
    const { name, value } = e.target;
    const selectedPilot = pilots.find((pilot) => pilot.global_id === value);

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      pilot_id: selectedPilot ? selectedPilot.global_id : "",
    }));
  };

  const handleAuditProgramSelect = (e) => {
    const selectedProgramId = e.target.value;
    setSelectedAuditProgram(selectedProgramId);

    const selectedProgram = auditPrograms.find(
      (program) => program.id === selectedProgramId
    );

    if (selectedProgram) {
      setFormData((prevData) => ({
        ...prevData,
        program: selectedProgram.id,
      }));
    }
  };

  const handleNewProgramAdded = () => {
    const owner = auditOwners[0];
    axios
      .get(`${apiUrl}/audit/programs/${owner.orgId}`)
      .then((res) => {
        setAuditPrograms(res.data);
        setIsPopupVisible(false);
      })
      .catch((error) => {
        console.error("Error fetching audit programs: ", error);
      });
  };

  const handleLocationSelect = (e) => {
    const selectedLocationId = e.target.value;
    setSelectedLocation(selectedLocationId);

    const selectedLocation = auditLocationList.find(
      (location) => location.id === selectedLocationId
    );
    if (selectedLocation) {
      setFormData((prevData) => ({
        ...prevData,
        location: selectedLocation.id,
      }));
    }
  };

  const handleNewLocationAdded = () => {
    axios
      .get(`${apiUrl}/audit/locations`)
      .then((res) => {
        setAuditLocationList(res.data);
        setIslocationPopupVisible(false);
      })
      .catch((error) => {
        console.error("Error fetching locations: ", error);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const newErrors = {};
    if (!formData.test_reference) newErrors.test_reference = "Test Reference is required";
    if (!formData.pilot_id) newErrors.pilot_id = "Pilot is required";
    if (!selectedAuditProgram) newErrors.audit_program = "Test Schedule is required";
    if (!formData.scheduled_date) newErrors.scheduled_date = "Scheduled Test Date is required";
  
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
  
    try {
      const scheduledDate = new Date(formData.scheduled_date).toISOString();
  
      // Update the audit header
      const updatedAuditHeader = {
        audit_name: formData.test_reference,
        audit_owner: auditOwners[0].orgId,
        auditor: formData.pilot_id,
        auditee: "NPCC",
        date_scheduled: scheduledDate,
        status: "Scheduled",
        audit_type: "Test",
        observer: formData.observer,
        location: formData.location,
        drone: formData.drone,
        controller: formData.controller,
        cont_rpo_id: formData.controllerROI,
        rpo_location: formData.rpo_location,
        software: formData.software,
        misc: formData.misc,
        battery: formData.battery || null,
        box: formData.box || null,
      };
  
      // Make the API call to update the audit header
      await axios.put(`${apiUrl}/audit/update_header/${flightId}`, updatedAuditHeader);
  
      // Now update the audit program detail using the selected audit program
      const programDetailsPayload = {
        program_id: selectedAuditProgram,
        audit_id: flightId,  // Use the current flightId
      };
  
      // Call the update program detail API
      await axios.post(`${apiUrl}/audit/update_program_detail`, programDetailsPayload);
  
      // Redirect or take appropriate action after successful update
      navigate(`/flight/create_test/${flightId}`);
    } catch (error) {
      console.error("Error updating audit program and header:", error);
    }
  };
  

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="flex flex-col items-center justify-center h-screen">
        <h1 className="text-4xl text-center mb-10 text-primary">
          Edit Test Flight
        </h1>
        <form
          onSubmit={handleSubmit}
          className="flex flex-col w-full max-w-4xl p-8 space-y-8 bg-white shadow-xl rounded-lg"
        >
          <div className="mb-8">
            <h2 className="text-2xl font-bold text-primary mb-4">
              Flight Details
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="mb-2">
                <label className="text-lg">Scheduled Flight Date:</label>
                <div className="flex items-center">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      value={formData.scheduled_date}
                      onChange={(date) =>
                        setFormData({ ...formData, scheduled_date: date })
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className={`w-full border h-10 border-gray-300 px-3 rounded ${
                            errors.scheduled_date ? "border-red-500" : ""
                          }`}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                {errors.scheduled_date && (
                  <p className="text-red-500">{errors.scheduled_date}</p>
                )}
              </div>

              <div>
                <label className="text-lg mb-2">Force:</label>
                <input
                  type="text"
                  name="audit_owner"
                  value={auditHeader.audit_owner}
                  disabled
                  className="w-full border h-10 border-gray-300 px-3 rounded"
                />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
              <div>
                <label className="text-lg mb-2">Test Schedule:</label>
                <div className="flex items-center">
                  <select
                    name="audit_program"
                    value={formData.program}
                    onChange={handleAuditProgramSelect}
                    className={`w-full border h-10 border-gray-300 px-3 rounded ${
                      errors.audit_program ? "border-red-500" : ""
                    }`}
                  >
                    <option value=""> </option>
                    {auditPrograms.map((program) => (
                      <option key={program.id} value={program.id}>
                        {program.program_name}
                      </option>
                    ))}
                  </select>
                  <button
                    type="button"
                    onClick={() => setIsPopupVisible(true)}
                    className="ml-4 bg-primary hover:bg-secondary text-white font-bold py-2 px-4 rounded"
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </button>
                </div>
                {errors.audit_program && (
                  <p className="text-red-500">{errors.audit_program}</p>
                )}
              </div>

              <div>
                <label className="text-lg mb-2">Location:</label>
                <div className="flex items-center">
                  <select
                    name="location"
                    value={formData.location}
                    onChange={handleLocationSelect}
                    className={`w-full border h-10 border-gray-300 px-3 rounded ${
                      errors.location ? "border-red-500" : ""
                    }`}
                  >
                    <option value="">Select Location</option>
                    {auditLocationList.map((location) => (
                      <option key={location.id} value={location.id}>
                        {location.location_name}
                      </option>
                    ))}
                  </select>
                  <button
                    type="button"
                    onClick={() => setIslocationPopupVisible(true)}
                    className="ml-4 bg-primary hover:bg-secondary text-white font-bold py-2 px-4 rounded"
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </button>
                </div>
                {errors.location && (
                  <p className="text-red-500">{errors.location}</p>
                )}
              </div>

              <div>
                <label className="text-lg mb-2">Test Reference:</label>
                <input
                  type="text"
                  name="test_reference"
                  value={formData.test_reference}
                  onChange={handleChange}
                  className={`w-full border h-10 border-gray-300 px-3 rounded ${
                    errors.test_reference ? "border-red-500" : ""
                  }`}
                />
                {errors.test_reference && (
                  <p className="text-red-500">{errors.test_reference}</p>
                )}
              </div>

              <div>
                <label className="text-lg mb-2">Drone:</label>
                <select
                  name="drone"
                  value={formData.drone}
                  onChange={handleChange}
                  className={`w-full border h-10 border-gray-300 px-3 rounded ${
                    errors.drone ? "border-red-500" : ""
                  }`}
                >
                  <option value="">Select Drone</option>
                  {drones.map((drone, index) => (
                    <option key={index} value={drone.id}>
                      {drone.code_name}
                    </option>
                  ))}
                </select>
                {errors.drone && <p className="text-red-500">{errors.drone}</p>}
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-6">
              <div>
                <label className="text-lg mb-2">PIC:</label>
                <select
                  name="auditor"
                  value={formData.auditor}
                  onChange={handlePilotChange}
                  className={`w-full border h-10 border-gray-300 px-3 rounded ${
                    errors.pilot_id ? "border-red-500" : ""
                  }`}
                >
                  <option value="">Select Pilot</option>
                  {pilots.map((pilot, index) => (
                    <option key={index} value={pilot.global_id}>
                      {pilot.first_name} {pilot.last_name}
                    </option>
                  ))}
                </select>
                {errors.pilot_id && (
                  <p className="text-red-500">{errors.pilot_id}</p>
                )}
              </div>

              <div>
                <label className="text-lg mb-2">RPO:</label>
                <div className="flex items-center">
                  <input
                    type="text"
                    name="observer"
                    placeholder="Observer"
                    value={formData.observer}
                    onChange={handleChange}
                    className="w-1/2 border h-10 border-gray-300 px-3 rounded"
                  />
                  <div className="ml-4">
                    <div className="flex items-center">
                      <div className="flex flex-col items-center mr-4">
                        <label className="text-lg mb-1">Co-Located</label>
                        <input
                          type="radio"
                          name="rpo_location"
                          value="co-located"
                          checked={formData.rpo_location === "co-located"}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="flex flex-col items-center">
                        <label className="text-lg mb-1">Remote</label>
                        <input
                          type="radio"
                          name="rpo_location"
                          value="remote"
                          checked={formData.rpo_location === "remote"}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <label className="text-lg mb-2">Controller PIC:</label>
                <select
                  name="controller"
                  value={formData.controller}
                  onChange={handleChange}
                  className={`w-full border h-10 border-gray-300 px-3 rounded ${
                    errors.controller ? "border-red-500" : ""
                  }`}
                >
                  <option value="">Select Controller</option>
                  {controllers.map((controller, index) => (
                    <option key={index} value={controller.id}>
                      {controller.code_name}
                    </option>
                  ))}
                </select>
                {errors.controller && (
                  <p className="text-red-500">{errors.controller}</p>
                )}
              </div>

              <div>
                <label className="text-lg mb-2">Controller RPO:</label>
                <select
                  name="controllerROI"
                  value={formData.controllerROI}
                  onChange={handleChange}
                  className={`w-full border h-10 border-gray-300 px-3 rounded ${
                    errors.controller ? "border-red-500" : ""
                  }`}
                >
                  <option value="">Select Controller</option>
                  {controllers.map((controller, index) => (
                    <option key={index} value={controller.id}>
                      {controller.code_name}
                    </option>
                  ))}
                </select>
                {errors.controller && (
                  <p className="text-red-500">{errors.controller}</p>
                )}
              </div>

              <div>
                <label className="text-lg mb-2">Battery:</label>
                <select
                  name="battery"
                  value={formData.battery}
                  onChange={handleChange}
                  className={`w-full border h-10 border-gray-300 px-3 rounded ${
                    errors.battery ? "border-red-500" : ""
                  }`}
                >
                  <option value="">Select Battery</option>
                  {filteredBatteries.map((battery, index) => (
                    <option key={index} value={battery.id}>
                      {battery.code_name}
                    </option>
                  ))}
                </select>
                {errors.battery && (
                  <p className="text-red-500">{errors.battery}</p>
                )}
              </div>

              <div>
                <label className="text-lg mb-2">Box:</label>
                <select
                  name="box"
                  value={formData.box}
                  onChange={handleChange}
                  className={`w-full border h-10 border-gray-300 px-3 rounded ${
                    errors.box ? "border-red-500" : ""
                  }`}
                >
                  <option value="">Select Box</option>
                  {boxes.map((box, index) => (
                    <option key={index} value={box.id}>
                      {box.code_name}
                    </option>
                  ))}
                </select>
                {errors.box && <p className="text-red-500">{errors.box}</p>}
              </div>

              <div>
                <label className="text-lg mb-2">Software:</label>
                <select
                  name="software"
                  value={formData.software}
                  onChange={handleChange}
                  className={`w-full border h-10 border-gray-300 px-3 rounded ${
                    errors.software ? "border-red-500" : ""
                  }`}
                >
                  <option value="">Select Software</option>
                  {softwareList.map((software, index) => (
                    <option key={index} value={software.id}>
                      {software.code_name}
                    </option>
                  ))}
                </select>
                {errors.software && (
                  <p className="text-red-500">{errors.software}</p>
                )}
              </div>

              <div>
                <label className="text-lg mb-2">Other Equipment:</label>
                <div
                  onClick={toggleDropdown}
                  className={`w-80 h-10 border border-gray-300 px-3 rounded flex items-center justify-between cursor-pointer ${
                    errors.misc ? "border-red-500" : ""
                  }`}
                >
                  <span>
                    {formData.misc && formData.misc.length > 0
                      ? `${formData.misc.length} selected`
                      : "Select Equipment"}
                  </span>
                  <span className="caret">&#9660;</span>
                </div>

                {dropdownOpen && (
                  <div className="absolute z-10 w-80 bg-white border border-gray-300 rounded shadow-md mt-1 max-h-40 overflow-y-auto">
                    {miscList.map((misc, index) => (
                      <div key={index} className="p-2 flex items-center">
                        <input
                          type="checkbox"
                          id={`misc-${index}`}
                          value={misc.id}
                          checked={formData.misc.includes(misc.id)}
                          onChange={handleCheckboxChange}
                          className="mr-2"
                        />
                        <label htmlFor={`misc-${index}`}>
                          {misc.code_name}
                        </label>
                      </div>
                    ))}
                  </div>
                )}

                {errors.misc && <p className="text-red-500">{errors.misc}</p>}
              </div>
            </div>
          </div>

          <div className="mt-4 flex justify-center">
            <button
              type="submit"
              className="bg-primary hover:bg-secondary text-white font-bold py-2 px-4 rounded block mx-auto"
            >
              Save Changes
            </button>
          </div>
        </form>
      </div>

      {islocationPopupVisible && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <LocationForm onLocationAdded={handleNewLocationAdded} />
          </div>
        </div>
      )}

      {isPopupVisible && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <AuditProgramForm onProgramAdded={handleNewProgramAdded} />
          </div>
        </div>
      )}
    </div>
  );
};

export default EditFlightPage;
