import superAdminProtection from '../hoc/superAdminProtection';
import "../components/styles.css";

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
const apiUrl = process.env.REACT_APP_API_URL;


const Profiles = (props) => {
  const [profiles, setProfiles] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
      getAllProfiles();
  }, []);

  const getAllProfiles = () => {
    axios({
      method: 'get',
      url: apiUrl + "/profiles",
    })
    .then(response => {
      setProfiles(response.data);
    })
    .catch(error => {
      console.error('There was an error!', error.response);
      alert("Failed to fetch profiles");
    });
  };
  return (
  
        <div className="flex flex-col items-center justify-top h-screen">
          <h2 className="text-primary text-4xl text-center mb-12">All Profiles</h2>
          <table className="table-auto w-1/2">
            <thead>
              <tr>
                <th className="px-4 py-2 text-left">First Name</th>
                <th className="px-4 py-2 text-left">Last Name</th>
                <th className="px-4 py-2 text-left">Email</th>
                <th className="px-4 py-2 text-left">Phone</th>
                <th className="px-4 py-2 text-left">is_Active</th>
                <th className="px-4 py-2 text-left">Updated_At</th>
                <th className="px-4 py-2 text-left">Created_At</th>
              </tr>
            </thead>
            <tbody>
              {profiles.map((profile, index) => (
                <tr key={index} className={index % 2 === 0 ? 'bg-gray-200' : ''}>
                  <td className="border px-4 py-2">{profile.first_name}</td>
                  <td className="border px-4 py-2">{profile.last_name}</td>
                    <td className="border px-4 py-2">{profile.email}</td>
                    <td className="border px-4 py-2">{profile.phone}</td>
                    <td className="border px-4 py-2">{profile.is_active ? '✔️ Active' : '❌ Inactive'}</td>
                    <td className="border px-4 py-2">{profile.updated_at ? new Date(profile.updated_at).toLocaleString() : 'N/A'}</td>
                    <td className="border px-4 py-2">{new Date(profile.created_at).toLocaleString()}</td>
                </tr>
                ))}
            </tbody>
            </table>
        </div>

)
};
export default superAdminProtection(Profiles);