import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';

const Dashboard = () => {
  const [regulationScores, setRegulationScores] = useState([]);
  const [orgScores, setOrgScores] = useState([]);
  const [auditScores, setAuditScores] = useState([]);
  const [checklistScores, setChecklistScores] = useState([]);

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchRegulationScores = async () => {
      try {
        const response = await axios.get(`${apiUrl}/admin/audit/scores/regulation`);
        setRegulationScores(response.data);
        console.log('response.data (regulation scores):', response.data);
      } catch (error) {
        console.error('Error fetching regulation scores', error);
      }
    };

    fetchRegulationScores();
  }, [apiUrl]);

  useEffect(() => {
    const fetchOrganizationScores = async () => {
      try {
        const response = await axios.get(`${apiUrl}/admin/audit/scores/organization`);
        setOrgScores(response.data);
      } catch (error) {
        console.error('Error fetching organization scores', error);
      }
    };
    fetchOrganizationScores();
  }, [apiUrl]);

  useEffect(() => {
    const fetchAuditScores = async () => {
      try {
        const response = await axios.get(`${apiUrl}/admin/audit/scores/audit`);
        setAuditScores(response.data);
        console.log('response.data (audit scores):', response.data);
      } catch (error) {
        console.error('Error fetching audit scores', error);
      }
    };

    fetchAuditScores();
  }, [apiUrl]);

  useEffect(() => {
    const fetchChecklistScores = async () => {
      try {
        const response = await axios.get(`${apiUrl}/admin/audit/scores/checklist`);
        setChecklistScores(response.data);
        console.log('response.data (checklist scores):', response.data);
      } catch (error) {
        console.error('Error fetching checklist scores', error);
      }
    };

    fetchChecklistScores();
  }, [apiUrl]);

  const getChartData = () => {
    const groupedData = regulationScores.reduce((acc, score) => {
      const { parent_regulation_title, regulation_title, average_score } = score;
      if (!acc[parent_regulation_title]) {
        acc[parent_regulation_title] = {
          parentTitle: parent_regulation_title,
          entries: [],
        };
      }
      acc[parent_regulation_title].entries.push({
        regulation_title,
        average_score,
      });
      return acc;
    }, {});

    return Object.values(groupedData);
  };

  const renderTable = () => {
    const data = getChartData();
    console.log('data:', data);
    const filteredData = data.filter(item => item.entries.some(entry => entry.average_score !== 0 && !isNaN(entry.average_score)));

    const sortedData = filteredData.sort((a, b) => {
      const avgA = a.entries.reduce((sum, entry) => sum + entry.average_score, 0) / a.entries.length;
      const avgB = b.entries.reduce((sum, entry) => sum + entry.average_score, 0) / b.entries.length;
      return avgA - avgB;
    });

    return (
      <TableContainer component={Paper}>
      <Table aria-label="collapsible table" sx={{ width: '95%', margin: 'auto' }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold', fontSize: '1.2rem' }} />
            <TableCell sx={{ fontWeight: 'bold', fontSize: '1.2rem' }}>Policing Outcome</TableCell>
            <TableCell align="right" sx={{ fontWeight: 'bold', fontSize: '1.2rem' }}>Average Score</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData.map((item, index) => (
            <Row key={index} row={item} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    
    );
  };

  const Row = (props) => {
    const { row } = props;
    const [open, setOpen] = useState(false);

    const averageScore = (entries) => {
      const total = entries.reduce((sum, entry) => sum + entry.average_score, 0);
      return (total / entries.length).toFixed(2);
    };

    return (
      <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell sx={{ width: '5%' }}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" sx={{ width: '70%', fontWeight: 'bold', fontSize: '1.1rem' }}>
          {row.parentTitle}
        </TableCell>
        <TableCell align="right" sx={{ width: '25%', fontWeight: 'bold', fontSize: '1.1rem' }}>
          {averageScore(row.entries)}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, paddingLeft: 4 }}>
            
              <Table size="small" aria-label="purchases" sx={{ width: '95%', margin: 'auto' }}>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ paddingLeft: '2em', fontWeight: 'bold', fontSize: '1rem' }}>Sub Category</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold', fontSize: '1rem' }}>Average Score</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.entries.map((entry, subIndex) => (
                    <TableRow key={subIndex}>
                      <TableCell component="th" scope="row" sx={{ paddingLeft: '4em', fontSize: '1rem' }}>
                        {entry.regulation_title}
                      </TableCell>
                      <TableCell align="right" sx={{ fontSize: '1rem' }}>{entry.average_score}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
    
    
    
    );
  };

  // Bar chart data and options
  const barData = {
    labels: orgScores.map((score) => score.org_name),
    datasets: [
      {
        label: 'Average Score',
        data: orgScores.map((score) => score.average_score),
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const checklistBarData = {
    labels: checklistScores.map((score) => score.checklist_name),
    datasets: [
      {
        label: 'Average Score',
        data: checklistScores.map((score) => score.average_score),
        backgroundColor: 'rgba(255, 99, 71, 0.2)', // Customize the colors as needed
        borderColor: 'rgba(255, 99, 71, 1)',
        borderWidth: 1,
      },
    ],
  };

  const auditBarData = {
    labels: auditScores.map((score) => score.audit_name),
    datasets: [
      {
        label: 'Average Score',
        data: auditScores.map((score) => score.average_score),
        backgroundColor: 'rgba(255, 99, 132, 0.2)', // Customize the colors as needed
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
    ],
  };

  const barOptions = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="relative flex bg-white min-h-screen">
      <div className="h-screen flex flex-col p-4 w-full">
        <div className="flex justify-center items-center p-4 bg-white">
          <h2 className="font-bold text-3xl text-primary">Global Scores Dashboard</h2>
        </div>
        <div className="flex flex-col p-5">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4 mb-4">
            <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
              <h3 className="font-bold text-lg text-gray-700">Flight Count</h3>
              <p className="text-2xl">12</p>
            </div>
            <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
              <h3 className="font-bold text-lg text-gray-700">Flight Time</h3>
              <p className="text-2xl">45 hrs</p>
            </div>
            <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
              <h3 className="font-bold text-lg text-gray-700">Test Count</h3>
              <p className="text-2xl">13</p>
            </div>
            <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
              <h3 className="font-bold text-lg text-gray-700">Distance Flown</h3>
              <p className="text-2xl">101 km</p>
            </div>
          </div>
        </div>
        {/* Chart container with flex */}
        <div className="flex flex-wrap justify-center">
          {/* Organization Scores Bar Chart */}
          <div className="w-full md:w-1/2 lg:w-1/3 p-4">
            <h2>Forces Scores</h2>
            {orgScores.length > 0 && <Bar data={barData} options={barOptions} />}
          </div>

          {/* Audit Scores Bar Chart */}
          <div className="w-full md:w-1/2 lg:w-1/3 p-4">
            <h2>Test Flight Scores</h2>
            {auditScores.length > 0 && <Bar data={auditBarData} options={barOptions} />}
          </div>

          {/* Checklist Scores Bar Chart */}
          <div className="w-full md:w-1/2 lg:w-1/3 p-4">
            <h2>Test Card Scores</h2>
            {checklistScores.length > 0 && <Bar data={checklistBarData} options={barOptions} />}
          </div>
        </div>

        {/* Render the table */}
        {renderTable()}
      </div>
    </div>
  );
};

export default Dashboard;
